import { Injectable } from '@angular/core';
import StoryHelper from 'src/helpers/story-helper';

@Injectable({
  providedIn: 'root'
})
export class UserInteractionValidatorService {

  public validate(validator: string, value: any): Boolean | null {
    var isValid = true;

    if (validator["min_words"]) {
      isValid = this.isMinWordsValid(value, validator["min_words"]);
    }

    if (validator["phrase"]) {
      isValid = this.phraseValidator(value, validator["phrase"]);
    }

    return isValid;
  }

  public phraseValidator(value: string, phrase: string): any {
    if (value) {
      if (phrase.toLowerCase() == "dont know" || phrase.toLowerCase() == "don't know") {
        return !this.blacklistedPhrases.includes(value.toLowerCase());
      }
      return true;
    }
    return false;
  }

  public isMinWordsValid(value: string, count: any): any {
    if (StoryHelper.countWords(value) >= count) {
      return true;
    }
    else {
      return false;
    }
  }

  private blacklistedPhrases = [
    "i don't know",
    "i dont know",
    "don't know",
    "dont know",
    "idk",
    "dunno",
    "no idea",
    "i have no idea",
    "i've got no idea",
    "ive got no idea",
    "nothing",
    "blah",
    "blahblah"
  ]
}
