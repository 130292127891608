import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import {
  Plugins,
} from '@capacitor/core';
import { AnalyticsService } from 'src/services/analytics.service';
import { first } from 'rxjs/operators';
import { JournalService } from 'src/services/journal.service';
import { NotificationService } from 'src/services/notification.service';
import { NavController } from '@ionic/angular';

const { Storage } = Plugins;


@Component({
  selector: 'email-signup',
  templateUrl: 'emailsignup.component.html',
  styleUrls: ['emailsignup.compontent.css']
})
export class EmailSignupComponent {

  constructor(public ngFireAuth: AngularFireAuth,
    public router: Router,
    private afs: AngularFirestore,
    private analytics: AnalyticsService,
    public journalService: JournalService,
    public notificationService: NotificationService,
    private navCtrl: NavController) { }

  usersCollection: AngularFirestoreCollection<any>;

  agreed: boolean = false;
  promocode: boolean = false;
  email2: string;
  password2: string;
  promoValue: string;

  signUpClick(email, password, promocodeValue) {
    console.log(promocodeValue);
    if (this.agreed) {
      this.email2 = email.value;
      this.password2 = password.value;

      if (this.promocode) {
        this.afs.collection('promocode', ref => ref.where('code', '==', promocodeValue)).snapshotChanges().pipe(first()).
          subscribe(res => {
            console.log(res);
            if (res.length > 0) {
              this.validPromo = true;
              this.signup(this.email2, this.password2, promocodeValue);
            }
            else {
              this.validPromo = false;
            }
          })
      }
      else {
        this.signup(this.email2, this.password2);
      }
    }
  }

  validPass: boolean = true;
  pristinePass: boolean = true;
  validEmail: boolean = true;
  pristineEmail: boolean = true;

  validPromo: boolean = true;

  signup(email, password, promocodeValue?) {
    this.pristineEmail = false;
    this.pristinePass = false;

    if (!this.validateEmail(email) || !this.validatePassword(password)) {
      return;
    }

    return this.ngFireAuth.createUserWithEmailAndPassword(email, password).then(async (res) => {

      let userid = res.user.uid;
      console.log(userid);

      const { value } = await Storage.get({ key: 'notif_token' });
      console.log('Got item: ', value);


      if (promocodeValue && promocodeValue != '') {
        this.afs.collection<any>('users').doc(userid).set({
          email: email,
          token: value,
          usrid: userid,
          promocode: promocodeValue
        });

        //this.analytics.logEvent("study_ios_" + promocodeValue);
        //await Storage.set({ key: 'study_ios', value: userid });

      } else {
        this.afs.collection<any>('users').doc(userid).set({
          email: email,
          token: value,
          usrid: userid
        });
      }

      await Storage.set({ key: 'userid', value: userid });

      this.saveDream1toFireBase(userid);
      this.journalService.initiateCollections();

      //this.analytics.logEvent("app_account_created");

      this.navCtrl.navigateForward("success", { animated: true });

    }
    ).catch(error => {
      console.log("Error creating user: ");
      console.log(error);
      let emailInUse = error['message'].includes("The email address is already in use");
      let badformat = error['message'].includes("The email address is badly formatted");
      if (emailInUse == true) {
        alert("The email is taken");
      }
      if (badformat) {
        alert("Invalid Email Format");
      }
      return false;
    });
  }

  signin() {
    this.navCtrl.navigateForward("login", { animated: false });
  }

  saveDream1toFireBase(userid) {
    var newObj = {};
    var storyPoints1 = localStorage.getItem("storyPoints-1");
    var storyState1 = localStorage.getItem("storyState-1");

    newObj["storyPoints-1"] = storyPoints1;
    newObj["storyState-1"] = storyState1;

    if (userid != null) {
      this.afs.collection<any>('dream_data').doc(userid).set(newObj).catch();
    }
  }

  onEmailChange(value: string) {
    if (!this.pristineEmail) {
      this.validateEmail(value);
    }
  }

  onPromocodeEntered(value: string) {
    console.log(value)
  }

  onPassChange(value: string) {
    if (!this.pristinePass) {
      this.validatePassword(value);
    }
  }

  validateEmail(value: string) {
    // var emailRegexp = new RegExp("/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/");
    // console.log(value);
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(value)) {
      this.validEmail = false;
      return false;
    }
    this.validEmail = true;
    return true;
  }

  validatePassword(value: string) {
    console.log(value);
    if (value.length < 8) {
      this.validPass = false;
      return false;
    }
    this.validPass = true;
    return true;
  }
}
