<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false">

    <div class="cmn-wrapper">
      <div class="cmn-font-helvetica ion-text-uppercase">
        <span>
          <ion-icon class="cmn-icon-helvetica" [routerLink]="'/slider'" name="chevron-back-outline">
          </ion-icon>
        </span>
      </div>
      <div class="cmn-serif-title ion-text-center">
        Disclaimer
      </div>
      <div class="disclaimer-text ion-text-center">
        This story may cause you to confront emotionally sensitive areas of your life, but Betwixt is not a form of
        therapy nor a replacement for medical or therapeutic treatment, and no human is monitoring your conversations.
        Click the SOS button at any stage for access to crisis resources and links. If you are concerned about your
        mental health, please seek a qualified health professional's advice before using this app and before making any
        medical decisions.
      </div>
      <br><br>
      <div class="ion-text-uppercase cmn-font-helvetica ion-text-center" [routerLink]="'/privacy'">Privacy Policy</div>
      <br>
      <div class="ion-text-uppercase cmn-font-helvetica ion-text-center" [routerLink]="'/terms-conditions'">Terms and
        conditions</div>
      <br>
      <div class="cst-footer ion-text-center cmn-bottom-text">
        <div (click)="acceptAndContinue()" class="cmn-button ion-text-center">
          Agree & Continue
        </div>
      </div>
    </div>
  </ion-content>
</ion-app>