<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="true">
        <div class="cmn-wrapper">
            <div class="cmn-font-helvetica ion-text-uppercase">
                <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
                </ion-icon>
            </div>

            <div class="cmn-serif-title ion-text-center">
                Terms and Conditions
            </div>
            <div class="disclaimer-text">

                <p><b>General</b></p>

                <p>
                    Welcome to Betwixt. Here are the terms (the “Terms”, “Agreement”)) that govern your use of our
                    website and our application (“App”; collectively the “Services”). Please carefully read them before
                    using the Services. For the purposes of these Terms, “we,” “our,” “us,” refer to Mind Monsters Games
                    Ltd, the providers and operators of the Services.
                </p>

                <p>
                    The Terms specific to your purchase of and use of a Betwixt NFT are dealt with in a separate
                    agreement which you can find on our website https://nft.betwixt.life/.
                </p>

                <p>
                    <b>
                        By using, downloading, installing, or otherwise accessing the services or any materials included
                        in or with the services, you hereby agree to be bound by these terms. If you do not accept these
                        terms, then you may not use, download, install, or otherwise access the services.

                    </b>
                </p><br>

                <p><b>Who can use Betwixt?</b></p>

                <p>
                    For users located outside of the European Union (“EU”), or the United Kingdom (“UK”), you must be
                    over 13 years of age to use the Services, and children under the age of 13 cannot use or register
                    for the Services. EU and UK users younger than age 16 cannot use or register for the Services. If
                    you are over 13 years of age but are not yet of legal age to form a binding contract (in many
                    jurisdictions, this age is 18), then you must get your parent or guardian to read these Terms and
                    agree to them for you before you use the Services. If you are a parent or guardian and you provide
                    your consent to your child’s registration with the Services, you agree to be bound by these Terms
                    with respect to your child’s use of the Services.
                </p><br>

                <p><b>No Medical or Psychiatric Advice or Therapy</b></p>

                <p><b> We are not a medical or health services organization provider or forum, and we do not engage in
                        patient diagnosis or the practice of medicine. The content entailed in the services is in no way
                        intended as medical and/or psychiatric advice or therapy, and is not provided for the purpose of
                        diagnosing or treating medical and/or psychiatric problems or conditions, nor is it a substitute
                        for medical and/or psychiatric treatment and/or therapy. The App and services are not, and
                        should never be, a substitute for the advice of a licensed healthcare provider or medical
                        professional.</b> </p>

                <p> No human is monitoring your live conversations. However, if at any stage you feel you may need
                    urgent help, you can click the SOS button in the App to find external links and numbers for services
                    in your area.
                </p><br>

                <p><b>What is Betwixt?</b></p>

                <p>Betwixt is a multisensory interactive App that uses play, storytelling and therapeutic techniques
                    from a variety of approaches (including positive psychology, cognitive approaches, mindfulness and
                    meditation, transactional analysis, self-distancing and expressive writing) to enhance
                    self-awareness. The App is meant to be used as a prevention or early intervention tool in a
                    self-help context. It is not intended to be a replacement for face-to-face psychotherapy or to
                    provide a diagnosis, prognosis, treatment or cure for a disease/condition/disorder or disability,
                    nor is it intended for use in crisis such as abuse or complex or severe mental health conditions
                    that causes for example; ideation of suicide, harm to self and others, or for any medical
                    emergencies.
                </p><br>

                <p><b>Use of the service </b></p>

                <p>
                    We reserve the right to withdraw or amend our website, our App, and any Service or
                    material we provide in our sole discretion without notice. We will not be liable if, for any reason,
                    all or any part of the website or Service is unavailable at any time or for any period.
                    From time to time, we may restrict access to some parts of the website, or our App, to
                    users, including registered users.
                </p>

                <p>
                    To access the Service, you may be asked to provide certain registration details or other
                    information. It is a condition of your use of the Service that all the information you provide to
                    use the Service is correct, current and complete.
                </p>

                <p>
                    You agree that you won’t disclose your account login details to anyone and you’ll notify us
                    immediately of any unauthorized use of your account. You’re responsible for all activities that
                    occur under your account, whether or not you know about them.
                </p>

                <p>
                    You agree that all information you provide to register with this Site or App, including but not
                    limited to through the use of any interactive features, is governed by our Privacy Policy, and you
                    consent to all actions we take with respect to your information consistent with our Privacy Policy.
                </p><br>

                <p> <b>Information Security</b> </p>

                <p>
                    No data transmission over the Internet can be guaranteed to be 100% safe. Thus,
                    we cannot warrant that your information will be absolutely secure. We have a variety of safeguards –
                    technical, administrative, and physical – in place to help protect against unauthorized access to,
                    use, or disclosure of user information.
                </p><br>

                <p><b>Our Proprietary Rights</b></p>
                <p>Anything you post, upload, share, store, or otherwise provide through the Services is your User
                    Content. Except for your User Content, the Services and all materials therein or transferred
                    thereby, including, without limitation, software, images, text, graphics, illustrations, logos,
                    patents, trademarks, service marks, copyrights, photographs, audio, videos, music, and all related
                    Intellectual Property Rights are our exclusive property. Except as explicitly provided, nothing in
                    this Agreement shall be deemed to create a license in or under any such Intellectual Property
                    Rights, and You agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit,
                    publicly display, publicly perform, publish, adapt, edit or create derivative works from any Content
                    contained in and related to the Services. Use of our Content for any purpose not expressly permitted
                    by this Agreement is strictly prohibited.
                </p>

                <p>
                    You may choose to or We may invite You to submit comments or ideas about the Service, including
                    without limitation about how to improve the Service or Our products ("Ideas"). By submitting any
                    Idea, You agree that Your disclosure is gratuitous, unsolicited and without restriction and will not
                    place us under any fiduciary or other obligation, and that We are free to use the Idea without any
                    additional compensation to You, and/or to disclose the Idea on a non-confidential basis or otherwise
                    to anyone. You further acknowledge that, by acceptance of Your submission, We do not waive any
                    rights to use similar or related ideas previously known to Us, or developed by our employees, or
                    obtained from sources other than You.
                </p><br>

                <p><b>NFT holders IP rights </b></p>

                <p>These are dealt with separately in the Terms governing the NFT asset.
                </p><br>

                <p><b>Modification and Termination of Services </b></p>

                <p>We are constantly innovating in order to provide the best possible experience for our users. We may
                    change the Services; stop providing the Services or features of the Services, to You or to Users
                    generally; or create usage limits for the Services. Changes to the Services include without
                    limitation changes to fee and payment policies, security patches, added functionality, automatic
                    updates, and other enhancements. Any new features that may be added to the website or the Services
                    from time to time will be subject to these Terms, unless stated otherwise.
                </p>

                <p>We may permanently or temporarily terminate or suspend Your access to the Services without notice and
                    liability if in Our determination You violate any provision of the Agreement.
                </p>

                <p>Upon any termination of the Services or your account these Terms will also terminate, but all
                    provisions of these Terms which, by their nature, should survive termination, shall survive
                    termination, including, without limitation, ownership provisions, warranty disclaimers, and
                    limitations of liability.
                </p><br>

                <p><b>Indemnification</b></p>

                <p>You agree to hold harmless and indemnify us, and our subsidiaries, affiliates, officers, agents,
                    employees, advertisers, licensors, suppliers or partners from and against any third party claim
                    arising from or in any way related to (a) your breach of the Terms, (b) your use of the Services,
                    (c) your violation of applicable laws, rules or regulations in connection with the Services, or (d)
                    your User Content, including any liability or expense arising from all claims, losses, damages
                    (actual and consequential), suits, judgments, litigation costs and attorneys’ fees, of every kind
                    and nature.
                </p><br>

                <p><b>Warranty Disclaimer</b></p>

                <p>
                    <b>
                        The service is provided on an "As is" and "As available" basis. Use of the service is at your
                        own risk. To the maximum extent permitted by applicable law, the service is provided without
                        warranties of any kind, whether express or implied, including, but not limited to, implied
                        warranties of merchantability, fitness for a particular purpose, or non-infringement. No advice
                        or information, whether oral or written, obtained by you from us or through the service will
                        create any warranty not expressly stated herein. Without limiting the foregoing, we, our
                        subsidiaries, affiliates, and licensors do not warrant that the content is accurate, reliable or
                        correct; that the service will meet your requirements; that the service will be available at any
                        particular time or location, uninterrupted or secure; that any defects or errors will be
                        corrected; or that the service is free of viruses or other harmful components. Any content
                        downloaded or otherwise obtained through the use of the service. The App is downloaded at your
                        own risk and you will be solely responsible for any damage to your computer system or mobile
                        device or loss of data that results from such download or your use of the service.
                    </b>
                </p>

                <p>
                    <b>
                        We do not warrant, endorse, guarantee, or assume responsibility for any product or service
                        advertised or offered by a third party through our service or any hyperlinked website or
                        service, and we will not be a party to or in any way monitor any transaction between you and
                        third-party providers of products or services.
                    </b>
                </p>
                <br>

                <p><b>Limitation of Liability</b></p>
                <p>
                    <b>
                        In no event and under no legal theory (including, without limitation, tort, contract, strict
                        liability, or otherwise) shall we or our subsidiaries, affiliates, agents, suppliers, vendors,
                        manufacturers or distributors be liable for any indirect, special, punitive, incidental,
                        exemplary or consequential damages, including, without limitation, damages for loss of use,
                        data, revenue or profits, business interruption, or loss of business opportunity or goodwill,
                        arising from or in connection with (a) the use of, or inability to use, the services; (b) the
                        provision of or failure to provide the services, even if we have been advised of the possibility
                        of such damages. You assume complete responsibility for your use of the services. That said,
                        should we be found to be liable to you for any damage or loss arising out of or which is in any
                        way connected with your use of the services, our liability shall not exceed $100.00 in the
                        aggregate.
                    </b>
                </p>
                <br>

                <p><b>Miscellaneous</b></p>

                <p>These Terms, together with our Privacy Policy, constitute the entire agreement between the parties
                    relating to the Services and all related activities. These Terms shall not be modified except in
                    writing signed by both parties or by a new posting of these Terms issued by us. If any part of these
                    Terms is held to be unlawful, void, or unenforceable, that part shall be deemed severed and shall
                    not affect the validity and enforceability of the remaining provisions.
                </p>

                <p>Each term of our Agreement operates independently and if any such term is deemed to be unlawful or
                    unenforceable, this will not affect any other term of our Agreement.
                </p>

                <p>The Terms are governed by English law and the English or Welsh courts will have exclusive
                    jurisdiction to settle any dispute (including non-contractual disputes) arising out of or in
                    connection with our Agreement.
                </p>
                <br>

                <p><b>Contact us</b></p>

                <p>If You have any questions, comments, or concerns about this Agreement or our other privacy policies
                    or practices, please contact us at <a
                        href="mailto:hazel@mindmonsters.co.uk">hazel@mindmonsters.co.uk</a>. </p>

            </div>
            <br><br>
            <div #continue (click)="back()" class="ion-text-center ion-text-uppercase cmn-font-helvetica cmn-enabled">
                Continue</div>
            <br><br>
        </div>
    </ion-content>
</ion-app>