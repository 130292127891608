import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { AnalyticsService } from 'src/services/analytics.service';
import { StateManagementService } from 'src/services/state-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.css']
})
export class KnowledgeComponent implements OnInit {

  navDream = 1;
  route$ : Subscription;
  
  constructor(private _location: Location, 
              private analytics: AnalyticsService,
              private stateManagementService: StateManagementService,     
              private route : ActivatedRoute) { }

  ngOnInit(): void {    
     this.route$ = this.route.params.subscribe(params => {
      this.navDream = params.id;
    });
  }

  back(){
    this._location.back();
  }

  complete(){
    this.analytics.logEvent("app_knowledge_" + this.navDream + "_completed");
    this.stateManagementService.saveLastCompletedQuest(1);
    this._location.back();
  }
}