
import { Plugins, FilesystemDirectory } from '@capacitor/core'
import { AnalyticsService } from 'src/services/analytics.service';
import { OperationsHelper } from 'src/helpers/ops-helper';
import { AngularFirestore } from '@angular/fire/firestore';
import { Location } from '@angular/common';
import { StateManagementService } from 'src/services/state-management.service';
import StoryHelper from 'src/helpers/story-helper';
import { AudioService } from 'src/services/audio.service';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/services/notification.service';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

const { Share, Storage } = Plugins;

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {



  public nextDreamRoman = "I";
  public lastCompletedDream = 1;
  public nextDream = 1;

  public showNotificationsPrompt = false;

  constructor(
    private navCtrl: NavController,
    private analytics: AnalyticsService,
              private afs: AngularFirestore,
              private location: Location,
              private stateManagementService: StateManagementService,
              private audioService: AudioService,
              private notificationService: NotificationService,
              public router: Router) {
  }

  ngOnInit(): void {

    this.lastCompletedDream = this.stateManagementService.getLastCompletedDream();
    this.nextDream = this.lastCompletedDream + 1;
    this.nextDreamRoman = StoryHelper.romanize(this.nextDream);

    //TODO: MOVE
    //TODO: ION DID ENTER SO IT REFRESHES
    //TODO: PROPER TEXT
    // this.analytics.logEvent("app_dream_" + this.lastCompletedDream + "_completed");
    // this.saveCompletedDreamToFirebase();
    this.audioService.stopFXImproved();
    this.audioService.fadeOutImproved(0);
    //this.audioService.unloadAllAudio();

    this.showNotificationsPrompt = false;
  }

  shouldShowNotificationsPrompt(){
    if(this.lastCompletedDream == 1 && !this.notificationService.hasGivenPermission()) {
      return true;
    }
    return false;
  }

  acceptNotification(accepted : Boolean){
    if (accepted){
      this.notificationService.initPushAsync().then(_ => {
        this.router.navigate(["/emailsignup"]);
      })
    }
    else {
      Storage.get({ key: 'userid' }).then( res => {
        if(res.value != null){
          this.router.navigate(["/emailsignup"]);
        }else{
          //backward compatibility for users 
          //who have already created account
          this.router.navigate(["/emailsignup"]);
        }
      });
    }
  }

  async shareBetwixt(){
    var uri = OperationsHelper.APP_STORE_LINK;
    var text = OperationsHelper.SHARE_TEXT;
    var title = OperationsHelper.SHARE_TITLE

    await Share.share({
      title: title,
      text: text,
      url: uri
    });
    
    this.analytics.logEvent("app_shared_betwixt");
  }

  joinDiscordLink(){
    //TODO get link to discord
    this.router.navigate(["/navmenu/navhome"]);

  }

  back(){
    this.location.back();
  }

  sendFeedback(){
    window.open(
      "https://forms.gle/1hicwfFQVG9GnPh7A",
      '_blank'
    );
  }

  calculateClasses(){
    return "d" + (this.lastCompletedDream+1);
  }

  getText(){
    switch (this.lastCompletedDream) {
      case 1:
        return "More dimensions of the In-Between await.";
      case 2:
        return "The world is revealing itself.";
      case 3:
        return "We’re a new team and we need your feedback to build and improve Betwixt. If you could spare 2 minutes now, we’d love to ask you 4 quick questions.";
      case 4:
        return "A whole new world lies ahead.";
      case 5:
        return "Thank you for playing the first five dreams of Betwixt. There are more on the way. In the meantime, feel free to use the Quests. ";
      default:
        break;
    }
  }

  saveCompletedDreamToFirebase(){
    Storage.get({ key: 'userid' }).then( res => {
      if(res.value != null){  
        this.afs.collection('users').doc(res.value).update({
          completeDream: this.lastCompletedDream
        })
      }
    }).catch();
  }

  //to be moved in a service, if a user is available
  saveTokenToFirebase(){
    Storage.get({ key: 'userid' }).then( res => {
      var useriD = res.value;
      Storage.get({ key: 'notif_token' }).then( res2 => {
        this.afs.collection('users').doc(useriD).update({
          token: res2
        });
      }).catch();
    }).catch();

  }

  
    
  forward(page){
    page = page + "/" + (this.lastCompletedDream+1);
    this.navCtrl.navigateForward(page, { animated: false });
  }
}