import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { fadeAnimation } from 'src/helpers/transitions-helper';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  constructor(private navCtrl: NavController) { }

  ngOnInit(): void {
  }

  continueClick() {
    this.navCtrl.navigateForward(["disclaimer"], { animation: fadeAnimation });
  }
}