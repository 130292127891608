<ion-content #page>
    <div class="cmn-wrapper">
        
        <app-sos></app-sos>
        <div class="cmn-navmenu-title cmn-text-shadow">Home</div>

        <div class="primarySlider">
            <div class="cmn-font-helvetica cmn-regular-spacing">
                Dreams
            </div>
            <app-card-carousel [item]="dreamsCarousel" [primary]="'true'"></app-card-carousel>
        </div>

        <div class="section">
            <div class="cmn-font-helvetica cmn-regular-spacing">
                Insight cards
            </div>
            <div class="cmn-font-helvetica cmn-font-light cmn-regular-spacing hide-small">
                Stories from the In-Between
            </div>
        </div>

        <app-cards></app-cards>

        <div class="section">
            <div class="cmn-font-helvetica cmn-regular-spacing">
                More information
            </div>
            <app-links [inputItems]="linkProps"></app-links>
        </div>

        <div class="section last">
            <div class="ion-text-uppercase cmn-font-helvetica ion-text-center">
                Share & care
            </div>
            <br>
            <div class="cmn-navmenu-title cmn-text-shadow ion-text-center">
                Share with <br> friends & family
            </div>
            <div (click)="shareBetwixt()"
                class="share-button text-center cmn-font-helvetica cmn-font-light cmn-regular-spacing">
                Share Betwixt
            </div>
        </div>
    </div>
</ion-content>