<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false">
    <div class="cmn-wrapper">
      <div class="cmn-font-helvetica ion-text-uppercase">
        <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
        </ion-icon>
      </div>
      <div class="cmn-serif-title ion-text-center big-margin-top">
        Game Complete
      </div>
      <br>
      <div class="disclaimer-text ion-text-center">
        <div class="image-wrapper">
          <img class="end-diamond" src="assets/images/diamond.png">
        </div>
        <div class="end-text">
          You already have the answers. <br>
          It’s time to go out into the world <br> and live them.
        </div>
      </div>

    </div>
  </ion-content>
</ion-app>