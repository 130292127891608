<ion-content>
    <div class="cmn-wrapper">
        <app-sos></app-sos>
        <div class="cmn-navmenu-title cmn-text-shadow">Quests</div>
       
        <div *ngFor="let carousel of ultimateCarousel; index as i">
            <div *ngIf="carousel.length" class="cmn-font-helvetica cmn-regular-spacing">
                Dream {{ romanize(ultimateCarousel.length-i) }}
            </div>
            <!-- <div *ngIf="carousel.length" class="cmn-font-helvetica cmn-font-light cmn-regular-spacing hide-small">
                Quests
            </div> -->
            <app-card-carousel [item]="carousel"></app-card-carousel>
            <br class="hide-small"><br>
        </div>
    </div>
</ion-content> 