
export class FlipCardHelper {

  static getStyles(currentDream, intro?) {
    var styles = { frontCardStyle: "", backCardStyle: "" }
    var dreamColor;

    var varName = '--d' + currentDream + '-gradient';
    if (currentDream == 0) {
      varName = '--d1-gradient';
    }
    dreamColor = getComputedStyle(document.documentElement).getPropertyValue(varName);

    if (currentDream > 11 || intro) {
      styles.frontCardStyle =
        "background-image: url('https://firebasestorage.googleapis.com/v0/b/betwixt-game.appspot.com/o/public%2Fpre-reveal_card_pwa_2.png?alt=media&token=c58b0b67-05c3-4419-bef9-bda6c2c1308b'), ";
    }
    else if (currentDream == 0) {
      styles.frontCardStyle =
        "background-image: url('https://firebasestorage.googleapis.com/v0/b/betwixt-game.appspot.com/o/public%2Fdream-images%2Fd1.png?alt=media'), ";
    }
    else {
      styles.frontCardStyle =
        "background-image: url('https://firebasestorage.googleapis.com/v0/b/betwixt-game.appspot.com/o/public%2Fdream-images%2Fd" + currentDream + ".png?alt=media'), ";
    }

    // TODO: FIX WITH ART INTEGRATION
    // styles.frontCardStyle += dreamColor;
    styles.frontCardStyle += "linear-gradient(rgb(232 242 253) 0.01%, rgb(232 242 253) 99.99%);"

    if (intro) {
      styles.backCardStyle = "background: black"
    } else {
      styles.backCardStyle = "background: " + dreamColor;
    }

    return styles;
  }
}