import { Injectable } from '@angular/core';
import { Howl, Howler } from 'howler';
import { StateManagementService } from './state-management.service';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  public audio: any;
  public src: string;
  public visible: boolean;

  public playing: boolean;
  public paused: boolean = false;
  public loaded: boolean;

  public currentTracks: any[];

  public sound: any;
  public soundFX: any;

  currentSoundNew: any;
  currentFxNew: any;

  private localBaseUri = "../assets/audio/";

  constructor(private stateManagementService: StateManagementService) {
    this.audio = new Audio();
    this.playing = false;
    this.loaded = false;

    this.visible = false;
    this.currentTracks = [];
  }


  public playAudioImproved(soundName: string) {

    if (this.currentSoundNew != null) {
      this.currentSoundNew.unload();
    }

    this.currentSoundNew = new Howl({
      src: this.localBaseUri + soundName,
      preload: true,
      html5: false,
      loop: true,
      onload: () => {
        this.currentSoundNew.fade(0, 1, 6000);
        this.currentSoundNew.play();
      }
    });
  }

  public changeSoundImproved(soundName?: string, duration: number = 0) {
    console.log("SOUND soundName " + soundName);
    console.log("SOUND existing Howl " + this.currentSoundNew?._src);

    //if soundName is not passed, fallback to existing sound, eg. when dream is resumed
    if (!soundName) {
      soundName = localStorage.getItem("currentSound");
      console.log("SOUND taking from storage " + soundName);
    }

    duration = duration * 1000;

    //load new sound
    var tempSoundNew = new Howl({
      src: this.localBaseUri + soundName,
      preload: true,
      html5: false,
      loop: true,
      onload: () => {
        tempSoundNew.fade(0, 1, 6000);
        tempSoundNew.play();
      }
    });


    //fade and unload current sound
    if (this.currentSoundNew != null) {
      var tempSoundOld = this.currentSoundNew;
      tempSoundOld.fade(1, 0, duration);
      setTimeout(() => tempSoundOld.unload(), duration);
    }

    this.currentSoundNew = tempSoundNew;

    if (soundName) {
      this.stateManagementService.saveDataToLocalStorage([{
        key: "currentSound",
        value: soundName.toString()
      }])
    }

    this.playing = true;
    this.paused = false;

    console.log("SOUND Changed to");
    console.log(this.currentSoundNew._src);
  }

  public fadeOutImproved(duration?: number) {
    if (this.currentSoundNew) {
      if (duration) {
        duration = duration * 1000;
        this.currentSoundNew.fade(1, 0, duration);
      }
      else {
        this.currentSoundNew.fade(1, 0, 6000);
      }
    }
    //localStorage.removeItem('currentSound');
  }

  public playFXImproved(soundFXName: string) {
    if (this.currentFxNew != null) {
      this.currentFxNew.unload();
    }

    this.currentFxNew = new Howl({
      src: this.localBaseUri + soundFXName,
      preload: true,
      html5: false,
      onload: () => {
        this.currentFxNew.play();
      }
    });
  }

  public stopFXImproved() {
    if (this.currentFxNew != null) {
      this.currentFxNew.stop();
      this.currentFxNew.unload();
    }
  }

  public pauseBackgroundAudio() {
    this.paused = true;

    this.currentSoundNew.pause();
    this.playing = false;
  }

  public unloadAllAudio() {
    this.currentTracks.forEach(track => {
      if (track.howl) {
        track.howl.unload();
      }
    });
    this.currentTracks = [];
  }

  public stopBackgroundSounds() {
    Howler.stop();
  }

  public unloadHowler() {
    console.log("sound context unloaded")
    Howler.unload();
  }
  public ctxResume() {
    //not verified to work
    Howler.mute(false)
    // Howler.ctx.resume();
  }
}