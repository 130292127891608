<ion-app>
    <ion-header class="ion-no-border">
     </ion-header>
    <ion-content scrollY="false">
      <div class="cmn-wrapper">
        <div class="upper-menu ion-text-center flex-container justify-center">
            <div class="flex-item">
                <ion-icon class="cmn-icon-helvetica"  (click)="back()" name="chevron-back-outline"></ion-icon>
            </div>
            <div class="flex-item  cmn-font-helvetica cmn-enabled ion-text-uppercase"> {{meditationTitle}} </div>
            <div class="flex-item sos cmn-font-helvetica" (click)="openLink()">SOS</div>
        </div> 
        <div class="cmn-mid-text music-icon" (click)="toggleSound()"></div>
        <div *ngIf="!playing" class="cmn-mid-text" (click)="toggleSound()">
            <ion-icon name="play" size="large"></ion-icon>
        </div>
        <div *ngIf="playing" class="cmn-mid-text" (click)="toggleSound()">
            <ion-icon name="pause" size="large"></ion-icon>
        </div>
       </div> 
       <audio id="player" controls controlsList="nodownload" class="">
        <source src="{{'https://firebasestorage.googleapis.com/v0/b/betwixt-game.appspot.com/o/public%2Fmeditations%2Fdream_'  + navDream + '_guided_med.mp3?alt=media'}}" type="audio/mpeg">
      </audio>
    </ion-content>
</ion-app>