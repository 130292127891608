<ion-app>
    <ion-header class="ion-no-border">
     </ion-header>
    <ion-content scrollY="false">   
       <div class="cmn-wrapper">
         <div class="ion-text-center ion-text-uppercase cmn-font-helvetica">Welcome to the in-between</div>
          <div #secondary class="cmn-mid-text cmn-serif-title opacityHidden cmn-text-shadow" [innerHTML]='secondaryText'></div>
       </div>
       <div #continue (click)="continueClick()" class="cmn-bottom-text ion-text-uppercase cmn-font-helvetica cmn-enabled opacityHidden"> Continue</div>
      
    </ion-content>
   </ion-app>