import { Injectable } from '@angular/core';
import {
  Plugins
} from '@capacitor/core';


import { AngularFirestore, AngularFirestoreCollection, DocumentSnapshot, Action } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})

export class JournalService {
  constructor(private afs: AngularFirestore) {
  }

  journalCollection: AngularFirestoreCollection<any>;

  async initiateCollections(){
    const { value } = await Storage.get({ key: 'userid' })
    var emptyDEntries = {
      d0: 0,
      d1: 0,
      d2: 0,
      d3: 0,
      d4: 0,
      d5: 0
    }

    this.afs.collection<any>('journal').doc(value).get().pipe(first()).subscribe(doc => {
      if(!doc.exists){
        this.afs.collection<any>('journal').doc(value).set(emptyDEntries).catch();
      }
    });
  }

  async getTotalNumberOfEntries(){
    const { value } = await Storage.get({ key: 'userid' })
    if(value!= null){
      return this.afs.collection<any>('journal').doc(value).snapshotChanges();     
    }else{
      null;
    }
  }

  async getEntriesPerDream(dream){
    const { value } = await Storage.get({ key: 'userid' })
    return this.afs.collection<any>('journal').doc(value).collection("d" + dream).snapshotChanges();      
  }

  saveEntry(dream: number, objectToSave){
    Storage.get({ key: 'userid' }).then( useridres => {
      this.afs.collection<any>('journal').doc(useridres.value).collection("d" + dream).add(objectToSave).catch()
      .then(t=> {
        var allEntriesCount = localStorage.getItem("journalEntryCount");
        var parsedEntry = JSON.parse(allEntriesCount);

        //increment
        var entryCount = parsedEntry["d" + dream];
        entryCount = entryCount +=1;

        //update local:
        parsedEntry["d" + dream] = entryCount;
        localStorage.setItem("journalEntryCount", JSON.stringify(parsedEntry));

        //update AFS:
        var updatedPartialEntry = {};
        updatedPartialEntry["d" + dream] = entryCount;
        this.afs.collection<any>('journal').doc(useridres.value).update(updatedPartialEntry);
      });
    }).catch();
  }

}