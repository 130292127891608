import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgotten-pass',
  templateUrl: './forgotten-pass.component.html',
  styleUrls: ['./forgotten-pass.component.css']
})
export class ForgottenPassComponent implements OnInit {

  public messageSuccess = "";
  public messageError = "";
  public showMessage = false;
  public isError = false;

  constructor(public ngFireAuth: AngularFireAuth, private _location: Location) { }

  ngOnInit(): void {
  }

  back() {
    this._location.back();
  }

  resetPass(email: string | number) {
    if (typeof (email) != 'string') {
      return;
    }
    this.ngFireAuth.sendPasswordResetEmail(email).then(() => {
      this.messageSuccess = "Thank you. We’ve sent you an email with a password reset link. If you don’t receive it, please check your spam.";
      this.isError = false;
    }).catch((error) => {
      if (error.code == "auth/user-not-found") {
        this.messageError = "Email adrress not recognised"
      }
      else if (error.code == "auth/invalid-email") {
        this.messageError = "Invalid email"
      }
      else {
        this.messageError = error;
      }
      this.isError = true;
    })
    this.showMessage = true;
  }
}