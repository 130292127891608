<ion-content [ngClass]="calculateClasses()">
    <div class="cmn-wrapper">
        <div class="cmn-font-helvetica ion-text-uppercase">
            <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
            </ion-icon>
        </div>

        <br><br>

        <div *ngIf="this.navDream == 1" class="disclaimer-text">
            <p>
                Studies<sup>1</sup> have shown significant physical and mental health benefits for people who journal
                for as little as two minutes per day.
            </p>
            <p>
                To help you build this powerful practice, we’ve written a different journaling prompt for each dream of
                Betwixt. You can answer each question as many times as you like.
            </p>
            <p>
                This simple question for Dream 1 has been written to help you view your sense of self in an objective
                way, and to consider the actions and pastimes that help you feel most aligned with who you really are.
            </p>

            <br><br>

            <p>
                1. <a href="https://bpspsychub.onlinelibrary.wiley.com/doi/abs/10.1348/135910707X250910"
                    target="_blank">https://bpspsychub.onlinelibrary.wiley.com/doi/abs/10.1348/135910707X250910</a>
            </p>
        </div>

        <div *ngIf="this.navDream == 2" class="disclaimer-text">
            <p>
                Your values are a core part of your personality; they guide your sense of right and wrong, as well as
                your behaviour and decision-making. This means that understanding your personal system of values is a
                crucial part of building self-awareness and living a healthy, fulfilling and meaningful life.
            </p>
            <p>
                This question has been written to help you tune into your values — that is, what’s important to you — on
                a moment-to-moment basis.
            </p>
            <p>
                Feel free to go with anything that comes to mind when you answer, and then reflect on the kind of role
                that value (or valued thing/person) plays in the greater narrative of your life.
            </p>
            <p>
                Once you’ve completed this quest a number of times, you can look back over your answers and take stock
                of any common themes that arise.
            </p>
        </div>

        <div *ngIf="this.navDream == 3" class="disclaimer-text">
            <p>
                Negative experiences tend to stick in the memory more easily than the positive. This happens because the
                mind wants to remember anything that has caused pain in order to avoid it in the future.
            </p>
            <p>
                What this means is that it’s important to pay attention to our lighter, calmer and more positive
                memories, and the simple act of describing those moments in order to relive them has been shown to
                improve mood, wellbeing and self-acceptance, and lead to personal growth.
            </p>
            <p>Note: for positive journaling, <i>reliving</i> a memory is better than re-construing or analysing. Here,
                feel free to simply describe the moment, list the positive emotions it evoked and enjoy going back to
                something fond or soothing.
            </p>
        </div>

        <div *ngIf="this.navDream == 4" class="disclaimer-text">
            <p>
                Emotional literacy is the ability to express your feelings using speech and other forms of
                communication. It is important for:
            </p>
            <ul>
                <li>The development of self-confidence and self-esteem;</li>
                <li>Social and emotional development, including the development of empathy;</li>
                <li>Building the skills of emotional self-management and impulse control;</li>
                <li>Building an understanding of your feelings, needs and values.</li>
            </ul>
            <p>
                This prompt has been written to help you tune into and label your emotions on a moment-to-moment basis,
                and to draw causal connections between the choices you make and the emotions that follow.
            </p>
            <p>
                Remember that your emotions aren’t caused by the outside world — they are a combined result of your
                perspective and existing associations, your relationship to the outside world and the actions you choose
                to take. This means that only you can control how you feel, which is a good thing.
            </p>
            <p>
                For example, if a beautiful sunset causes you to feel peaceful, then it can only do so if you choose to
                stop and enjoy it. By the same measure, someone’s deceitful behaviour cannot make you feel angry unless
                you choose to respond in that way (which may well be an appropriate and healthy response, of course).
            </p>
            <p>
                The point here is to own your emotions, because this allows you to control the ways in which you express
                them.
            </p>
        </div>

        <div *ngIf="this.navDream == 5" class="disclaimer-text">
            <p>
                It’s all too easy to neglect self-care. People do this because they believe it’s indulgent to put their
                needs before the needs of others, or because they prioritise their work, social life, etc. above rest,
                recovery and time to relax.
            </p>
            <p>
                Self-care routines, however, are important and can help to reduce anxiety, stress and depression, as
                well as to improve concentration, productivity, happiness, energy levels and even physical health.
            </p>
            <p>
                As life is dynamic, your self-care routines can be, too. This question is here to help you consider the
                different ways for you to look after yourself and how they can fit into your existing routines.
            </p>
            <p>
                Here are some examples of common self-care activities:
            </p>
            <ul>
                <li><b>Creative:</b> drawing, writing, singing or listening to music;</li>
                <li><b>Restful:</b> taking breaks from work, breathing exercises, taking a bath; </li>
                <li><b>Active:</b> walking, stretching, working out;</li>
                <li><b>Stimulating:</b> reading, listening to audiobooks, completing puzzles, trying something new;</li>
                <li><b>Social:</b> meeting with friends or talking to someone about what you’re going through; </li>
                <li><b>Spiritual:</b> meditation, prayer, reflection, mindfulness practices.</li>
            </ul>
        </div>

        <br><br>

        <div #continue (click)="back()" class="ion-text-center ion-text-uppercase cmn-font-helvetica cmn-enabled">Back
            to prompt</div>
        <br><br><br>
    </div>
</ion-content>