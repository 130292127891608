<ion-content>
    <div class="cmn-wrapper">
        <div class="image-wrapper text-center">
            <img class="image" src="../../assets/images/dream-completed.png">
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="ion-text-uppercase ion-text-center cmn-font-helvetica menu-option cmn-enabled"
                    [routerLink]="'/dreamend'">Continue</div>
            </div>
        </div>
    </div>
</ion-content>