import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

const { PushNotifications, Storage, LocalNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  constructor(private angularFireAuth : AngularFireAuth, private afs: AngularFirestore) {
  }

  public initPush(){
    this.registerPush();
  }

  public async initPushAsync(){
    return new Promise((resolve, reject) => {
      this.registerPush();
      resolve();
    });
  }

  hasGivenPermission() {
    var hasAccepted;
    (async () => {
      hasAccepted = (await Storage.get({ key: 'notif_token' })).value;
    });

    if(hasAccepted != null){
      return false;
    }
    return false;
  }

  private registerPush(){
    var that = this;
    PushNotifications.requestPermission().then((permission) => {
      if(permission.granted){
        PushNotifications.register();
      }
    });

    PushNotifications.addListener(
      'registration',
      async (token: PushNotificationToken) => {
        console.log('My token : ' + JSON.stringify(token));
        await Storage.set({
          key: 'notif_token',
          value: token.value
        })

        setTimeout(()=>{
          console.log("scgeduled");
          that.scheduleLocalNotification(
            that.ids.accept_prompt,
            that.payloads.accept_prompt, 
            new Date(Date.now() + 1000 * 3600), null, "d1_accept_prompt");
        },3000)

      }
    );
  }

  async scheduleLocalNotification(id : number, message: string, time : Date, route?: string, data? : string){
    const notifs = await LocalNotifications.schedule({
      notifications: [
        {
          title: 'Betwixt',
          body: message,
          id: id,
          schedule: { at: time },
          sound: null,
          attachments: null,
          actionTypeId: '',
          extra: {
            route: route,
            data: data
          },
        },
      ],
    });
  }



  payloads = { accept_prompt : "Your journey awaits. Trust your guide. Face your monster. Free your imagination.", 
               d1_start: "The In-Between is waiting for you. What will you find in this dream reality?",
               d1_end: "Are you ready to continue your journey through the In-Between?",
               discord_notification: "You have a new message from the voice",
               journal_reminder: "It's time to take to the Journal." ,
               journal_daily : "It's time for our daily reflection.",} ;

  ids = { accept_prompt : 1,
          d1_start: 1,
          d1_end : 1,
          discord_notification : 1,
          journal_reminder : 5,
          journal_daily: 6
  }
}