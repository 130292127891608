import { Component, OnInit } from '@angular/core';
import { StoryService } from 'src/services/story.service';
import { ThemeService } from 'src/services/theme.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-missions',
  templateUrl: './missions.component.html',
  styleUrls: ['./missions.component.css']
})
export class MissionsComponent implements OnInit {  

  constructor(public storyService : StoryService,
              private themeService : ThemeService,
              private router: Router) {}

  ngOnInit() {
    // if (!this.storyService.story)
    // {
    //   this.storyService.startGame();
    // }
    this.themeService.changeThemeById(4);

    
  }

  ngOnDestroy() {
    this.themeService.loadPreferredTheme();
  }

  onBoardMissionAndRedirect(){
    localStorage.setItem("missionsOnboarded", "true");
    this.router.navigate(['/missions/dream/1']);
  } 
}
