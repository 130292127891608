import { AnimationController } from '@ionic/angular';

// export default class TransitionsHelper {
   
// }

const animationCtrl = new AnimationController();
export const expandAnimation = (_: HTMLElement, opts: any) => {

  const rootTransition = animationCtrl.create();

  const enterTransition = animationCtrl.create().addElement(opts.leavingEl);
  const exitTransition = animationCtrl.create().addElement(opts.enteringEl);

  enterTransition.fromTo('opacity', '1', '0').fromTo('transform', 'scale(1)', 'scale(1.35)').duration(850);
  exitTransition.fromTo('opacity', '0', '1').delay(900).duration(300);

  rootTransition.addAnimation([enterTransition, exitTransition]);

  return rootTransition;
};

export const expandAnimationDream = (_: HTMLElement, opts: any) => {

  const rootTransition = animationCtrl.create();

  const enterTransition = animationCtrl.create().addElement(opts.leavingEl);
  const exitTransition = animationCtrl.create().addElement(opts.enteringEl);

  enterTransition.fromTo('opacity', '1', '0').fromTo('transform', 'scale(1)', 'scale(1.3)').duration(850);
  exitTransition.fromTo('opacity', '0', '1').delay(500).duration(450);


  // enterTransition.fromTo('opacity', '1', '1').fromTo('transform', 'scale(1)', 'scale(1.35)').duration(850);
  // exitTransition.fromTo('opacity', '0', '1').delay(900).duration(300);

  rootTransition.addAnimation([enterTransition, exitTransition]);

  return rootTransition;
};

// export const shrinkAnimation = (_: HTMLElement, opts: any) => {

//     const rootTransition = animationCtrl.create();
  
//     const enterTransition = animationCtrl.create().addElement(opts.leavingEl);
//     const exitTransition = animationCtrl.create().addElement(opts.enteringEl);
  
//     enterTransition.fromTo('opacity', '1', '0').fromTo('transform', 'scale(1)', 'scale(0.65)').duration(850);
//     exitTransition.fromTo('opacity', '0', '1').delay(850).duration(500);
  
//     rootTransition.addAnimation([enterTransition, exitTransition]);
  
//     return rootTransition;
//   };



// export const shrinkAnimationDream = (_: HTMLElement, opts: any) => {

//   const rootTransition = animationCtrl.create();

//   const enterTransition = animationCtrl.create().addElement(opts.leavingEl);
//   const exitTransition = animationCtrl.create().addElement(opts.enteringEl);

//   enterTransition.fromTo('opacity', '1', '0').fromTo('transform', 'scale(1)', 'scale(0.65)').duration(850);
//   exitTransition.fromTo('opacity', '0', '1').delay(850).duration(300);

//   rootTransition.addAnimation([enterTransition, exitTransition]);

//   return rootTransition;
// };

export const fadeAnimation = (_: HTMLElement, opts: any) => {

    const rootTransition = animationCtrl.create();
  
    const enterTransition = animationCtrl.create().addElement(opts.leavingEl);
    const exitTransition = animationCtrl.create().addElement(opts.enteringEl);
  
    enterTransition.fromTo('opacity', '1', '0').duration(600);
    exitTransition.fromTo('opacity', '0', '1').delay(600).duration(600);
  
    rootTransition.addAnimation([enterTransition, exitTransition]);
  
    return rootTransition;
  };
  
  

