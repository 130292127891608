import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';



import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';

const { PushNotifications, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor(private angularFireAuth : AngularFireAuth) {
  }

  isLoggedIn$ = new BehaviorSubject(undefined);
}