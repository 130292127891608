<div>
    <div class="text-center wrapper">
        <div class="title">
            <p>Betwixt missions</p>
            <hr>
        </div>

        <p>Welcome back to the waking world! </p>

        <p>You have a choice of three optional missions to complete between each dream:</p>

        <div class="practical">
            <p>
                <span class="mission-title"> <b>Mind Missions</b></span>
                <br>
                Your thoughts, emotions and behaviours all stem from your beliefs. These Missions help you to conduct a
                debate with your own mind so that you can challenge the beliefs that stand in your way, and test drive
                new and better perspectives associated with high levels of emotional wellbeing.
        </div>

        <div class="creative">
            <p>
                <span class="mission-title"> <b>Creative Missions</b></span>
                <br>
                A creative self-awareness-building task, taking a deeper dive into key topics.
            </p>
        </div>


        <div class="meditative">
            <p>
                <span class="mission-title"> <b>Meditative Missions</b></span>
                <br>
                A guided meditation which will help you to learn while you relax.
            </p>
        </div>

        <br>

        <div class="disclaimer-text">
            These missions are not mandatory. You are in control. Engage with the ones that look helpful
            and leave the rest. If you want to return to the missions at a later time, you can find them by clicking
            the “missions” tab at the top of the page.
        </div>

    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="action-bar__item col-sm-12 col-lg-6" (click)="onBoardMissionAndRedirect()">
                <button class="action-bar__button">Continue to missions</button>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <div id="footer" class="fixed-bottom text-left bottombar">
        <div class="footer-wrapper">
            <a routerLink="/game" routerLinkActive="active" class="footerText"><i class="fa fa-arrow-left"
                    aria-hidden="true"></i> Back to the
                story</a>
        </div>
    </div>
</div>