import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/services/notification.service';

@Component({
  selector: 'signup-options',
  templateUrl: 'signupoptions.compontent.html',
  styleUrls: ['signupoptions.component.css']
})
export class SignUpOptionsCompontent implements OnInit{

  constructor(public notificationService : NotificationService) {}
  ngOnInit(): void {
    this.notificationService.initPush();
  }
}
