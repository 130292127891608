<ion-content>
    <div class="cmn-wrapper">
        <app-sos></app-sos>
        <div class="cmn-navmenu-title cmn-text-shadow">Profile</div>

        <div class="section">
            <div class="cmn-font-helvetica cmn-regular-spacing">
                More information
            </div>
           <app-links [inputItems]="linkProps"></app-links>
        </div>
    </div>

</ion-content>