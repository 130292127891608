export const FIRST_SOUNDS = [
    //0, 1 
    '', '',
    //2
    'd1l1.mp3',
    //3
    'd2l1.mp3',
    //4
    'd4l1.mp3',
    //5
    'ambience_full_loop_2.mp3',
    //6
    'ambience_full_loop_2.mp3',
    //7
    'cathedral_loop.mp3',
    //8
    'glistening_ambience_no_life.mp3',
    //9
    'glistening_ambience_no_life.mp3',
    //10
    'glistening_ambience_med_life.mp3',
    //11
    'glistening_ambience_no_life.mp3']
