<ion-content>
    <ion-tabs>
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="navhome">
            <!-- <ion-icon name="ios-custom-home"></ion-icon> -->
            <ion-icon name="home-outline"></ion-icon>
          </ion-tab-button>      
          <ion-tab-button tab="navquests">
            <ion-icon name="stop-outline" style="transform: rotate(45deg);"></ion-icon>
          </ion-tab-button>
          <ion-tab-button tab="navjournal">
            <ion-icon name="journal-outline"></ion-icon>
          </ion-tab-button>
          <ion-tab-button tab="navprofile">
            <ion-icon name="person-outline"></ion-icon>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
</ion-content>
