import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserInputTransformationService {

  //constructor() { }

  //remove all dots or spaces from the end of the string
  public sanitizeEndOfInput(str: string): string {
    while (str[str.length - 1] === "." || str[str.length - 1] === " " || str[str.length - 1] === "?" || str[str.length - 1] === "!") {
      str = str.slice(0, -1);
    }
    return str;
  }

  public decapitalizeFirstLetter(str: string): string {
    //Decapitalization transformation to be run only if the input doesn't start with "I " or "I'". the "I'" takes care of "I'd", "I'm", "I'll" etc.
    if (str.length > 0 && str.slice(0, 2) != "I " && str.slice(0,2) != "I'") {
      str = str.substring(0,1).toLowerCase() + str.slice(1);
    }
    return str;
  }

  public capitalizeFirstLetter(str: string): string {
    if (str.length > 0) {
      str = str.substring(0, 1).toUpperCase() + str.slice(1);
    }
    return str;
  }

  public quoteSwap(str: string) {
  return str.replace(/[\u2018\u2019]/g, "'");
}


  public replacePronounsAtBeginningOfInput(str: string): string {
    if (str.slice(0, 3) == "Am ") { str = "are " + str.slice(3); }
    if (str.slice(0, 3) == "am ") { str = "are " + str.slice(3); }
    if (str.slice(0, 3) == "My ") { str = "your " + str.slice(3); }
    if (str.slice(0, 3) == "my ") { str = "your " + str.slice(3); }
    if (str.slice(0, 3) == "Me ") { str = "you " + str.slice(3); }
    if (str.slice(0, 3) == "Me.") { str = "you" + str.slice(3); }
    if (str.slice(0, 3) == "me ") { str = "you " + str.slice(3); }
    if (str.slice(0, 3) == "me.") { str = "you" + str.slice(3); }
    if (str.slice(0, 3) == "We ") { str = "you " + str.slice(3); }
    if (str.slice(0, 3) == "we ") { str = "you " + str.slice(3); }
    if (str.slice(0, 4) == "our ") { str = "your " + str.slice(4); }
    if (str.slice(0, 4) == "Our ") { str = "your " + str.slice(4); }
    if (str.slice(0, 6) == "I was ") { str = "you were " + str.slice(6); }
    if (str.slice(0, 7) == "I was. ") { str = "you were. " + str.slice(7); }
    if (str.slice(0, 6) == "i was ") { str = "you were " + str.slice(6); }
    if (str.slice(0, 7) == "i was. ") { str = "you were. " + str.slice(7); }
    return str;
  }

  public replacePronounsAtEndOfInput(str: string): string {
    // if (str.slice(str.length-11, str.length) == ". Wasn't I?") { str = str.slice(0, str.length - 11) + ". Weren't you?" }
    if (str.slice(str.length - 10, str.length) == ". Wasn't I") { str = str.slice(0, str.length - 10) + ". Weren't you" }
    // if (str.slice(str.length-11, str.length) == ". I wasn't.") { str = str.slice(0, str.length-11) + ". You weren't" }
    // if (str.slice(str.length-10, str.length) == " wasn't I?") { str = str.slice(0, str.length - 10) + " weren't you?" }
    // if (str.slice(str.length-10, str.length) == " wasn't I.") { str = str.slice(0, str.length - 10) + " weren't you" }
    if (str.slice(str.length-10, str.length) == ". I wasn't") { str = str.slice(0, str.length-10) + ". You weren't" }
    // if (str.slice(str.length-10, str.length) == " I wasn't.") { str = str.slice(0, str.length-9) + " you weren't" }
    if (str.slice(str.length-9, str.length) == " wasn't I") { str = str.slice(0, str.length - 9) + " weren't you" }
    if (str.slice(str.length-9, str.length) == " I wasn't") { str = str.slice(0, str.length-9) + " you weren't" }
    // if (str.slice(str.length-8, str.length) == ". I was.") { str = str.slice(0, str.length - 7) + ". You were" }
    if (str.slice(str.length-8, str.length) == ", was I?") { str = str.slice(0, str.length - 7) + ", were you?" }
    if (str.slice(str.length-7, str.length) == ". I was") { str = str.slice(0, str.length - 7) + ". You were" }
    // if (str.slice(str.length-7, str.length) == " I was.") { str = str.slice(0, str.length-7) + ". you were" }
    // if (str.slice(str.length-7, str.length) == ". I am.") { str = str.slice(0, str.length - 7) + ". You are" }
    // if (str.slice(str.length-7, str.length) == " Was I?") { str = str.slice(0, str.length - 7) + " Were you?" }
    // if (str.slice(str.length-7, str.length) == ", am I?") { str = str.slice(0, str.length - 7) + ", are you?" }
    if (str.slice(str.length - 6, str.length) == " Was I") { str = str.slice(0, str.length - 6) + " Were you" }
    if (str.slice(str.length - 6, str.length) == " was I") { str = str.slice(0, str.length - 6) + " were you" }
    if (str.slice(str.length - 6, str.length) == ", am I") { str = str.slice(0, str.length - 6) + ", are you" }
    // if (str.slice(str.length-6, str.length) == " mine.") { str = str.slice(0, str.length - 6) + " yours" }
    // if (str.slice(str.length-6, str.length) == " I am.") { str = str.slice(0, str.length - 6) + " you are" }
    // if (str.slice(str.length-6, str.length) == " ouwwwrs.") { str = str.slice(0, str.length - 6) + " yours" }
    // if (str.slice(str.length-6, str.length) == " Am I?") { str = str.slice(0, str.length - 6) + " Are you?" }
    if (str.slice(str.length - 5, str.length) == " Am I") { str = str.slice(0, str.length - 5) + " Are you" }
    if (str.slice(str.length-6, str.length) == " I was") { str = str.slice(0, str.length-6) + " you were" }
    if (str.slice(str.length-6, str.length) == ". I am") { str = str.slice(0, str.length-6) + ". You are" }
    if (str.slice(str.length-5, str.length) == " I am") { str = str.slice(0, str.length-5) + " you are" }
    if (str.slice(str.length-5, str.length) == " mine") { str = str.slice(0, str.length-5) + " yours" }
    if (str.slice(str.length-5, str.length) == " ours") { str = str.slice(0, str.length-5) + " yours" }
    // if (str.slice(str.length-4, str.length) == " me.") { str = str.slice(0, str.length-4) + " you" }
    // if (str.slice(str.length-4, str.length) == " Me.") { str = str.slice(0, str.length - 4) + " You" }
    if (str.slice(str.length-3, str.length) == " me") { str = str.slice(0, str.length-3) + " you" }
    if (str.slice(str.length-3, str.length) == " Me") { str = str.slice(0, str.length-3) + " You" }
    if (str.slice(str.length - 3, str.length) == " we") { str = str.slice(0, str.length - 3) + " you" }
    if (str.slice(str.length - 3, str.length) == " We") { str = str.slice(0, str.length - 3) + " You" }
    return str;
  }

  public replacePronouns(str: string): string {

    // console.log("input: " + str);
    function replaceAll(string: string, search: string, replace: string) {
      return string.split(search).join(replace);
    }


    str = this.replacePronounsAtEndOfInput(str);
    //For the inputs where we need to scan the beginning of inputs for certain transformations.
    //This is done so that the below in combination with transforming only full words/phrases by surrounding
    //the words/phrases with spaces on both sides, takes care of all the cases and avoids false positives

    str = this.replacePronounsAtBeginningOfInput(str);

    //Spaces are used in both the search and replace strings, to increase the probability of only transforming whole words and not part of words.
    //e.g. 'gramps' would change to 'grareps' because the 'am' in the middle gets replaced by 'are'

    str = replaceAll(str, ". I'd ", ". You'd ");
    str = replaceAll(str, ". i'd ", ". You'd ");
    str = replaceAll(str, ". I'll ", ". You'll ");
    str = replaceAll(str, ". i'll ", ". You'll ");
    str = replaceAll(str, ". I'm ", ". You're ");
    str = replaceAll(str, ". i'm ", ". You're ");
    str = replaceAll(str, ". I was ", ". You were ");
    str = replaceAll(str, ". i was ", ". You were ");
    str = replaceAll(str, ". I wasn't", ". You weren't");
    str = replaceAll(str, ". i wasn't", ". You weren't");
    str = replaceAll(str, ". I ", ". You ");
    str = replaceAll(str, ". i ", ". You ");
    str = replaceAll(str, ". I've ", ". You've ");
    str = replaceAll(str, ". i've ", ". You've ");
    str = replaceAll(str, "! I'd ", "! You'd ");
    str = replaceAll(str, "! i'd ", "! You'd ");
    str = replaceAll(str, "! I'll ", "! You'll ");
    str = replaceAll(str, "! i'll ", "! You'll ");
    str = replaceAll(str, "! I'm ", "! You're ");
    str = replaceAll(str, "! i'm ", "! You're ");
    str = replaceAll(str, "! I was ", "! You were ");
    str = replaceAll(str, "! i was ", "! You were ");
    str = replaceAll(str, "! I ", "! You ");
    str = replaceAll(str, "! i ", "! You ");
    str = replaceAll(str, "! I wasn't", "! You weren't");
    str = replaceAll(str, "! i wasn't", "! You weren't");
    str = replaceAll(str, "? I'd ", "? You'd ");
    str = replaceAll(str, "? i'd ", "? You'd ");
    str = replaceAll(str, "? I'll ", "? You'll ");
    str = replaceAll(str, "? i'll ", "? You'll ");
    str = replaceAll(str, "? I'm ", "? You're ");
    str = replaceAll(str, "? i'm ", "? You're ");
    str = replaceAll(str, "? I was ", "? You were ");
    str = replaceAll(str, "? i was ", "? You were ");
    str = replaceAll(str, "? I ", "? You ");
    str = replaceAll(str, "? i ", "? You ");
    str = replaceAll(str, "? I wasn't", "? You weren't");
    str = replaceAll(str, "? i wasn't", "? You weren't");
    str = replaceAll(str, "I was ", "you were ");  //I -> you is decapitalized here as it will be fed back in the middle of a sentence
    str = replaceAll(str, "I was.", "you were.");
    str = replaceAll(str, " me ", " you ",);
    str = replaceAll(str, " Me ", " You ",);
    str = replaceAll(str, " my ", " your ");
    str = replaceAll(str, " My ", " Your ");
    str = replaceAll(str, "mine ", "yours ");
    str = replaceAll(str, "Mine ", "Yours ");
    str = replaceAll(str, "myself", "yourself");
    str = replaceAll(str, "Myself", "Yourself");
    str = replaceAll(str, " am ", " are ");
    str = replaceAll(str, "Am ", "Are ");
    str = replaceAll(str, "I'd ", "you'd ");
    str = replaceAll(str, " i'd ", " you'd ");
    str = replaceAll(str, "You'll ", "you'll ");
    str = replaceAll(str, "I'm ", "you're ");
    str = replaceAll(str, " i'm ", " you're ");
    str = replaceAll(str, "I'll ", "you'll ");
    str = replaceAll(str, " i'll ", " you'll ");
    str = replaceAll(str, " I've ", " you've ");
    str = replaceAll(str, " i've ", " you've ");
    str = replaceAll(str, " our ", " your ");
    str = replaceAll(str, "Our ", "Your ");
    str = replaceAll(str, " we ", " you ");
    str = replaceAll(str, " We ", " you ");
    str = replaceAll(str, " we'd ", " you'd ");
    str = replaceAll(str, " We'd ", " You'd ");
    str = replaceAll(str, " we'll ", " you'll ");
    str = replaceAll(str, " We'll ", " You'll ");
    str = replaceAll(str, " we're ", " you're ");
    str = replaceAll(str, " We're ", " You're ");
    str = replaceAll(str, " ours ", " yours ");
    str = replaceAll(str, " Ours ", " Yours ");
    str = replaceAll(str, " ourselves ", " yourselves ");
    str = replaceAll(str, "I wasn't", "you weren't");
    str = replaceAll(str, "Wasn't I ", "Weren't you ");
    str = replaceAll(str, " was I ", " were you ");
    str = replaceAll(str, " Was I ", " Were you ");
    str = replaceAll(str, " me,", " you,");
    str = replaceAll(str, "I ", "you ");  //TODO: This needs to be handled differently for when I is the first letter in the sentence and when its not
    str = replaceAll(str, " i ", " you ");

    console.log("output: " + str);

    return str;
  }

  //Conditionally decapitalize after replacing pronouns, if the pronoun replacement occurred.
  public replacePronounsAndDecapitalizeFirstLetter(str: string): string {
    var pronouns_replaced = this.replacePronouns(str);
    if (pronouns_replaced == str) {
      return str;
    }
    else {
       return this.decapitalizeFirstLetter(pronouns_replaced);
    }
  }


  public decapitalizeAndSwapPronouns(str: string): string {
    // console.log("input:" + str);
    str = this.replacePronounsAtBeginningOfInput(str);
    str = this.replacePronounsAtEndOfInput(str);

    function replaceAll(string: string, search: string, replace: string) {
      return string.split(search).join(replace);
    }

    str = replaceAll(str, " our ", " your ");
    str = replaceAll(str, " Our ", " Your ");
    str = replaceAll(str, " My ", " your ");
    str = replaceAll(str, " my ", " your ");
    str = replaceAll(str, " Me ", " you ");
    str = replaceAll(str, " me ", " you ");
    str = replaceAll(str, " we ", " you ");
    str = replaceAll(str, " We ", " you ");
    str = replaceAll(str, "My ", "your ");
    str = replaceAll(str, "mine ", "yours ");
    str = replaceAll(str, "Mine ", "yours ");
    str = replaceAll(str, "myself ", "yourself ");
    str = replaceAll(str, "Myself ", "yourself ");
    str = replaceAll(str, "I ", "you ");
    str = replaceAll(str, "I'd ", "you'd ");
    str = replaceAll(str, "I'll ", "you'll ");
    str = replaceAll(str, " am ", " are ");
    str = replaceAll(str, "Am ", "are ");
    str = replaceAll(str, "I'm ", "you're ");
    str = replaceAll(str, "I was ", "you were ");
    str = replaceAll(str, "I wasn't ", "you weren't ");
    str = replaceAll(str, "The ", "the ");
    str = replaceAll(str, "Someone ", "someone ");

    // console.log("input:" + str);

    return str;
  }

  public decapitalizePronouns(str: string): string {

    function replaceAll(string: string, search: string, replace: string) {
      return string.split(search).join(replace);
    }

    str = replaceAll(str, "My ", "my ");
    str = replaceAll(str, "The ", "the ");
    str = replaceAll(str, "Our ", "our ");
    str = replaceAll(str, "Us ", "us ");
    str = replaceAll(str, "Me ", "me ");
    str = replaceAll(str, "Myself", "myself");
    str = replaceAll(str, "Ourself", "ourself");
    str = replaceAll(str, "Anybody", "anybody");
    str = replaceAll(str, "Anyone", "anyone");
    str = replaceAll(str, "Everybody", "everybody");
    str = replaceAll(str, "Everyone", "everyone");
    str = replaceAll(str, "Most ", "most ");
    str = replaceAll(str, "Nobody", "nobody");
    str = replaceAll(str, "No one", "no one");
    str = replaceAll(str, "Other", "other");
    str = replaceAll(str, "Others", "others");
    str = replaceAll(str, "Somebody", "somebody");
    str = replaceAll(str, "Someone", "someone");

    return str;
  }

  //DIY transformations put in by Nassos initially in the ink file for D5. moved to angular code as a function
  public personSwap(str: string): string {

    if (str == "me") { str = "you" };
    if (str == "Me") { str = "you" };
    if (str == "Mum") { str = "your mother"};
    if (str == "mum") { str = "your mother" };
    if (str == "mummy") { str = "your mother" };
    if (str == "Mummy") { str = "your mother" };
    if (str == "Mom") { str = "your mother" };
    if (str == "mom") { str = "your mother" };
    if (str == "My Mum") { str = "your mother" };
    if (str == "My mum") { str = "your mother" };
    if (str == "my Mum") { str = "your mother" };
    if (str == "my mum") { str = "your mother" };
    if (str == "My mummy") { str = "your mother" };
    if (str == "My Mummy") { str = "your mother" };
    if (str == "My Mom") { str = "your mother" };
    if (str == "My mom") { str = "your mother" };
    if (str == "my Mom") { str = "your mother" };
    if (str == "my mom") { str = "your mother" };
    if (str == "My Mother") { str = "your mother" };
    if (str == "My mother") { str = "your mother" };
    if (str == "my Mother") { str = "your mother" };
    if (str == "my mother") { str = "your mother" };
    if (str == "Dad") { str = "your father" };
    if (str == "dad") { str = "your father" };
    if (str == "Daddy") { str = "your father" };
    if (str == "daddy") { str = "your father" };
    if (str == "My Dad") { str = "your father" };
    if (str == "My dad") { str = "your father" };
    if (str == "my Dad") { str = "your father" };
    if (str == "my dad") { str = "your father" };
    if (str == "My Daddy") { str = "your father" };
    if (str == "My daddy") { str = "your father" };
    if (str == "my Daddy") { str = "your father" };
    if (str == "my daddy") { str = "your father" };
    if (str == "My Father") { str = "your father" };
    if (str == "My father") { str = "your father" };
    if (str == "my Father") { str = "your father" };
    if (str == "my father") { str = "your father" };
    if (str == "My Brother") { str = "your brother" };
    if (str == "My brother") { str = "your brother" };
    if (str == "my Brother") { str = "your brother" };
    if (str == "my brother") { str = "your brother" };
    if (str == "My Sister") { str = "your sister" };
    if (str == "My sister") { str = "your sister" };
    if (str == "my Sister") { str = "your sister" };
    if (str == "my sister") { str = "your sister" };
    if (str == "my best friend") { str = "your best friend" };
    if (str == "My best friend") { str = "your best friend" };
    if (str == "my mate") { str = "your mate" };
    if (str == "My mate") { str = "your mate" };
    if (str == "My uncle") { str = "your uncle" };
    if (str == "my uncle") { str = "your uncle" };
    if (str == "My aunt") { str = "your aunt" };
    if (str == "my aunt") { str = "your aunt" };
    if (str == "My auntie") { str = "your auntie" };
    if (str == "my auntie") { str = "your auntie" };
    if (str == "My boss") { str = "your boss" };
    if (str == "my boss") { str = "your boss" };
    if (str == "My grandfather") { str = "your grandfather" };
    if (str == "my grandfather") { str = "your grandfather" };
    if (str == "My granpa") { str = "your grandfather" };
    if (str == "my granpa") { str = "your grandfather" };
    if (str == "My grandpa") { str = "your grandfather" };
    if (str == "my grandpa") { str = "your grandfather" };
    if (str == "My gramps") { str = "your grandfather" };
    if (str == "my gramps") { str = "your grandfather" };
    if (str == "My cousin") { str = "your cousin" };
    if (str == "my cousin") { str = "your cousin" };
    if (str == "My wife") { str = "your wife" };
    if (str == "my wife") { str = "your wife" };
    if (str == "My husband") { str = "your husband" };
    if (str == "my husband") { str = "your husband" };
    if (str == "My partner") { str = "your partner" };
    if (str == "my partner") { str = "your partner" };
    if (str == "My abuelo") { str = "your abuelo" };
    if (str == "my abuelo") { str = "your abuelo" };
    if (str == "My abuela") { str = "your abuela" };
    if (str == "my abuela") { str = "your abuela" };
    if (str == "My grandmother") { str = "your grandmother" };
    if (str == "my grandmother") { str = "your grandmother" };
    if (str == "My grannie") { str = "your grandmother" };
    if (str == "my grannie") { str = "your grandmother" };
    if (str == "My nana") { str = "your grandmother" };
    if (str == "my nana") { str = "your grandmother" };
    if (str == "My grandma") { str = "your grandmother" };
    if (str == "my grandma") { str = "your grandmother" };
    if (str == "My granma") { str = "your grandmother" };
    if (str == "my granma") { str = "your grandmother" };
    if (str == "My gran") { str = "your grandmother" };
    if (str == "my gran") { str = "your grandmother" };
    if (str == "My nan") { str = "your grandmother" };
    if (str == "my nan") { str = "your grandmother" };
    if (str == "My boyfriend") { str = "your boyfriend" };
    if (str == "my boyfriend") { str = "your boyfriend" };
    if (str == "My girlfriend") { str = "your girlfriend" };
    if (str == "my girlfriend") { str = "your girlfriend" };
    if (str == "My teacher") { str = "your teacher" };
    if (str == "my teacher") { str = "your teacher" };
    if (str == "My coach") { str = "your coach" };
    if (str == "my coach") { str = "your coach" };
    if (str == "My priest") { str = "your priest" };
    if (str == "my priest") { str = "your priest" };
    if (str == "My twin") { str = "your twin" };
    if (str == "my twin") { str = "your twin" };
    if (str == "My colleague") { str = "your colleague" };
    if (str == "my colleague") { str = "your colleague" };
    if (str == "My buddy") { str = "your buddy" };
    if (str == "my buddy") { str = "your buddy" };

    return str

  }

  public removeArticleAtBeginningOfInput(str: string): string {
    if (str.slice(0, 2) == "a ") { str = str.slice(2); }
    if (str.slice(0, 2) == "A ") { str = str.slice(2); }
    if (str.slice(0, 3) == "an ") { str = str.slice(3); }
    if (str.slice(0, 3) == "An ") { str = str.slice(3); }

    return str;
  }

}
