import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  Plugins,
} from '@capacitor/core';
import { NavController } from '@ionic/angular';
import { JournalService } from 'src/services/journal.service';
import { map, first } from 'rxjs/operators';
const { Storage } = Plugins;

import * as firebase from 'firebase/app'; 
import 'firebase/firestore';
import { StateManagementService } from 'src/services/state-management.service';
import { AnalyticsService } from 'src/services/analytics.service';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.css']
})

//Used for Journal Detail
export class JournalComponent implements OnInit {

  entries : any = [];
  placeholderText = "Type your answer...";
  showEntries = true;
  showLater = true;
  showButtons = false;
  navDream = 1;
  route$: Subscription;
  currentBubble: { Eyebrow: any; Headline: any };

  @ViewChild("inputArea", { read: ElementRef, static: true }) inputArea: ElementRef;


  constructor(private route: ActivatedRoute, 
    private httpClient: HttpClient, 
    public location: Location,
    private router: Router,
    public navCtrl: NavController,
    public journalService: JournalService,
    public stateManagement: StateManagementService,
    public analyticsService: AnalyticsService) { }

  ngOnInit(): void {
 
    Storage.get({ key: 'journal-onboarded' }).then(onboarded => {
      console.log(onboarded.value);
      if (onboarded.value == null) {
        this.navCtrl.navigateForward("journal-disc", { animated: false });
      }
    }).catch();

    this.route$ = this.route.params.subscribe(params => {
      this.navDream = params.id;
    });

    if (this.navDream == 0){
      this.placeholderText = "What's on your mind..."
    }

    this.httpClient.get("../../assets/ink/AllDreamData.json").subscribe((data: object) => {
      this.getCurrentJournal(data);
    });

    this.journalService.getEntriesPerDream(this.navDream).then(val =>{
      val.pipe(map((snaps) => {
        snaps.map((snap)=> {
          var dbentry = snap.payload.doc.data();
          var timestamp = dbentry['tm']?.toDate();

          var entry = {
            Dream : this.navDream,
            Date : timestamp,
            Text : dbentry['txt'],
            ShowMore : false
          }
      
          this.entries.push(entry);
        })
        
        this.entries.sort((a,b)=>b.Date.getTime()-a.Date.getTime());

        console.log(this.entries);
      }),   
      first()).subscribe();
    })

  }

  saveEntry(entry){
    var entryToDisplay = {
      Dream : this.navDream,
      Date : Date.now(),
      Text : entry,
      ShowMore : false
    }

    if (entry != ""){
      this.entries.unshift(entryToDisplay);
    }

    var entryDTO = {
      tm : firebase.firestore.FieldValue.serverTimestamp(),
      txt : entry,
    }
    this.journalService.saveEntry(this.navDream, entryDTO);
    this.analyticsService.logEvent("journal_entry_created" + this.navDream);

    this.toggleButtons("all", "hide");
    this.inputArea.nativeElement.value = "";
  }

  toggleButtons(button, action){
    if (button == "all") {
      if (action == "show"){
        this.showButtons = true;
        this.showLater = false;

        this.showEntries = false;
      }
      else if (action == "hide"){
        this.showButtons = false;

        this.showEntries = true;
      }
    }

    else if (button == "later"){
      if (action == "show"){
        this.showLater = true;
      }
      else if (action == "hide"){
        this.showLater = false;
      }
    }
    
    this.inputArea.nativeElement.value = "";
  }

  getCurrentJournal(data) {
    if (this.navDream == 0) {
      this.currentBubble = {
        "Eyebrow": "Welcome to the journal",
        "Headline": "Your Free Thoughts"
      }
    }
    else {
      data[this.navDream].forEach(
        val => {
          if (val.IsJournal) {
            this.currentBubble = {
              "Eyebrow": val.Eyebrow,
              "Headline": val.Headline
            }
          }
        });
    }
  }

  calculateClasses() {
    return "d" + this.navDream;
  }

  back(text){
    if (this.guardRedirect(text))
    {
      if(!this.stateManagement.isAppOpenedFromNotificaton){
        this.navCtrl.navigateForward(["navmenu/navjournal"], { animated: false });
      }
      else{
        this.stateManagement.isAppOpenedFromNotificaton = false;
        this.router.navigate(["navmenu/navjournal"]);
      }
    }
  }

  trimString(string, length) {
    return string.length > length
      ? string.substring(0, length) + "..."
      : string;
  }

  redirectoToInfo(text){
    this.router.navigate(["/journal-info/" + this.navDream])
  }

  guardRedirect(text){
    if (text == "")
    {
      return true;
    }
    if (confirm("Are you sure you want to exit the screen without saving your entry?")){
      return true;
    }
    return false;
  }
}