import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { NavController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { Plugins } from '@capacitor/core'

const { Storage } = Plugins;

@Component({
  selector: 'link-nft',
  templateUrl: './link-nft.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class LinkNftComponent implements OnInit {

  constructor(private _location: Location,
    private navCtrl: NavController,
    public afs: AngularFirestore) { }

  ngOnInit(): void {
  }

  continue() {
    Storage.get({ key: 'userid' }).then(uid => {
      this.afs.collection<any>("users").doc(uid.value).get()
        .pipe(first())
        .subscribe(r => {
          if (r.exists && r.data()["NFTCollection"] && r.data()["NFTCollection"] == 'Elder') {
            this.navCtrl.navigateForward(["/"], { animation: fadeAnimation });
          } else {
            alert("NFT is not linked. Please try again. If the issue persists, please contact us")
          }
        });
    });
  }

}
