import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }
  
  openImage(cardId: number) {
    this.router.navigate(["/card-detail", cardId]);
  }
}