import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { LinksConsts } from 'src/components/shared-components/links/links.component';
import { AudioService } from 'src/services/audio.service';
import { IonContent } from '@ionic/angular';

import { Plugins } from '@capacitor/core'
import { OperationsHelper } from 'src/helpers/ops-helper';
import { HttpClient } from '@angular/common/http';

import { StateManagementService } from 'src/services/state-management.service';
import StoryHelper from 'src/helpers/story-helper';
import { Router, Event, NavigationEnd } from '@angular/router';

const { Share, Storage } = Plugins;

@Component({
  selector: 'app-navhome',
  templateUrl: './navhome.component.html',
  styleUrls: ['./navhome.component.css']
})
export class NavhomeComponent implements OnInit {

  cardDisplayProps: any = {};
  bubbleDisplayProps: any = {};
  dreamProps: any = {};

  @ViewChild('page') page: IonContent;

  dreamsCarousel: any = [];

  lastCompletedDream;
  lastCompletedDreamRoman;
  nextDreamRoman;

  subsription;

  linkProps: any = {};

  cardsDataObject: any = {};

  completedDreamCache;
  completedQuestCache;

  constructor(private audioService: AudioService,
    private httpClient: HttpClient,
    private stateManagementService: StateManagementService,
    private router: Router,
    private ref: ChangeDetectorRef) { }

    ngOnInit () : void {}

  // ngOnInit(): void {
  //   this.httpClient.get("../../assets/ink/quests.json").subscribe((data: object) => {
  //     this.cardsDataObject = data;
  //     this.populateCards(this.cardsDataObject);
  //   });

  //   this.linkProps = [
  //     {
  //       Headline: "Rate us",
  //       RouterValue: LinksConsts.RATE,
  //     },
  //     {
  //       Headline: "Send us feedback",
  //       RouterValue: LinksConsts.EMAIL_FEEDBACK,
  //     }
  //   ];



  //   if (this.subsription == null) {
  //     this.subsription = this.router.events.subscribe((event: Event) => {
  //       if (event instanceof NavigationEnd) {
  //         this.page.scrollToTop();
  //       }
  //     });
  //   }

  //   this.httpClient.get("../../assets/ink/AllDreamData.json").subscribe((data: object) => {
  //     this.cardsDataObject = data;

  //     if (this.completedDreamCache != lastCompletedDream || this.completedQuestCache != lastCompletedQuest) {
  //       this.dreamsCarousel = [];
  //       if (this.cardsDataObject != null) {
  //         this.populateCards(this.cardsDataObject);
  //       }
  //       this.completedDreamCache = lastCompletedDream;
  //       this.completedQuestCache = lastCompletedQuest;
  //     }

  //   this.populateCards(this.cardsDataObject);
  // }
  // }

  // populateCards(data) {

  //   this.lastCompletedDream = this.stateManagementService.getLastCompletedDream();
  //   this.lastCompletedDreamRoman = StoryHelper.romanize(this.lastCompletedDream);

  //   var lastDreamData;
  //   var lockQuests = false;

  //   if (this.lastCompletedDream == 0) {
  //     lastDreamData = data[1];
  //     this.lastCompletedDreamRoman = StoryHelper.romanize(1);

  //     lockQuests = true;
  //   }
  //   else {

  //     lastDreamData = data[this.lastCompletedDream];
  //   }

  //   if (lastDreamData) {
  //     for (var i = 1; i < 12; i++) {
  //       if (data[i]) {
  //         var val = data[i][0];
  //         if (val.IsDream) {
  //           this.dreamsCarousel.push({
  //             "SubHeadline": val.SubHeadline,
  //             "Eyebrow": val.Eyebrow,
  //             "Headline": val.Headline,
  //             "Duration": val.Duration,
  //             "Background": val.Background,
  //             "RouterValue": val.RouterValue,
  //             "NeedsSound": val.NeedsSound,
  //             "IsDream": val.IsDream,
  //             "Id": val.Id,
  //             "Locked": i > this.lastCompletedDream + 1
  //           })
  //         }
  //       }
  //       else{
  //         break;
  //       }
  //     }
  //   }


  //   this.ref.detectChanges();
  // }


  async shareBetwixt() {
    var uri = OperationsHelper.APP_STORE_LINK;
    var text = OperationsHelper.SHARE_TEXT;
    var title = OperationsHelper.SHARE_TITLE

    await Share.share({
      title: title,
      text: text,
      url: uri
    });
  }
}
