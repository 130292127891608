import { Component, OnInit } from '@angular/core';
import { OperationsHelper } from 'src/helpers/ops-helper';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.component.html',
  styleUrls: ['../disclaimer/disclaimer.component.css']
})
export class DownloadPWAComponent implements OnInit {
  public isMobileOrTablet = OperationsHelper.isMobileOrTablet;
  public isPWAinstalled = OperationsHelper.isMobileOrTablet;

  public downloadText = "";
  public isChromeiOS: boolean = false;
  public isChrome: boolean = false;
  public isSafari: boolean = false;
  public isDesktop: boolean = false;

  ngOnInit(): void {
    this.getDownloadPWAInstuctions();
  }
  ngAfterViewInit(): void {
    this.getDownloadPWAInstuctions();
  }

  getDownloadPWAInstuctions() {
    var browser = OperationsHelper.getBrowser();
    this.isDesktop = !OperationsHelper.isMobileOrTablet();

    if (this.isDesktop) {
      return;
    }

    if (browser == "ChromeiOS") {
      this.isChromeiOS = true;
      this.isChrome = false;
      this.isSafari = false;
    } else if (browser == "Chrome") {
      this.isChromeiOS = false;
      this.isChrome = true;
      this.isSafari = false;
    } else if (browser == "Safari") {
      this.isChromeiOS = false;
      this.isChrome = false;
      this.isSafari = true;
    }
    return;
  }

  redirectUpdate() {
    var helpUrl = OperationsHelper.APP_STORE_LINK;
    window.open(helpUrl, "_blank");
  }
}