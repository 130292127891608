<ion-app>
   <ion-header class="ion-no-border">
   </ion-header>
   <ion-content *ngIf='!loading' scrollY="false">
      <flip-card [frontCardBackground]="frontCardBackground" [backCardBackground]="backCardBackground"
         [edgeCardBackground]="'background: white'" [ctaTitle]="'Continue'" [ctaCallback]="myCallbackFunction"
         [hideButtonOnClick]="true">

         <div back-card>
            <div class="cmn-wrapper">
               <div class="cmn-mid-text cmn-serif-title">
                  An epic, magical journey of self-discovery
               </div>
            </div>
         </div>
      </flip-card>
   </ion-content>
</ion-app>