import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  theme = 1;

  constructor() {
    this.theme = this.getPreferredTheme()
  }

  public getPreferredTheme(): number {
    return localStorage.getItem("theme") ? parseInt(localStorage.getItem("theme")) : 1;
  }

  public loadPreferredTheme() {
    this.changeThemeById(this.getPreferredTheme());
  }

  public savePreferredTheme() {
    localStorage.setItem("theme", this.theme.toString());
  }

  public changeTheme() {
    if (this.theme == 3) {
      this.theme = 1;
    }
    else {
      this.theme++;
    }

    this.savePreferredTheme();
    this.changeThemeById(this.theme);
  }

  public changeThemeById(themeId: number) {
    var docStyle = document.documentElement.style;
    switch (themeId) {
      case 1:
        docStyle.setProperty("--ff-background", "#DEE9F4");
        docStyle.setProperty("--ff-primary", "#73B7E0");
        // default
        docStyle.setProperty("--ff-story-text", "#36454f");
        docStyle.setProperty("--ff-background-secondary", "#FFFFFF");
        docStyle.setProperty("--ff-text-narrator", "#36454f");
        docStyle.setProperty("--box-shadow-top", "0 1px 2px 0 rgba(0, 0, 0, .10)");
        docStyle.setProperty("--box-shadow-bottom", "0 -1px 2px 0 rgba(0, 0, 0, .10)");
        break;
      // dark
      case 2:
        docStyle.setProperty("--ff-background", "#222");
        docStyle.setProperty("--ff-primary", "#028FA3");
        docStyle.setProperty("--ff-background-secondary", "#626262");
        docStyle.setProperty("--ff-story-text", "#F6F7F9");
        docStyle.setProperty("--ff-text-narrator", "#F6F7F9");
        docStyle.setProperty("--box-shadow-top", "0 1px 2px 0 rgba(255, 255, 255, .10)");
        docStyle.setProperty("--box-shadow-bottom", "0 -1px 2px 0 rgba(255, 255, 255, .10)");
        break;
      // new
      case 3:
        docStyle.setProperty("--ff-background", "#263662");
        docStyle.setProperty("--ff-primary", "#C57BDD");
        docStyle.setProperty("--ff-background-secondary", "#848BE5");
        docStyle.setProperty("--ff-story-text", "#FFF");
        docStyle.setProperty("--ff-text-narrator", "#FFF");
        docStyle.setProperty("--box-shadow-top", "0 1px 2px 0 rgba(255, 255, 255, .10)");
        docStyle.setProperty("--box-shadow-bottom", "0 -1px 2px 0 rgba(255, 255, 255, .10)");
        break;
      // missions theme
      case 4:
        docStyle.setProperty("--ff-background", "white");
        docStyle.setProperty("--ff-primary", "#73B7E0");
        docStyle.setProperty("--ff-background-secondary", "white");
        docStyle.setProperty("--ff-story-text", "#FFF");
        docStyle.setProperty("--ff-text-narrator", "#FFF");
        docStyle.setProperty("--box-shadow-top", "0 1px 2px 0 rgba(0, 0, 0, .10)");
        docStyle.setProperty("--box-shadow-bottom", "0 -1px 2px 0 rgba(0, 0, 0, .10)");
      default:
        break;
    }

  }
}
