import { Component, OnInit } from '@angular/core';
import { Howl, Howler } from 'howler';
import { AudioService } from 'src/services/audio.service';

@Component({
  selector: 'app-background-audio',
  templateUrl: './background-audio.component.html',
  styleUrls: ['./background-audio.component.css']
})
export class BackgroundAudioComponent implements OnInit {
  constructor(public audioService : AudioService) { }

  ngOnInit(): void {
    console.log("Service is " + this.audioService.playing);
  }

  ngOnDestroy(){
    this.audioService.stopBackgroundSounds();
  }
}
