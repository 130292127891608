<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="false">
      <div class="cmn-wrapper">
        <div class="cmn-font-helvetica ion-text-uppercase">
          <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
          </ion-icon>
      </div>
        <div class="cmn-serif-title ion-text-center margin-top">
            Betwixt on <br>Discord
        </div>
        <div class="disclaimer-text ion-text-center">
            <br>The In-Between has more to offer on Discord. <br>
            Join the server for exclusive content, new features, weekly missions and more. <br>
            Talk to the dev team, help us nurture the community and have your say in the evolution of Betwixt. 
        </div>
        <br>
        <div class="cst-footer ion-text-center cmn-bottom-text">
          <div (click)="openDiscord()" class="cmn-button ion-text-center">
           Join our Discord
          </div>
        </div>
      </div>
    </ion-content>
  </ion-app>