import { Injectable } from '@angular/core';
import { Plugins } from "@capacitor/core";
const { FirebaseAnalytics } = Plugins;
import { environment } from '../environments/environment';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  logEvent(eventName: string) {
    if (environment.testMode != true) {
      firebase.analytics().logEvent(eventName);
    }

  }

  logEventWithParams(eventName: string, paramName: string) {
    if (environment.testMode != true) {
      firebase.analytics().logEvent(eventName, { 'method': paramName });
    }
  }
}
