<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false">
    <div class="cmn-wrapper">
      <div class="cmn-serif-title ion-text-center big-margin-top">
        Re-connect your NFT
      </div>
      <br>
      <div class="disclaimer-text ion-text-center">
        We’re finally nearing the reveal now, <br>and we thank you for bearing with us these last weeks.
        <br>
        <br>
        To ensure your account is all set, <br>please re-confirm your NFT by following the link below:
        <br>
        <br>
        <a class="white-under" href="https://nft.betwixt.life/nft-connect"
          target="_blank">https://nft.betwixt.life/nft-connect</a>
        <br>
        <br>

        <div>In case you forgot your password, click on the link below to reset it:</div>
        <br>
        <div class="cmn-font-oscar ion-text-center white-under" [routerLink]="'/forgotten-pass'">Reset password</div>
      </div>
      <div class="cst-footer ion-text-center cmn-bottom-text">
        <div (click)="continue()" class="cmn-button ion-text-center">
          Continue
        </div>
      </div>
    </div>
  </ion-content>
</ion-app>