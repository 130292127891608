<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>

    <ion-content *ngIf="contentVisible" scrollY="false">
        <flip-card [frontCardBackground]="backCardBackground" [backCardBackground]="frontCardBackground"
            [edgeCardBackground]="'background: white'" [ctaTitle]="getButtonText()" [ctaCallback]="myCallbackFunction"
            isButtonOnFrontSide="false" [hideButtonOnClick]="true">

            <div front-card class="cmn-wrapper ion-text-center hidden"
                [class.visible]="!transitionsService.shouldHideContent">
                <div class="ion-text-uppercase cmn-font-helvetica dream-number cmn-enabled">
                    {{dreamText?.eyebrow}}
                </div>
                <br>
                <div class="cmn-serif-title"> Welcome back! </div>
                <br>
                <div class="ion-text-uppercase cmn-font-helvetica cmn-enabled">
                    {{dreamText?.subtitle}}
                </div>
                <div class="instruction-labels text-center">

                </div><br>

                <div class="cmn-font-jotia ion-text-center">
                    {{dynamicMessage}}
                    <br>
                </div>

            </div>

            <div back-card>
            </div>

        </flip-card>
    </ion-content>
</ion-app>