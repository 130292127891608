import { Component, OnInit, Input, SimpleChange, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { UserInteraction } from 'src/interfaces/user-interaction.interface';
import { StoryService } from 'src/services/story.service';
import { Choice } from 'inkjs';
import { UserInteractionType } from 'src/enums/choice-type.enum';

@Component({
  selector: 'app-user-action',
  templateUrl: './user-action.component.html',
  styleUrls: ['./user-action.component.css']
})
export class UserActionComponent implements OnInit, OnChanges {
  @Input() public userInteraction: UserInteraction;

  private contentPlaceholder: ElementRef;

  @ViewChild('input', { static: false }) set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
        this.contentPlaceholder = content;
    }
 }
  public visible = false;
  public inputValue: string;

  constructor(private storyService: StoryService) {}

  public ngOnInit() {
    this.visible = true;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.userInteraction instanceof SimpleChange) {
      this.inputValue = '';
      if (changes.userInteraction.currentValue && changes.userInteraction.currentValue.type === UserInteractionType.TEXT) {
       this.visible = true;
      }
    }
  }

  public trigger(value: Choice | string) {
    this.storyService.triggerUserInteraction(value);
  }
}
