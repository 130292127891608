<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="true">
        <div class="cmn-wrapper">
            <div class="cmn-font-helvetica ion-text-uppercase">
                <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
                </ion-icon>
            </div>

            <div class="cmn-serif-title ion-text-center">
                Emergency Mental Health Resources
            </div>
            <div class="disclaimer-text">
                <p class="ion-text-center">If you need help for a mental health crisis, emergency or breakdown, you
                    should get immediate expert advice. There are a number of ways to speak to a mental health
                    professional when you need support. </p>
                    <br> 
                <h3>If you are in the UK:</h3>
                <p><strong>999</strong></p>
                <p>If your life is at imminent risk, <strong>call 999</strong> for emergency help</p>
                <p><strong> Shout - Anonymous text support</strong></p>
                <p>If you are experiencing a personal crisis, are unable to cope and need support,<strong> text "Shout"
                        to 85258</strong></p>
                <p><strong>NHS urgent mental health helplines</strong></p>
                <p>NHS urgent mental health helplines are for people of all ages. </p>
                <p>You can call for:<br /> </p>
                <ul>
                    <li>
                        <p>24-hour advice and support - for you, your child, your parent or someone you care for</p>
                    </li>
                    <li>
                        <p>help to speak to a mental health professional</p>
                    </li>
                    <li>
                        <p>an assessment to help decide on the best course of care</p>
                    </li>
                </ul>
                <p><strong><a
                            href="https://www.nhs.uk/service-search/mental-health/find-an-urgent-mental-health-helpline" target="_blank">Click
                            here to find a local NHS urgent health helpline (England only)</a></strong></p>
                <p><strong>Free listening services</strong></p>
                <p>If you just need to talk, any time of the day or night, these services offer confidential advice from
                    trained volunteers. You can talk about anything that's troubling you, no matter how difficult:</p>
                <p> </p>
                <ul>
                    <li>
                        <p><strong>Call <a href="tel:116123">116 123 </a> </strong>to talk to <a
                                href="https://www.samaritans.org/how-we-can-help/contact-samaritan/" target="_blank">Samaritans</a>, or
                            email: <a href="mailto:jo@samaritans.org">jo@samaritans.org</a> for a reply within 24 hours.
                            </p>
                    </li>
                    <li>
                        <p>If you are under 19, you can also <strong>call </strong> <a href="tel:0800 1111">0800 1111</a>
                            to talk to <a href="https://www.childline.org.uk" target="_blank">Childline</a>. The number will not appear
                            on your phone bill.</p>
                    </li>
                </ul>
                <p><strong>Anxiety UK</strong></p>
                <p>Anxiety UK is a UK national registered charity formed in 1970 for those affected by anxiety
                    disorders. It is a user-led organisation, run by sufferers and ex-sufferers of anxiety disorders.
                    You can call, email, live chat or text with a representative today (opening hours: Monday to Friday,
                    9:30 am to 5:30 pm):</p>
                <ul>
                    <li>
                        <p>Anxiety UK helpline: call 03444 775 774</p>
                    </li>
                    <li>
                        <p>Live chat at <a href="http://www.anxietyuk.org.uk/" target="_blank">www.anxietyuk.org.uk</a></p>
                    </li>
                    <li>
                        <p>Email (not instant response) <a
                                href="mailto:support@anxietyuk.org.uk" target="_blank">support@anxietyuk.org.uk</a></p>
                    </li>
                    <li>
                        <p>Text (not instant response) 07537 416 905<br /> </p>
                    </li>
                </ul>
                <br>  

                <h3>If you are in the US or Canada: </h3>

                <p><strong>911</strong></p>
                <p>If your life is at imminent risk, <strong>call 911</strong> for emergency help</p>

                <p><strong>Crisis Text Line</strong></p>
                <p>If you are experiencing a personal crisis, are unable to cope and need support, <strong>text "HOME"
                        to <a href="tel:741741">741741</a></strong></p>

                <p><strong>Samaritans</strong> </p>
                <p>If you are in crisis or suicidal and need someone to talk to, call the Samaritans branch in your area
                    or:</p>
                <p><strong>1 (800) 273-TALK</strong></p>
                <br>
                    <br>  

                <h3>International emergency websites and contact numbers:</h3>

                <p><strong>Checkpoint</strong></p>
                <p>For a comprehensive list of emergency numbers and websites worldwide, visit <strong><a
                            href="https://checkpointorg.com/global/" target="_blank">Checkpoint</a> </strong></p>
            </div>
            <br><br>
            <div #continue (click)="back()" class="ion-text-center ion-text-uppercase cmn-font-helvetica cmn-enabled">
                Continue</div>
            <br><br>
        </div>
    </ion-content>
</ion-app>