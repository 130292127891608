<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false">
    <div class="cmn-wrapper">
      <div class="cmn-serif-title ion-text-center big-margin-top">
        Get the most of your experience
      </div>
      <div class="disclaimer-text ion-text-center">
        <div *ngIf="isDesktop">
          It looks like you are trying to access Betwixt from your desktop device.<br>
          Betwixt has been optimized to work as a mobile app. <br>
          To install the app on your phone, please access it
          from a mobile device and follow the instructions displayed there.
        </div>
        <div *ngIf="!isDesktop">
          <div>
            Betwixt has been optimized to work as a mobile app.

            To get the most of your experience, download the app
            following the instructions below:<br><br>
          </div>
          <div *ngIf="isChromeiOS">
            It looks like you are using Chrome on iOS. To install the app, please access it from Safari and follow the
            instructions displayed there.
          </div>
          <div *ngIf="isChrome">
            Click the browser settings (⋮ in the top right corner).<br>Tap 'Add to Home screen' or 'Install
            app'.<br>Confirm by tapping 'Install'. <br><br>The Betwixt app will show up on your home screen like the
            rest
            of the apps on your phone.
          </div>
          <div *ngIf="isSafari">
            Tap the <ion-icon name="share-outline"></ion-icon> button. <br> Scroll down and tap 'Add to Home Screen'.
            <br><br>The Betwixt app will show
            up on your home screen like the rest of the apps on your phone
          </div>
        </div>
      </div>

    </div>
  </ion-content>
</ion-app>