<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="false"  >
        <div class="card-outline" [ngClass]="calculateClasses()">
            <div class="cmn-wrapper">
               
                <div class="text-center cmn-card background-gradint-card">
                    <div class="cmn-card-wrapper">
                        <br>

                        <div class="cmn-serif-title ion-text-center">Dream {{nextDreamRoman}}</div>
                        <br>

                        <div class="cmn-serif-title ion-text-center">The In-between</div>

                        <br>

                        <div class="cmn-font-jotia ion-text-center">
                           Sound disclaimer
                        </div>
                        <br>
                        <div class="cmn-font-jotia ion-text-center">
                           10 mins
                         </div>
                    </div>
                </div>

            </div>

            <div class="cmn-bottom-text ion-text-center">
                <div (click)="forward('game')" class="cmn-button ion-text-center">
                   Enter the In-between
                </div>
            </div>
        </div>
    </ion-content>
</ion-app>
