import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { Plugins } from '@capacitor/core';
import { NavController } from '@ionic/angular';
const { Storage } = Plugins;
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public auth: AuthService,
        public router: Router,
        private navCtrl: NavController,
    ) { }
    async canActivate(): Promise<boolean> {
        var result = await Storage.get({ key: 'userid' });
        if (result.value == null) {
            this.navCtrl.navigateForward("login", { animated: false });
            return false;
        }
        return true;
    }
}