import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.css']
})
export class BubbleComponent implements OnInit {

  @Input() item: any;

  bubbles: any = [];
  
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.bubbles = this.item;
  }

  redirect(bubble : any){
    if (!bubble.Locked){
      this.router.navigateByUrl(bubble.RouterValue, { replaceUrl: true });
    }
  }

  ngOnChanges(){
    this.bubbles = this.item;
  }
}
