import { Component, OnInit } from '@angular/core';
import { FlipCardHelper } from 'src/helpers/flip-card-helper';
import { StateManagementService } from 'src/services/state-management.service';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import StoryHelper from 'src/helpers/story-helper';
import { expandAnimationDream, fadeAnimation } from 'src/helpers/transitions-helper';
import { TransitionsPropertiesService } from 'src/services/rounded-edges-service';
import { DreamScheduleService } from 'src/services/dreamschedule.service';
import { map, first } from 'rxjs/operators';

@Component({
  selector: 'app-predream',
  templateUrl: './predream.component.html',
  styleUrls: ['./predream.component.css'],
})
export class PredreamComponent implements OnInit {
  frontCardBackground: String;
  backCardBackground: String;
  lastCompletedDream: number;
  nextDream: number;
  dreamText: { title: string, subtitle: string, eyebrow: string, duration: string, showDuration: boolean };

  public styleText;
  contentVisible = false;

  constructor(
    private stateManagementService: StateManagementService,
    private navCtrl: NavController,
    private httpClient: HttpClient,
    public transitionsService: TransitionsPropertiesService,
    public dreamScheduleService: DreamScheduleService
  ) { }

  ngOnInit(): void {
    this.transitionsService.shouldHideContent = true;

    this.lastCompletedDream = this.stateManagementService.getLastCompletedDream()
    this.nextDream = this.lastCompletedDream + 1
    this.getStyle();

    this.httpClient.get("../../assets/ink/dreamInfo.json").subscribe((data: object) => {
      this.dreamText = this.getDreamText(data);
    });

    setTimeout(() => {
      this.transitionsService.shouldHideContent = false;
    }, 100);

  }

  ionViewDidEnter() {
    this.transitionsService.shouldHideContent = true;

    this.lastCompletedDream = this.stateManagementService.getLastCompletedDream()
    this.nextDream = this.lastCompletedDream + 1;
    if (this.nextDream == 12) {
      this.nextDream = 11;
    }
    this.getStyle();

    this.httpClient.get("../../assets/ink/dreamInfo.json").subscribe((data: object) => {
      this.dreamText = this.getDreamText(data);
    });

    setTimeout(() => {
      this.transitionsService.shouldHideContent = false;
    }, 100);

    this.getReleaseDate();
  }

  playPreviousDream() {
    this.navCtrl.navigateForward("game/" + this.lastCompletedDream, { animation: fadeAnimation });
    localStorage.setItem('lastCmplDream', (+this.lastCompletedDream - 1).toString());
  }

  hideIfFirstDream() {
    if (this.lastCompletedDream == 0) {
      return true;
    }
    return false;
  }
  getReleaseDate() {
    this.dreamScheduleService
      .getDreamReleaseDate(this.nextDream)
      .then((val) => {
        val
          .pipe(first(),
            map((snaps) => {
              var data = snaps.payload.data()
              if (data['release']) {
                let release = data['release'];

                let today = new Date()
                if (release.toDate() > today) {
                  //locked = true
                  this.navCtrl.navigateForward("waiting", { animated: false });
                } else {
                  this.contentVisible = true;
                }
              }
            })
          )
          .subscribe()
      })
  }


  getStyle() {
    var styles = FlipCardHelper.getStyles(this.nextDream);
    this.frontCardBackground = styles.frontCardStyle;
    this.backCardBackground = styles.backCardStyle;
  }

  getButtonText() {
    return "Enter the In-Between"
  }

  myCallbackFunction = (args: any): void => {
    this.navCtrl.navigateForward("game/" + this.nextDream, { animation: expandAnimationDream });

  }

  getDreamText(data) {
    var text = { title: '', subtitle: '', eyebrow: '', duration: '', showDuration: false }

    text.title = data[this.nextDream].Headline;
    text.subtitle = data[this.nextDream].SubHeadline;
    text.duration = data[this.nextDream].Duration;
    text.showDuration = data[this.nextDream].ShowDuration;

    text.eyebrow = "Dream " + StoryHelper.romanize(this.nextDream)

    return text;
  }
}