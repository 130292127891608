import { Injectable } from '@angular/core';
import { Plugins } from "@capacitor/core";
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StateManagementService } from './state-management.service';
import { AngularFirestore } from '@angular/fire/firestore';
const { Storage } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class ArtGenService {

  userId;
  NFTID;
  walletAddress;

  constructor(
    private httpClient: HttpClient,
    private stateManagementService: StateManagementService,
    public afs: AngularFirestore) { }

  generate(dream: number) {
    this.ensureUserNFTData();

    Storage.get({ key: 'userid' }).then(res => {
      if (res.value != null) {
        this.userId = res.value
      }
    }).catch();
    Storage.get({ key: 'NFTID' }).then(res => {
      if (res.value != null) {
        this.NFTID = res.value
      }
    }).catch();
    Storage.get({ key: 'walletAddress' }).then(res => {
      if (res.value != null) {
        this.walletAddress = res.value
      }
    }).catch();

    // let user: IUser = {
    //   userID: "bJmZXQq5KsVjFggbWqKBbS6jgy22",
    //   NFTID: 289,
    //   walletAddress: "0x8c8DbB6bDFE88aeCAD47e0a813FC46bd49aAb7E0"
    // };
    let user: IUser = {
      userID: this.userId,
      NFTID: this.NFTID,
      walletAddress: this.walletAddress
    }

    let storyState = this.stateManagementService.getStoryFromLocalStorage(dream);

    if (dream == 1) {
      let playerBetterPlace = '';
      let gameOver = ''
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        gameOver = variableState['gameOverD1'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 1,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "gameOver": gameOver
        }
      }).subscribe(res => {

      });
    }
    if (dream == 2) {
      let playerBetterPlace = '';
      let gameOver = '';
      let hasLoveD2 = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        gameOver = variableState['gameOverD2'];
        hasLoveD2 = variableState['hasLoveD2'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 2,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "gameOver": gameOver,
          "hasLoveD2": hasLoveD2
        }
      }).subscribe(res => {

      });

    }
    if (dream == 3) {
      let playerBetterPlace = '';
      let driver = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        driver = variableState['driver'];
        d1Whiteout = variableState['d1Whiteout'];
        d2Whiteout = variableState['d2Whiteout'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 3,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "driver": driver,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout
        }
      }).subscribe(res => {

      });
    }
    if (dream == 4) {
      let playerBetterPlace = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      let d4AltRoute = '';
      let d4Emotion = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        d4AltRoute = variableState['d4AltRoute'];
        d4Emotion = variableState['d4Emotion'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 4,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout,
          'd4AltRoute': d4AltRoute,
          "d4Emotion": d4Emotion
        }
      }).subscribe(res => {

      });

    }
    if (dream == 5) {
      let playerBetterPlace = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      let d5EyesOpen = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        d1Whiteout = variableState['d1Whiteout'];
        d2Whiteout = variableState['d2Whiteout'];
        d5EyesOpen = variableState['d5EyesOpen'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 5,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout,
          "d5EyesOpen": d5EyesOpen
        }
      }).subscribe(res => {

      });
    }
    if (dream == 6) {
      let playerBetterPlace = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      let faceMonsterChoice = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        d1Whiteout = variableState['d1Whiteout'];
        d2Whiteout = variableState['d2Whiteout'];
        faceMonsterChoice = variableState['faceMonsterChoice'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 6,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout,
          "faceMonsterChoice": faceMonsterChoice
        }
      }).subscribe(res => {

      });

    }
    if (dream == 7) {
      let playerBetterPlace = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      let faceMonsterChoice = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        d1Whiteout = variableState['d1Whiteout'];
        d2Whiteout = variableState['d2Whiteout'];
        faceMonsterChoice = variableState['faceMonsterChoice'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 7,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout,
          "faceMonsterChoice": faceMonsterChoice
        }
      }).subscribe(res => {

      });

    }
    if (dream == 8) {
      let playerBetterPlace = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      let faceMonsterChoice = '';
      let d8AltRoute = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        d1Whiteout = variableState['d1Whiteout'];
        d2Whiteout = variableState['d2Whiteout'];
        faceMonsterChoice = variableState['faceMonsterChoice'];
        d8AltRoute = variableState['d8AltRoute'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 6,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout,
          "faceMonsterChoice": faceMonsterChoice,
          "d8AltRoute": d8AltRoute
        }
      }).subscribe(res => {

      });

    }
    if (dream == 9) {
      let playerBetterPlace = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      let d9MonsterComfort = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        d1Whiteout = variableState['d1Whiteout'];
        d2Whiteout = variableState['d2Whiteout'];
        d9MonsterComfort = variableState['d9MonsterComfort'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 9,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout,
          "d9MonsterComfort": d9MonsterComfort
        }
      }).subscribe(res => {

      });

    }
    if (dream == 10) {
      let playerBetterPlace = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      let d10Colour = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        d1Whiteout = variableState['d1Whiteout'];
        d2Whiteout = variableState['d2Whiteout'];
        d10Colour = variableState['d10Colour'];
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 6,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout,
          "d10Colour": d10Colour
        }
      }).subscribe(res => {

      });

    }
    if (dream == 11) {
      let playerBetterPlace = '';
      let d1Whiteout = '';
      let d2Whiteout = '';
      let d10Colour = '';
      let d11Choice = '';
      if (storyState && storyState['variablesState']) {
        let variableState = storyState['variablesState'];
        playerBetterPlace = variableState['playerBetterPlace'];
        d1Whiteout = variableState['d1Whiteout'];
        d2Whiteout = variableState['d2Whiteout'];
        d10Colour = variableState['faceMonsterChoice'];
        d11Choice = variableState['d11Choice']
      }

      this.httpClient.post("https://betwixt-dev.ew.r.appspot.com", {
        user: user,
        "dreamData": {
          "dream": 11,
          "TINT": 1,
          "playerBetterPlace": playerBetterPlace,
          "d1Whiteout": d1Whiteout,
          "d2Whiteout": d2Whiteout,
          "d10Colour": d10Colour,
          "d11Choice": d11Choice
        }
      }).subscribe(res => {

      });
    }
  }

  private ensureUserNFTData() {
    Storage.get({ key: 'userid' }).then(res => {
      if (res.value != null) {
        this.afs.collection('users').doc(res.value).get().subscribe(dbuser => {
          Storage.set({ key: 'NFTID', value: dbuser['NFTID'] });
          Storage.set({ key: 'walletAddress', value: dbuser['walletAddress'] });
        });
      }
    }).catch();
  }
}

export interface IUser {
  userID: string,
  NFTID: number,
  walletAddress: string;
}
