<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="false">
            <div class="cmn-wrapper">
                <div class="ion-text-center ion-text-uppercase cmn-font-helvetica">Welcome to the in-between</div>
                <div class="ion-text-center cmn-serif-title scrn-title cmn-text-shadow"> What are you here for? </div>
                <ion-grid class="">
                    <ion-row class="ion-align-items-end">
                        <ion-col #story class="box ion-text-bottom" (click)="select('story')">
                            <div class="cmn-rectangle"></div>
                            <span class="box-text cmn-font-jotia">
                                I'm searching for a better story.
                            </span>
                        </ion-col>
                        <ion-col #mystery class="box ion-text-bottom cmn-font-jotia" (click)="select('mystery')">
                            <div class="cmn-rectangle"></div>
                            <span class="box-text">
                                My mind's a mystery I wish to solve.
                            </span>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col #lost class="box ion-text-bottom cmn-font-jotia" (click)="select('lost')">
                            <div class="cmn-rectangle"></div>
                            <span class="box-text">
                                I've lost something. I want it back.
                            </span>
                        </ion-col>
                        <ion-col #unknown class="box ion-text-bottom cmn-font-jotia" (click)="select('unknown')">
                            <div class="cmn-rectangle"></div>
                            <span class="box-text">
                                I don't know what I'm looking for yet.
                            </span>
                        </ion-col>
                    </ion-row>
                </ion-grid>
        </div>
    </ion-content>
</ion-app>