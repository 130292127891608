<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="true">
        <div class="cmn-wrapper">
            <div class="cmn-font-helvetica ion-text-uppercase">
                <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
                </ion-icon>
            </div>
            <div *ngIf="this.navDream == 1">
                <div class="cmn-serif-title ion-text-center">
                    Dream I Unravelled: Your Life as a Story
                </div>
                <div class="disclaimer-text">
                    <p>After each dream in Betwixt, you&rsquo;ll unlock a snippet of valuable information in this
                        section of
                        the app. These pages are here to offer a deeper dive into the theory and science behind the
                        game.
                        You can expect tips, tools, journaling prompts, and a better understanding of how the mechanisms
                        at
                        play in Betwixt can help you.</p>
                    <p>Here, after Dream One, we&rsquo;re starting at the beginning: the power of story.&nbsp;</p>
                    <p><strong>Where fantasy meets reality</strong></p>

                    <p class="quote">&ldquo;Fiction is an ancient virtual reality technology that specializes in
                        simulating human
                        problems.&rdquo; <br />― Jonathan Gottschall, The Storytelling Animal: How Stories Make Us Human
                    </p>

                    <p><strong>You&rsquo;re playing this game as a lone figure, stranded in the In-Between</strong>
                        &mdash;
                        a strange parallel world that shapeshifts to reflect your emotions and make real what you think.
                        Along your journey, you&rsquo;ll meet various foes and find ways to overcome the metaphorical
                        obstacles that block your path. We&rsquo;ve built Betwixt this way not just to make it immersive
                        and
                        exciting, but also because <em>story is the vehicle by which personal change happens</em>.</p>
                    <p><strong>The human mind is built for narrative</strong> &mdash; it&rsquo;s how we find meaning,
                        join
                        the dots and understand the world. As children, we learn through storybooks and role-playing.
                        Then,
                        as we grow older, we get even better at story-seeking; so good that we start to project stories
                        of
                        personal meaning onto everyday situations and the simplest of things we do. We even make stories
                        out
                        of the thoughts we think and the feelings we feel.&nbsp;</p>
                    <p><strong>On our darkest days, we can allow ourselves to get locked into tales of failure and
                            despair.
                        </strong>The stories we all tell ourselves about how we&rsquo;re not good enough, smart enough,
                        fast
                        enough or funny enough actively influence the way we interact and behave. These stories control
                        the
                        choices we make, and they form the very lens through which we view the world. Left unchecked,
                        they
                        can cast a shadow over the entirety of our lives, mutating from occasional doubt to what feels
                        like
                        the fundamental truth.&nbsp;</p>
                    <p>But the good news is that these stories are not truth. Just like any screenplay or novel, our
                        limiting narratives can be edited and rewritten. All we need in order to do that is the strength
                        to
                        take a look at their building blocks &mdash; the thoughts, feelings, memories and behaviours
                        that
                        make them up &mdash; and the courage to author a better tale. This is what Betwixt is all
                        about.&nbsp;</p>
                    <p>As the quest for authorship is a creative one, imagination is your greatest ally in the
                        In-Between.
                        You can think of the metaphors and imagery in Betwixt as a blank canvas for the storytelling
                        part of
                        your mind. They are there to help you explore your inner narratives, uncover the meaning behind
                        your
                        thoughts and feelings, and solve problems by seeing things from a new lens.</p>
                    <p>We wish you the very best on your journey!</p>
                    <br><br>
                    <p><strong>Something For You to Ponder</strong></p>
                    <p>Making a meaningful change to your life demands that you ask yourself the right questions. Here's
                        one
                        for you to try on for size. You can think around it, talk about it or journal on it. It&rsquo;s
                        up
                        to you:</p>
                    <p>"As the lead character in your own story, what&rsquo;s the core challenge you need to overcome,
                        and
                        which doors will open for you?&rdquo;</p>
                </div>
            </div>
            <div *ngIf="this.navDream == 2">
                <div class="cmn-serif-title ion-text-center">
                    Dream II Unravelled: A Look at Gratitude
                </div>
                <div class="disclaimer-text">
                    <p>The series of questions at the end of Dream II (affectionately known as the "love sequence" at
                        Betwixt HQ) is based on extensive research into the benefits of practising gratitude &mdash;
                        that is, actively appreciating the &ldquo;goodness of life&rdquo;. Doing this regularly trains
                        the mind to pick up on positive information and to take a positive perspective. This leads not
                        only to improved attitude, but also to an increasingly optimistic <em>experience</em> of life.
                        Gratitude breeds happiness.&nbsp;</p>
                    <p>An article from <a
                            href="https://www.health.harvard.edu/healthbeat/giving-thanks-can-make-you-happier"
                            target="_blank">Harvard
                            Medical School's online publication</a> states this:&nbsp;</p>
                    <p class="quote">In positive psychology research, gratitude is strongly and consistently associated
                        with greater happiness. Gratitude helps people feel more positive emotions, relish good
                        experiences, improve their health, deal with adversity, and build strong relationships.</p>
                    <p><strong>So how, exactly, is gratitude practised?</strong></p>
                    <p>As with every other tool you&rsquo;ll learn on your journey through the In-Between, gratitude is
                        not an innate characteristic but a skill. This means you can learn it and successfully cultivate
                        it further with just a little work.&nbsp;</p>
                    <p>To make this easier for you, here are four different ways to begin honing your skills:</p>
                    <ol>
                        <li><b>Relive positive memories</b><br />Gratitude can be applied to the past by recalling
                            positive
                            memories and giving thanks to different people, situations or other things from your earlier
                            life. This can be done mentally (say, as you drift off to sleep), in writing, or in
                            conversation with a friend.</li>
                        <br>
                        <li><b>Say "thank you"</b>
                            <br />Probably the simplest way to bring gratitude into your life is
                            to verbally thank the people around you. Giving sincere thanks is actually quite a
                            vulnerable
                            thing to do, which means it doesn't come naturally to everyone. To prepare for this task,
                            write a list of the people you spend the most time with and note down all the things you
                            could thank them for.&nbsp;</li>
                        <br>
                        <li><b>Write thank-you letters</b><br />A randomised controlled study<sup>1</sup> conducted by
                            Indiana University divided a group of 293 participants &mdash; mostly university students
                            seeking
                            mental health counselling &mdash; into three groups. All participants received counselling,
                            one group was also asked to write about their deepest thoughts and feelings, and a third was
                            asked to write a letter of gratitude to another person once a week over a three-week period.
                            The study found that the gratitude group reported significantly better mental health both
                            four and twelve weeks after the writing exercise was completed.</li>
                        <br>
                        <li><b>Keep a gratitude diary</b><br />Journaling is perhaps the most popular means of
                            practising gratitude, and there are many ways to do this. We suggest simply making a nightly
                            note of
                            one thing you felt grateful for during the day just gone.&nbsp;</li>
                        <br>
                    </ol>

                    <p style="font-size: 11px;">1. Wong, Joel Y., “Does gratitude writing improve the mental health of
                        psychotherapy clients? Evidence from a randomized controlled trial”, May 2016, Psychotherapy
                        Research 28(2):1-11</p>
                </div>
            </div>
            <div *ngIf="this.navDream == 3">
                <div class="cmn-serif-title ion-text-center">
                    Dream III Unravelled: The Drives Within
                </div>
                <div class="disclaimer-text">
                    <p>In Dream III, you talked with the voice about whether you might be a Striver, Sprinter, Worker,
                        Carer or Rock. These metaphorical roles are based on the concept of <em>Drivers</em> from
                        Transactional Analysis. Here’s a little background:</p>
                    <p><strong>The five Drivers</strong></p>
                    <p>In 1975, Taibi Kahler identified five internal drivers that motivate us to act, and which can
                        also be found at the root of a range of common dysfunctional behaviours such as perfectionism,
                        overworking and people-pleasing. </p>
                    <p>Drivers are learned during our formative years, and can be understood as unconscious beliefs that
                        tend to fire up during times of stress. The belief, for all variations, starts like this:</p>
                    <h3 class="ion-text-center"><strong>“I’m only okay when I …”</strong></h3>
                    <p>That sentence is then completed in a different way for each of the five drivers:</p>
                    <p><strong>The “Be Perfect” Driver (Striver):</strong></p>
                    <p>“I’m only okay when I get things right”</p>
                    <p><strong>The “Hurry Up” Driver (Sprinter):</strong></p>
                    <p>“I’m only okay when I’m doing things quickly”</p>
                    <p><strong>The “Be Strong” Driver (Rock):</strong></p>
                    <p>“I’m only okay when I don’t show my emotions”</p>
                    <p><strong>The “Try Hard” Driver (Worker):</strong></p>
                    <p>“I’m only okay when I’m working hard”</p>
                    <p><strong>The “Please Others” Driver (Carer):</strong></p>
                    <p>“I’m only okay when everyone else is happy”</p><br>
                    <h3 class="ion-text-center"><strong>Knowing Your Patterns</strong></h3>
                    <p>It's possible to recognise all five Drivers at work in your life, but most people identify with
                        two or three, and many have a single front-runner (or, at least, one that causes the most
                        problems).</p>
                    <p>To help you determine which drivers play the biggest role for you, and where that's likely to
                        happen, here are the five descriptions in a little more detail. </p>
                    <p>Note: if you would like to change your selected driver for the remainder of the Betwixt story,
                        you can do so by replaying Dream III before moving on.</p><br>

                    <h3 class="ion-text-center"><strong>The Striver (“Be Perfect” Driver) </strong></h3>
                    <p class="ion-text-center">“I’m only okay when I get things right”</p><br>

                    <p>Strivers like everything just so. They put effort into detail, set themselves high standards and
                        struggle to tolerate any kind of imperfection. This can mean that they create excellent work,
                        keep an immaculate home, or excel in their careers and hobbies, but they pay in both anxiety and
                        time to achieve those results. To make matters worse, Strivers often struggle to see their own
                        success regardless of how obvious it can appear to others.</p>
                    <p>As with all the drivers, there are a number of ways in which the “Be Perfect” driver can be
                        learned. Perhaps you were praised for achieving high grades at school or keeping a tidy bedroom.
                        Or, perhaps you were punished for failure, errors or sloppiness. </p>
                    <p><strong>Transactional Analysis “Be Perfect” counter: "Good enough"</strong></p><br>

                    <h3 class="ion-text-center"><strong>The Rock (“Be Strong” Driver)</strong></h3>
                    <p class="ion-text-center">“I’m only okay when I don't show my emotions”</p><br>

                    <p>This driver tells us that it is not okay to show our feelings. Instead, with a “Be Strong”
                        driver, we opt to push our emotions away and tough it out. While this pattern can make you good
                        in a crisis, in the long term it can cause an emotional disconnection or numbness that hinders
                        relationships and can sap the joy out of life.</p>
                    <p>People with a “Be Strong” driver may, during their formative years, have been punished for overt
                        displays of emotion and/or praised for emotional stoicism and keeping their cool (perhaps coming
                        from a family who chose the "stiff upper lip" attitude). </p>
                    <p><strong>Transactional Analysis “Be Strong” counter: "It's okay"</strong></p><br>

                    <h3 class="ion-text-center"><strong>The Sprinter (“Hurry Up” Driver)</strong></h3>
                    <p class="ion-text-center">“I’m only okay when I get things done quickly”</p><br>

                    <p>Sprinters are all about speed and efficiency. Their strengths lie in the ability to multitask,
                        thrive under pressure and simply get a lot done. When this driver fires up, however, the need to
                        hurry can overwhelm everything else, leading to mistakes and missed details. Sprinting through
                        life can also make it hard to settle, relax or enjoy what you are doing.</p>
                    <p>During their formative years, people with a strong “Hurry Up” driver may have been punished for
                        dawdling and/or praised for speed and efficiency. </p>
                    <p><strong>Transactional Analysis “Hurry Up” counter: "Slow down"</strong></p><br>

                    <h3 class="ion-text-center"><strong>The Worker (“Try Hard” Driver)</strong></h3>
                    <p class="ion-text-center">“I’m only okay when I'm working hard”</p><br>

                    <p>Workers put in enormous amounts of effort and, as a result, can get a lot done. However, they may
                        be prone to overcomplicate tasks due to their belief that great results must always involve
                        large amounts of work. Overwhelm and overload are the biggest risks for Workers, along with the
                        sense of failure that follows a perpetually incomplete to-do list. </p>
                    <p>During their formative years, people with a strong “Try Hard” driver may have been punished for
                        laziness or inactivity and/or praised for effort and "going the extra mile". </p>
                    <p><strong>Transactional Analysis “Try Hard” counter: "Do enough"</strong></p><br>

                    <h3 class="ion-text-center"><strong>The Carer (“Please Others” Driver)</strong></h3>
                    <p class="ion-text-center">“I’m only okay when everyone else is happy”</p><br>

                    <p>Carers feel the need to keep everyone around them comfortable, and they'll strive to achieve this
                        at their own expense. Beneath their desire to help is the unconscious belief that if they can
                        just save everyone else, then someone will eventually save them. Unfortunately, this isn’t how
                        needs-fulfilment works, and the lack of reciprocation can lead to bitter resentment. Carers have
                        to learn to put themselves first in order to feel satisfied and content. </p>
                    <p>People with a strong “Please Others” driver are likely to have assumed the role of carer/mediator
                        in their family while growing up. Perhaps they were praised for their ability to appease or calm
                        others, and they might have only felt safe when they managed to keep family conflict at bay. 
                    </p>
                    <p><strong>Transactional Analysis “Please Others” counter: "Please yourself"</strong></p><br>
                    <br>
                    <p><strong>Putting it into practice</strong></p>
                    <p>Drivers are neither a good nor bad thing in themselves, and the goal is not to delete them from
                        the mind (which wouldn't be possible anyway), but to learn how to control your response to the
                        urge they create. Self-awareness is the key.</p>
                    <p>The following are journaling prompts designed to help you build awareness of your key driver(s). 
                    </p>
                    <p>To use these, you can either write lists -- challenging yourself to find 25+ items for each
                        prompt, or you can free-write for five minutes per prompt, exploring any tangent that occurs.
                        Good luck!<br /><br /></p>
                    <ol>
                        <li>List all the different situations in your life where your driver can fire up.<br />Or,
                            complete the sentence: <em>My driver fires up when…</em><br /><br /></li>
                        <li>List all the different behaviours, feelings and/or thoughts processes that could be a sign
                            that your driver is active.<br />Or, complete the sentence: <em>I know my driver is active
                                when I…</em><em><br /><br /></em></li>
                        <li>List the healthy (countering) thoughts or actions that could help you override your driver
                            in the different situations it occurs. <br />Or, complete this sentence: <em>To interrupt my
                                driver pattern when I notice it firing up, I can..</em>.</li>
                    </ol>
                </div>
            </div>
            <div *ngIf="this.navDream == 4">
                <div class="cmn-serif-title ion-text-center">
                    Dream IV Unravelled: Psychological Distance
                </div>
                <div class="disclaimer-text">
                    <p>Journaling (or “expressive writing”) can be a powerful way to improve wellbeing. Putting your
                        thoughts into writing helps you to build self-awareness; to prioritise your problems, plans,
                        fears and concerns; and to practice healthy self-communication.</p>

                    <p>There is, however, a trick to making your writing truly effective as a tool for self-awareness
                        and wellbeing. That trick is known as self-distancing, and it’s one of the key mechanisms at
                        play in Betwixt. The process you completed in Dream IV — where you analysed a difficult memory
                        by observing it from above — was the most involved so far. </p>
                    <p> </p>
                    <h3>So, what is Self-distancing?</h3>
                    <p>In 2016, researchers Ethan Kross and Ozlem Ayduk published their extensive research on the
                        psychological and behavioural benefits of self-distancing:</p>

                    <p>We hypothesized that people’s attempts to reflect adaptively on their negative feelings often
                        fail because they focus on their experiences from a psychologically immersed perspective, which
                        makes it difficult for people to reason objectively without getting caught up in the emotionally
                        arousing details of what happened to them. Thus, we hypothesized that a mechanism was needed to
                        allow people to “take a step back” from their experience so that they could work-through it more
                        effectively. We called this process self-distancing.<sup>1</sup></p>

                    <p>Self-distancing, in other words, enables us to explore and learn from our more challenging
                        experiences <em>without</em> getting dragged into the negative emotion associated with those
                        events. Additionally, by virtue of a little extra space, it teaches us to view ourselves in the
                        same way we might see a struggling friend. What this means is that it encourages
                        <em>self-empathy</em>: the otherwise elusive compassionate perspective on our own behaviours,
                        decisions and reactions.</p>

                    <p>While it’s possible to take a self-distanced perspective mentally, the easiest way to practice
                        and develop this counterintuitive skill is through journaling. So you can give it a go, here are
                        four different methods for you to try.</p>

                    <p>Note: if an event from your life feels too difficult to tackle on your own, please consider
                        speaking to a mental health professional before exploring it in writing. </p>
                    <p> </p>
                    <p><strong>Option 1: Mental imagery</strong></p>
                    <p>If you like to think in images, you can self-distance through visualisation. Before writing about
                        a troubling memory, close your eyes and replay the event as if you were a fly on the wall. So,
                        rather than stepping back into the first-person perspective, you’ll be viewing aversion of
                        yourself as <em>they</em> experience what happened.</p>

                    <p>While you write, repeat your visualisation at regular intervals to ensure you maintain the
                        distance. If you like, you can observe a different element of the experience each time you do
                        this, remembering that self-distanced writing focuses more on understanding your reaction to
                        what happened than it does on recounting the events themselves. Here are some prompts for your
                        writing: </p>

                    <ul>
                        <li>Observe the body language of your distanced self. How do they react, physically? </li>
                        <li>Which emotions does your distanced self display, and how can you tell they’re feeling that
                            way?</li>
                        <li>If you were speaking at the time, what can you hear in your distanced self’s tone of voice
                            or chosen words?</li>
                        <li>When you observe the behaviours, choices and language of your distanced self, what do you
                            think their intentions might have been? What were they hoping to achieve? Which needs were
                            they trying to satisfy?</li>
                    </ul>

                    <p>Note: the most illuminating details often surface when expressive writing follows a tangent. So,
                        even though you start with a certain event in mind, you most certainly don’t have to stay in
                        that scenario. Just make sure that, when switching to secondary memories or topics, you take a
                        moment to create the distance each time.</p>
                    <p> </p>
                    <p><strong>Option 2: Invite an important other into the scene</strong></p>
                    <p>The perspective of an objective (or compassionate, wise) observer can be enormously powerful. In
                        a recent study<sup>2</sup>, five-year-olds demonstrated an improved ability to overcome
                        challenges when
                        asked the question, “What would Batman do?” As cute as that option is, your observer needn’t be
                        a superhero. Consider inviting an insightful friend, caring grandparent or an important
                        teacher/mentor into the scene of a troubling event from your life, and then step into their
                        shoes to write about what happened. What’s <em>their</em> perspective? How do they feel about
                        you or your choices at the time? What’s their take on the event as a whole? </p>
                    <p> </p>
                    <p><strong>Option 3: Avoid using the pronoun “I”</strong></p>
                    <p>To explore any situation — past, present or future — you can self-distance by writing
                        <em>about</em> yourself, using your name or the pronouns "they", "she" or "he". Another option
                        is to write <em>to</em> yourself using the word “you” rather than “I” or “me” (a little like
                        writing yourself a letter). In doing this, you can offer the kind of advice you may give a
                        friend, and you can raise the questions you really need to answer.</p>

                    <p>This technique may be the most effective when adopting a self-distanced perspective in your
                        self-talk (i.e. in thought rather than writing). In a study<sup>3</sup> concerned with social
                        anxiety, a
                        group of students were told that they were going to be judged on their ability to make a good
                        impression on a member of the opposite sex. The aim was to induce stress. Before the test, they
                        were asked to reflect on their nervousness using either first-person (“I/me”) or
                        non-first-person (“you/they/she/he”) pronouns. Judges rated the performance of those in the
                        non-first-person group to be better overall. Additionally, those who avoided the word “I” also
                        reported significantly lower levels of anxiety when interviewed after the interaction.</p>

                    <p>So, if you’re struggling with the pressure of an imminent event, pausing to reflect on your inner
                        experience in either the second- or third-person could be a good (if a little strange-feeling)
                        idea.</p>
                    <p> </p>
                    <p><strong>Option 4: Focus on your future self</strong></p>
                    <p>Finally, while writing about a challenge in your present-day life, ask yourself the question,
                        “How will I feel about this in a week/month/five year’s time?”. When we imagine looking back on
                        an event, we draw to our attention the impermanence of our current situation. This is a powerful
                        thing. Creating the space temporally also prompts us to consider the actions we might take to
                        overcome the challenge, as well as the growth opportunities it will most likely provide.</p>
                    <p><br /><br /></p>
                    <p style="font-size: 11px;">References: <br /><br />1. E. Kross (University of Michigan, Ann Arbor,
                        MI, United States) and O.
                        Ayduk (University of California, Berkeley, CA, United States), Self-Distancing: <em>Theory,
                            Research, and Current Directions</em>, 2016.<br /><br />2. <a
                            href="https://onlinelibrary.wiley.com/action/doSearch?ContribAuthorStored=White%2C+Rachel+E">White</a>,
                        <a href="https://onlinelibrary.wiley.com/action/doSearch?ContribAuthorStored=White%2C+Rachel+E">Rachel
                            E</a>; <a
                            href="https://onlinelibrary.wiley.com/action/doSearch?ContribAuthorStored=Carlson%2C+Stephanie+M">Carlson</a>,
                        <a
                            href="https://onlinelibrary.wiley.com/action/doSearch?ContribAuthorStored=Carlson%2C+Stephanie+M">Stephanie
                            M</a>; “What would Batman do? Self‐distancing improves executive function in young
                        children”, <em>Wiley Online Library</em>, 2015. <br /><br /> 3. Clark, Joanne Valentine;
                        Arkowitz, Hal; “Social Anxiety and Self-Evaluation of Interpersonal
                        Performance”, <em>Sage Journals</em>, 1975. </p>
                </div>
            </div>
            <div *ngIf="this.navDream == 5">
                <div class="cmn-serif-title ion-text-center">
                    Dream V Unravelled: What do you Need
                </div>
                <div class="disclaimer-text">
                    <p>In Dream V, you considered the link between negative emotions and/or problematic behaviours and
                        unmet needs. Here, we’re going to explore these concepts in a little more depth, starting with a
                        look at emotional literacy.</p>
                    <p> </p>
                    <p><strong>Emotional Literacy</strong></p>
                    <p>Coming from the world of counselling, emotional literacy is the capacity to express, understand
                        and manage emotions. It’s vital for the development of empathy and healthy relationships with
                        others, as well as for building mental resilience; the ability to overcome, and grow from life’s
                        challenges.</p>
                    <p>Claude Steiner, an American psychotherapist who was one of the first to write extensively on this
                        topic, describes emotional literacy as the ability to “handle emotions in a way that improves
                        your personal power and improves the quality of life around you”<sup>1</sup>.</p>
                    <p> </p>
                    <p><strong>Emotional literacy in the In-Between</strong></p>
                    <p>Both the conversations with the voice and the processes in Betwixt aim to facilitate the kind of
                        awareness that leads to emotional literacy. This includes strengthening skills like putting
                        feelings into words, taking ownership of your emotions, considering the effect they have on the
                        world around you, and building tools for emotional management such as
                        self-distancing<sup>2</sup> and perspective-taking<sup>3</sup> (along with the calming and
                        regulating techniques you can practice by completing the quests). </p>
                    <p>In Dream V, we touched on how the buildup of unexpressed or unprocessed emotion can lead to
                        problematic behaviours or choices. Essentially, without emotional literacy, our feelings can fly
                        under the radar of awareness, and we miss the messages they send — namely, messages about what
                        we need. This is an important point because, if we don’t take conscious action to fulfil our
                        needs, the unconscious mind will have to find its own way to fill the void. Sometimes, this will
                        work out fine. At other times, however, it can lead to the repetition of self-destructive
                        choices, habits or outbursts. </p>
                    <p> </p>
                    <p><strong>Knowing your needs</strong></p>
                    <p>Just as hunger tells us about a lack of food, negative emotions signpost unmet needs of all
                        kinds. The emotion itself is a <em>call to action</em>. We must address the deficit in order to
                        feel better.</p>
                    <p>As fundamental as this might seem, however, reading and responding to our emotions in a healthy,
                        autonomous way is a skill we have to learn. In a perfect world, we’d do this by observing the
                        ability in those around us — just as we learn to speak our native language by hearing it as we
                        grow up. But this is no perfect world and, for most of us, it takes a little effort to tune in
                        and grasp what our feelings are asking for.</p>
                    <p> </p>
                    <p><strong>A simple tool for building emotional literacy and needs awareness</strong></p>
                    <p>Complete the following steps for any negative emotion you feel. This practice may seem
                        particularly useful for recurring or lingering feelings, but you can also apply it to common
                        experiences, such as everyday frustration, lethargy or listlessness. Doing so can be a powerful
                        way to learn how to understand your emotions in general, as well as to build the habit of taking
                        action. Complete the following steps for any negative emotion you feel.</p>
                    <p> </p>
                    <p><u>Step 1: Name the emotion</u><br />Label the emotion you feel. Is it jealousy, fear, guilt, etc.?
                        <br /><br />Note: the more specific your label, the better. If you feel angry, for example, what
                        <em>kind</em> of anger is it? Do you feel fury, rage or even apoplexy? Or is it more like
                        annoyance, frustration or bitterness? If you really want to expand your range, a thesaurus can
                        be helpful here. </p>
                    <p> </p>
                    <p><u>Step 2: Identify the unmet need</u><br />Once you’ve labelled the emotion, scan through the list of
                        needs below and notice what jumps out at you. This list is not exhaustive, but it covers a wide
                        range of needs from the basics like safety and health, to the more abstract and nuanced needs
                        such as purpose and inspiration. </p>
                    <p>What you’re looking for here is the one that feels most at a lack:<br /><br /></p>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <p>Safety / Security</p>
                                    <p>Health / Physical wellbeing</p>
                                    <p>Relaxation</p>
                                    <p>Rest / Sleep</p>
                                    <p>Affection / Touch</p>
                                    <p>Nurturing / Support</p>
                                    <p>Exercise</p>
                                    <p>Movement</p>
                                    <p>Privacy</p>
                                    <p>Space</p>
                                    <p>Choice</p>
                                    <p>Freedom</p>
                                    <p>Autonomy</p>
                                    <p>Meaning</p>
                                    <p>Variety</p>
                                    <p>Excitement</p>
                                    <p>Personal significance</p>
                                    <p>Purpose</p>
                                    <p>Contribution</p>
                                    <p>Inspiration</p>
                                </td>
                                <td>
                                    <p>Play</p>
                                    <p>Hope / Reassurance</p>
                                    <p>Joy</p>
                                    <p>Spontaneity</p>
                                    <p>Novelty</p>
                                    <p>Celebration</p>
                                    <p>Friendship</p>
                                    <p>Companionship</p>
                                    <p>Belonging</p>
                                    <p>Community</p>
                                    <p>Connection</p>
                                    <p>Acceptance</p>
                                    <p>Support</p>
                                    <p>Respect</p>
                                    <p>Appreciation</p>
                                    <p>Understanding</p>
                                    <p>Listening</p>
                                    <p>Empathy</p>
                                    <p>To be seen and heard</p>
                                    <p>Trust</p>
                                    <p>Authenticity</p>
                                    <p>Creativity</p>
                                </td>
                                <td>
                                    <p>Self-expression</p>
                                    <p>Clarity</p>
                                    <p>Focus</p>
                                    <p>Consistency</p>
                                    <p>Order</p>
                                    <p>Simplicity</p>
                                    <p>Ease</p>
                                    <p>Harmony</p>
                                    <p>Peace</p>
                                    <p>Effectiveness</p>
                                    <p>Awareness</p>
                                    <p>Presence</p>
                                    <p>Accomplishment</p>
                                    <p>Self-esteem</p>
                                    <p>Self-confidence</p>
                                    <p>Growth</p>
                                    <p>Challenge</p>
                                    <p>Learning</p>
                                    <p>Discovery</p>
                                    <p>Competence </p>
                                    <p>Skill</p>
                                    <p></p>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p> </p>
                    <p><u>Step 3: Take action</u></p>
                    <p>Finally, pick an immediate action that you can take to begin meeting that need. You don’t have to
                        know how to completely fulfil the need in one fell swoop, it’s just a case of doing
                        <em>something</em> that can start moving you in that direction right away. </p>
                    <p><br /><br /></p>
                    <p style="font-size: 11px;">Notes:<br /><br />1. Steiner, C. with Perry, P. (1997) <em>Achieving Emotional Literacy</em>.
                        London: Bloomsbury<br /><br />2. For more on self-distancing, see Dream IV’s Quest for
                        Knowledge.<br><br> 3. Perspective-taking refers to a person's ability to consider a situation or problem from a
                        different point of view. It requires you to put yourself in another person's position and
                        imagine what you would feel, think, or do if you were in that situation.</p>
                    <p><br /></p>
                </div>
            </div>
            <br><br>
            <div #continue (click)="complete()"
                class="ion-text-center ion-text-uppercase cmn-font-helvetica cmn-enabled">Complete quest</div>
            <br><br>

        </div>

    </ion-content>
</ion-app>