<section class="message-panel">
  <div class="message-panel__messages" [appScroll]='scrollMessages' #scrollMessages>

    <div *ngFor="let storyPoint of storyPoints">
      <app-message [storyPoint]="storyPoint"> </app-message>
    </div>
  </div>

  <div class="message-panel__action-bar" [appScroll]='scrollMessages'>
    <div *ngIf="storyService.displayDots" class="saving narrator-wrapper">
      <div class="alldots">
        <span class="animateddot">
          <ion-icon name="ellipse"></ion-icon>
        </span>
        <span class="animateddot">
          <ion-icon name="ellipse"></ion-icon>
        </span>
        <span class="animateddot">
          <ion-icon name="ellipse"></ion-icon>
        </span>
      </div>
    </div>
    <app-user-action [userInteraction]="currentUserInteraction"></app-user-action>
  </div>

</section>