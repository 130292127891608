import { Component, ViewChild, ComponentFactoryResolver, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Plugins } from '@capacitor/core';
const { Device } = Plugins;
import { first } from 'rxjs/operators';

import { OperationsHelper } from 'src/helpers/ops-helper';
import { environment } from 'src/environments/environment';
import firebase from 'firebase';

const { Storage } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public ngFireAuth: AngularFireAuth,
    public authService: AuthService,
    public router: Router,
    public afs: AngularFirestore) {

    // forces PWA

    if (!environment.testMode) {
      this.checkDeviceInfo();
    }

    this.logLastActivity();

    this.ngFireAuth.onAuthStateChanged((user) => {
      if (user) {

        if (environment.checkNFTconnected) {
          this.afs.collection<any>("users").doc(user.uid).get()
            .pipe(first())
            .subscribe(r => {
              if (r.exists && r.data()["NFTCollection"] && r.data()["NFTCollection"] == 'Elder' && r.data()['NFTID']) {
                return;
              } else {
                this.router.navigate(["/link-nft"]);
              }
            });
        }
        // this.afs.collection<any>("users_testers").doc(user.email).get().subscribe(val=> {
        //   if (val.exists)
        //     Storage.set({ key: 'userState', value: "tester" });
        // });
        this.authService.isLoggedIn$.next(true);
      } else {
        console.log("NO USER DETECTED IN STATE CHANGE");
        this.authService.isLoggedIn$.next(false);
      }
    })
  }

  private logLastActivity() {

    Storage.get({ key: 'userid' }).then(res => {
      if (res.value != null) {
        this.afs.collection('users').doc(res.value).update({
          lastAccess: firebase.firestore.FieldValue.serverTimestamp()
        });
      }
    }).catch();
  }

  // TODO: finish optimization
  private checkLocalLastVisit() {
    Storage.get({ key: 'lastLoggedLocal' }).then(res => {
      if (res.value != null) {
        console.log(new Date(res.value + 30))
        console.log(new Date())
        if (new Date(res.value + 30) < new Date()) {
          Storage.set({ key: 'lastLoggedLocal', value: new Date().toString() });
        }
      } else {
        Storage.set({ key: 'lastLoggedLocal', value: new Date().toString() });
      }
    });
  }

  checkDeviceInfo() {
    if (environment.testMode == false && (!OperationsHelper.isMobileOrTablet || !OperationsHelper.isInstalled)) {
      this.router.navigate(["/download"]);
    }
  }

  ngOnInit() {
    this.fetchDynamicDiscordLink();
  }

  fetchDynamicDiscordLink() {
    this.afs.collection("info").doc("dynamic_content").get()
      .pipe(first())
      .subscribe(r => {
        if (r.data()["discord_link"]) {
          localStorage.setItem("discord_link", r.data()["discord_link"]);
        } else {
          localStorage.setItem("discord_link", "betwixt.life");
        }
      })
  }

  async getDeviceInfo() {
    const info = await Device.getInfo();
    return info.appBuild;
  }
}