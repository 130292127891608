<ion-content [ngClass]="calculateClasses()">
    <div class="cmn-wrapper">
        <div class="cmn-font-helvetica ion-text-uppercase">
            <ion-icon class="cmn-icon-helvetica cmn-enabled" (click)="back(inputArea.value)" name="chevron-back-outline">
            </ion-icon>
        </div>
        <div class="ion-text-uppercase cmn-font-helvetica ion-text-center eyebrow cmn-enabled">
            {{currentBubble?.Eyebrow}}
        </div>
        <div class="cmn-serif-title ion-text-center title">
          <p class="headline">
            {{currentBubble?.Headline}}
          </p>  
        </div>
        <div *ngIf="navDream != 0" class="ion-text-uppercase cmn-font-helvetica ion-text-center journal-info" (click)="redirectoToInfo(inputArea.value)">
            Why am I answering this?
        </div>

        <div class="ion-text-left cmn-input-parent">
            <ion-textarea #inputArea class="input-field" placeholder="{{placeholderText}}" auto-grow="true"
                (ionFocus)="toggleButtons('all', 'show')" autocapitalize="sentences"> </ion-textarea>
            <!-- <ion-textarea #entry class="input-field" placeholder="Type your answer" auto-grow="true" (input)="onInputChange($event.target.value)" (ionFocus)="toggleButtons('all', 'show')"> </ion-textarea> -->
        </div>
        <div *ngIf="showButtons">
            <div class="share-button text-center cmn-font-helvetica cmn-font-light cmn-regular-spacing"
                (click)="saveEntry(inputArea.value)">
                Save
            </div>
            <br>
            <div class="text-center cmn-font-helvetica cmn-font-light cmn-regular-spacing"
                (click)="toggleButtons('all', 'hide')">
                Discard
            </div>
        </div>
        <!-- <div *ngIf="showLater">
            <div class="text-center cmn-font-helvetica cmn-font-light cmn-regular-spacing" (click)="back(entry.value)">
                Later
            </div>
        </div> -->
        <br>

        <div *ngIf="showEntries">
            <div *ngFor="let entry of entries" class="entry">
                <div class="date ion-text-uppercase cmn-font-helvetica cmn-enabled">
                    {{entry.Date | date:'d MMMM y'}}
                </div>
                <div>
                    <div *ngIf="!entry.ShowMore" class="text">
                        {{ trimString(entry.Text, 77) }}
                    </div>
                    <div *ngIf="entry.ShowMore" class="text">
                        {{ entry.Text }}
                    </div>
                    <a *ngIf="entry.Text && entry.Text.length >= 77" (click)="entry.ShowMore = !entry.ShowMore" class="cmn-font-helvetica cmn-regular-spacing" >Show 
                        <span [innerHtml]="entry.ShowMore ? 'less' : 'more'">  </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</ion-content>