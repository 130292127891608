
<div class="bubbles">
    <div  *ngFor="let bubble of bubbles" class="bubble" [ngStyle]="{'background' : bubble.Background }" (click)=redirect(bubble)>
        <div *ngIf="bubble.Locked">
            <div class="ion-text-uppercase cmn-font-helvetica ion-text-center eyebrow">
                Daily prompt
            </div>
            <div class="lock-parent text-center">
                <span class="bordered-sp ion-text-uppercase">
                    <ion-icon style="padding-top: 2px; padding-bottom: 2px" name="lock-closed-outline"></ion-icon>
                </span>
            </div>
        </div>
        <div *ngIf="!bubble.Locked">
            <div *ngIf="bubble.Eyebrow" class="ion-text-uppercase cmn-font-helvetica ion-text-center eyebrow">
                {{bubble.Eyebrow}}
            </div>
            <div class="cmn-navmenu-title">
                {{bubble.Headline}}
            </div>
            <div class="cmn-font-helvetica cmn-regular-spacing">
                {{bubble.Entries}}
            </div>
        </div>
    </div>
</div>