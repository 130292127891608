import { Injectable } from '@angular/core';
import {
  Plugins
} from '@capacitor/core';


import { AngularFirestore, AngularFirestoreCollection, DocumentSnapshot, Action } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})

export class DreamScheduleService {
  constructor(private afs: AngularFirestore) {
  }

  async getDreamReleaseDate(dreamNumber: number ){
    return this.afs.collection<any>('dream_release_schedule').doc("dream" + dreamNumber).snapshotChanges();  
  }

}