<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content>
        <div class="cmn-wrapper">
            <div class="cmn-font-helvetica ion-text-uppercase">
                <span>
                    <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
                    </ion-icon>
                </span>
            </div>

            <div>
                <div class="cmn-serif-title ion-text-center cmn-text-shadow">
                    Make the most of your journey
                  </div>
                <div class="main-text cmn-font-oscar ion-text-center">
                    Betwixt is an interactive journey that works best with notifications. We’ll use them only to enrich your experience and to let you know when you <b>unlock new sections of the game.</b>
                </div>
                <div class="cmn-bottom-text ion-text-center">
                    <div class="cmn-button ion-text-center" (click)='acceptNotification(true)'>
                        <div>Turn on notifications</div>
                    </div>

                    <div (click)='acceptNotification(false)' class="ion-text-uppercase cmn-font-helvetica"> No, thank you</div>
                  </div>
            </div>
        </div>
    </ion-content>
</ion-app>