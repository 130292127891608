import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AnimationController, NavController } from '@ionic/angular';

import {
  Plugins,
} from '@capacitor/core';
import { Router } from '@angular/router';
import { fadeAnimation } from 'src/helpers/transitions-helper';
const { Storage } = Plugins;

@Component({
  selector: 'app-purpose',
  templateUrl: './purpose.component.html',
  styleUrls: ['./purpose.component.css']
})
export class PurposeComponent implements OnInit, AfterViewInit{

  secondaryText = "";

  @ViewChild("start", { read: ElementRef, static: true }) start: ElementRef;
  @ViewChild("secondary", { read: ElementRef, static: true }) secondary: ElementRef;
  @ViewChild("continue", { read: ElementRef, static: true }) continue: ElementRef;
  
  constructor(private animationCtrl: AnimationController, private navCtrl: NavController) { }

  ngOnInit(): void {
    this.SetText();
  }

  async SetText(){
    const { value } = await Storage.get({ key: 'intro-choice' });

    if(value=="story"){
      this.secondaryText = "Here, you can be both the author and audience of your own story.";
     }
     if(value=="mystery"){
      this.secondaryText = "Every riddle holds the key to its solution.";
     }
     if(value=="lost"){
      this.secondaryText = "Here, nothing is lost; only forgotten. <br><br> It's time to remember.";
     }
     if(value=="unknown"){
      this.secondaryText = "Trust your mind, and you'll find out."
     }
  }
  ngAfterViewInit(): void {
    const animationSecondaryAppear = this.animationCtrl.create()
      .addElement(this.secondary.nativeElement)
      .duration(2500)
      .delay(500)
      .fromTo('opacity', '0', '1')
      .onFinish(() => { 
        animationContinueAppear.play();
      });

    animationSecondaryAppear.play()

    const animationContinueAppear = this.animationCtrl.create()
      .addElement(this.continue.nativeElement)
      .duration(500)
      .fromTo('opacity', '0', '1');
  }

  continueClick(){
    this.navCtrl.navigateForward(["slider"], {animation: fadeAnimation});
  }
}
