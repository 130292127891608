<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="true">
        <div class="cmn-wrapper">
            <div class="cmn-font-helvetica ion-text-uppercase">
                <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
                </ion-icon>
            </div>

            <div class="cmn-serif-title ion-text-center">
                Privacy Policy
            </div>
            <div class="disclaimer-text">
                <p class="ion-text-center">last updated June 2022</p>
                <p>This Privacy Policy explains how Mind Monsters Ltd ("Betwixt","we", "us", "our",) collects, uses, and
                    discloses information about you when you access or use our website, mobile application, and other
                    online products and services (collectively, the "Services"), and when you contact our customer
                    service team, engage with us on social media, or otherwise interact with us.&nbsp;</p>
                <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by
                    revising the date at the top of the policy and, in some cases, we may provide you with additional
                    notice (such as adding a statement to our website homepage or sending you a notification). We
                    encourage you to review the Privacy Policy whenever you access the Services or otherwise interact
                    with us to stay informed about our information practices and the choices available to you.</p>
                <p><strong>Collection of Information</strong></p>
                <p>We collect the following kinds of data when you use the Application and our Services:</p>
                <ul>
                    <li>
                        <p><strong>your contact data</strong> such as your full name, email address, and password when
                            you create your account;</p>
                    </li>
                    <li>
                        <p><strong>your usage data</strong> such as what screens or features you access;</p>
                    </li>
                    <li>
                        <p><strong>your log data</strong> such as the type of web browser you use, app version, access
                            times and dates, pages viewed, your IP address;</p>
                    </li>
                    <li>
                        <p><strong>the native actions</strong> you take within the App, such as picking from a number of
                            available options and story paths;</p>
                    </li>
                    <li>
                        <p><strong>the content you create or share</strong> on the App;</p>
                    </li>
                    <li>
                        <p><strong>the information you provide to us</strong> when you fill out a form or a survey,
                            participate in a contest or promotion, make a purchase, communicate with us via social media
                            sites, request customer support, or otherwise communicate with us.</p>
                    </li>
                </ul>
                <p><strong>How we handle your conversation messages</strong></p>
                <p>When you use the Service, you select from pre-formatted options or type in free-text using your
                    keypad. We collect, transmit and securely store your messages in our servers. We process your
                    messages in real-time via a rule-based engine in order to direct you appropriately to subsequent
                    conversation. At no point during your interaction with the App does another natural person have
                    access to or get to monitor or respond to your messages.&nbsp;</p>
                <p>Your data, messages or usage is not used for direct marketing nor is it sold to advertisers. We do
                    use anonymised and only the minimal data that is required to make the product as helpful as possible
                    to people, or to advance mental health research.&nbsp;</p>
                <p><strong>Information from other sources</strong></p>
                <p>We may obtain information about you from other sources, including through third party services and
                    organizations to supplement information provided by you. For example, if you access our Services
                    through a third-party application, such as an app store, a third-party login service, or a social
                    networking site, we may collect information about you from that third-party application that you
                    have made public via your privacy settings. This supplemental information allows us to verify
                    information that you have provided to us and to enhance our ability to provide you with information
                    about our business, products, and Services.</p>
                <p><strong>Use of Information</strong></p>
                <p>Protecting your Information is a top priority for us. We will never sell or rent any Data you shared
                    in the App, nor access your information, except for the purposes included here.</p>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>
                        <p>Create and manage your account in order for you to access and use the Services;</p>
                    </li>
                    <li>
                        <p>Personalize and enhance your user experience by making the story reflect your past choices;
                        </p>
                    </li>
                    <li>
                        <p>Communicate with you about products, services, and events offered by us and others, request
                            feedback, and send news, gifts or other information we think will be of interest to you;</p>
                    </li>
                    <li>
                        <p>Monitor and analyze trends, usage, and activities in connection with our Services;</p>
                    </li>
                    <li>
                        <p>Respond to law enforcement requests and comply with the law, in particular, for the purpose
                            of detecting, investigating, and prosecuting illegal acts.</p>
                    </li>
                    <li>
                        <p>Detect, investigate and prevent fraudulent transactions and other illegal activities and
                            protect our rights and property and those of others, including to enforce our agreements and
                            policies.&nbsp;</p>
                    </li>
                    <li>
                        <p>We may also de-identify and/or aggregate and/or anonymize your data for purposes of product
                            development and improvement. De-identified data, in individual or aggregated form, may also
                            be used for research purposes both internally or with research partners for the advancement
                            of clinical and scientific knowledge.</p>
                    </li>
                    <li>
                        <p>We use third party service providers who provide technical and support services to help us
                            provide and improve the product and Services. In providing the Services, these third party
                            service providers may have limited access to databases of user information or registered
                            member information solely for the purpose of helping us to improve the product and they will
                            be subject to contractual restrictions prohibiting them from using the personal data of our
                            members for any other purpose.</p>
                    </li>
                </ul>
                <p><strong>Cookies and similar technologies</strong></p>
                <p>When you use the Services, We may use cookies and other IT functions to collect your Data. Cookies
                    are small text files that are automatically created by your browser and stored on your device when
                    you connect to the Website or the Application and use the Services.</p>
                <p>You have the choice to refuse to keep all or certain of the cookies in your browser or to disable all
                    or certain cookies, after accepting their deposit on your browser, by configuring your settings.
                    With regard to the use of advertising-related or personalization-related technologies, you can
                    determine whether you consent or not to the use of cookies for marketing purposes.</p>
                <p><strong>Website analytics</strong></p>
                <p>We may also partner with selected third-party vendors, such as Google Analytics, Facebook Business
                    Manager, to allow tracking technologies and remarketing services on the App through the use of first
                    party cookies and third-party cookies, to, among other things, analyze and track users&rsquo; use of
                    the App and better understand online activity. By accessing the App or our Site, you consent to the
                    collection and use of your information by these third-party vendors. You are encouraged to review
                    their privacy policy and contact them directly for responses to your questions. We do not transfer
                    personal information to these third-party vendors.</p>
                <p><strong>Security of your data</strong></p>
                <p>We seek to safeguard the security of your Data and have implemented security measures consistent with
                    accepted practices in the industry to protect your data and limit access to it.&nbsp;</p>
                <p>In particular, we have implemented appropriate technical and organizational measures to minimize
                    risks associated with data loss, misuse, unauthorized access, and unauthorized disclosure using
                    encryption technology, such as Secure Sockets Layer (SSL) during data transport and at rest as well
                    as physical access restrictions for our data centers and authorization controls for data
                    access.However, despite our efforts to protect your Data, there is always some risk that an
                    unauthorized third party may find a way around our security systems or that transmissions of your
                    Data over the Internet will be intercepted. Unfortunately, we cannot guarantee the absolute security
                    of your data, nor can we guarantee that the Data that you provide will not be intercepted while
                    being transmitted to Us over the Internet. Therefore, we urge you to also take every precaution to
                    protect your Data when you are on the Internet or using the Application.</p>
                <p><strong>Storage of personal data</strong></p>
                <p>We will keep your personal data for as long as it is necessary to fulfil the purposes for which it
                    was collected as described above and in accordance with our legal and regulatory obligations.</p>
                <p>If you would like further information about our data retention practices you can ask for this at any
                    time (see &ldquo;Contact Us&rdquo; section below).</p>
                <p><strong>How we store your data</strong></p>
                <p>We process your information and store it on servers located in data centres in the EEA. Our server
                    environment is highly secure and any information is encrypted with industry standard
                    techniques.<br /><br /></p>
                <p>Unfortunately, the transmission of information via the internet is not completely secure. Although We
                    will do Our best to protect your personal data, We cannot guarantee the security of your data
                    transmitted to Our Site(s); any transmission is at your own risk. Once We have received your
                    information, We will use strict procedures and security features to try to prevent unauthorised
                    access.</p>
                <p><strong>Legal basis of personal data processing</strong></p>
                <p>In accordance with GDPR, the lawful basis we use for collecting and processing your information are
                    as follows:</p>
                <ul>
                    <li>
                        <p>Where it is necessary for entering into or performing a contract with you;</p>
                    </li>
                    <li>
                        <p>Where we have a legitimate interest to do so, provided your rights do not override those
                            interests;</p>
                    </li>
                    <li>
                        <p>Where you have consented to its uses;</p>
                    </li>
                    <li>
                        <p>Where our colleagues believe it is in your vital interests to share your personal details;
                        </p>
                    </li>
                    <li>
                        <p>Where required to comply with our legal obligations.</p>
                    </li>
                </ul>
                <p><strong>Your rights</strong></p>
                <p>Subject to certain exemptions, and in some cases dependent upon the processing activity we are
                    undertaking, you have certain rights in relation to your personal information.</p>
                <ul>
                    <li>
                        <p>Right to information: You can request a copy of the personal information we have collected
                            about you.</p>
                    </li>
                    <li>
                        <p>Right to rectification: We want to ensure that your information is up to date and correct.
                            You can request that your information be corrected or removed if you consider it incorrect.
                        </p>
                    </li>
                    <li>
                        <p>Right to be forgotten: You can request us to delete your personal information. We may not
                            delete data that the law requires us to keep.</p>
                    </li>
                    <li>
                        <p>Data portability: You can request that we transfer your personal data from our IT
                            environment, either to another company or to you. This does not apply to information that
                            the law requires us to keep.</p>
                    </li>
                    <li>
                        <p>Withdrawal of consent: You can withdraw your consent to share your information or to receive
                            marketing / emails at any time. Either by unsubscribing from the mailing list or by
                            contacting us through email.</p>
                    </li>
                    <li>
                        <p>Complaint: You can file a complaint with the Data Protection Authorities if you believe that
                            we are treating your personal data in violation with GDPR.</p>
                    </li>
                </ul>
                <p>You can exercise your rights by contacting us by email at <a
                        href="mailto:hazel@mindmonsters.co.uk">hazel@mindmonsters.co.uk</a>. Subject to legal
                    and other permissible considerations, we will make every reasonable effort to honour your request
                    promptly or inform you if we require further information in order to fulfil your request.</p>
                <p><strong>Children&rsquo;s information</strong></p>
                <p>The Services are not directed to children under 13 (or other age as required by local law), and we do
                    not knowingly collect personal data from children. If you learn that your child has provided us with
                    personal data without your consent, you may contact us as set forth below. If we learn that we have
                    collected any personal data in violation of applicable law, we will promptly take steps to delete
                    such personal data and terminate the child&rsquo;s account.</p>
                <p><strong>Contact</strong></p>
                <p>Please send any questions, comments or requests regarding this privacy policy to our team at
                    <a href="mailto:hazel@mindmonsters.co.uk">hazel@mindmonsters.co.uk</a>. You can also write to us at
                    the following address &ndash; Mind Monsters
                    Games Ltd,12 Whitmore House, 140 Nuttall Street, London N1 5LJ.
                </p>
                <p>If you feel that we have not addressed your questions or concerns adequately, or you believe that
                    your data protection or privacy rights have been infringed, you can complain to any supervisory
                    authority or other public body with responsibility for enforcing privacy laws. In the United Kingdom
                    this is the Information Commissioner&rsquo;s Office, you can see their contact details via the ICO
                    website.</p>
                <p><br /></p>
            </div>
            <br><br>
            <div #continue (click)="back()"
                class="ion-text-center ion-text-uppercase cmn-font-helvetica cmn-enabled">Continue</div>
            <br><br>
        </div>
    </ion-content>
</ion-app>