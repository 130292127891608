<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="false">
        <div class="cmn-wrapper">
            <div class="upper-menu ion-text-center flex-container justify-center">
                <div class="flex-item">
                    <img src="assets/icons/close.svg" (click)="back()">
                </div>

                <div class="flex-item cmn-rectangle" style="visibility:hidden"></div>
                <div class="flex-item sos cmn-font-helvetica">SOS</div>
            </div>
            <div>
                <div class="ion-text-center share-image">
                   <img src="./assets/images/social/{{cardId}}.png" />
                </div>
            </div>
           
        </div>
        <div (click)="share()" class=" cmn-bottom-text ion-text-uppercase cmn-font-helvetica
        cmn-enabled"> Share this story</div>
    </ion-content>
</ion-app>