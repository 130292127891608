import { Component, EventEmitter, Input, Output } from '@angular/core'

const FLIPPING_DURATION = 2000

/**
 * Statuses used as css classes
 */
enum FlippingStatus {
  IDLE = 'flip-card--idle',
  FLIPPING_TO_BACK = 'flip-card--flipping-to-back',
  FLIPPING_TO_FRONT = 'flip-card--flipping-to-front',
  FLIPPED = 'flip-card--flipped',
}

@Component({
  selector: 'flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.scss'],
})
export class FlipCardComponent {
  @Input('cardTitle') cardTitle = ''
  @Input('ctaTitle') ctaTitle = ''
  @Input('frontCardBackground') frontCardBackground = ''
  @Input('backCardBackground') backCardBackground = ''
  @Input('isButtonOnFrontSide') isButtonOnFrontSide: boolean
  @Input('hideButtonOnClick') hideButtonOnClick: boolean;
  @Input('ctaDisabledfromOutSide') ctaDisabledfromOutSide : boolean;
  @Input('edgeCardBackground') edgeCardBackground = ''

  @Input() ctaCallback: () => void;

  @Output() public flipEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>()

  flippingStatus: FlippingStatus = FlippingStatus.IDLE
  flippingTimeoutId: NodeJS.Timeout | null = null

  fading: Boolean = false;
  firstClick: Boolean = false;

  isButtonOnFrontSideClass() {
    if (this.isButtonOnFrontSide)
      return "front-button"
    else
      return "back-button"
  }

  public flipCard() {
    this.firstClick = true;
    if (this.isFlipping) {
      return
    }

    this.flippingStatus = this.getNewFlippingStatus(this.flippingStatus)

    if (this.flippingStatus === FlippingStatus.FLIPPED) {
      this.flipEvent.emit(true)
    }

    if (this.flippingTimeoutId != null) {
      clearTimeout(this.flippingTimeoutId)
    }

    this.flippingTimeoutId = setTimeout(() => {
      this.flippingStatus = this.getNewFlippingStatus(this.flippingStatus)
      if (this.flippingStatus === FlippingStatus.FLIPPED) {
        this.flipEvent.emit(true)
      }
    }, FLIPPING_DURATION)
  }

  buttonClick() {
    this.ctaCallback();
    this.firstClick = true;

    if (this.hideButtonOnClick){
      this.fading = true;
      setTimeout(()=>{
        this.fading = false;
      },1000);
    }
  }

  get isCTADisabled(): boolean {

    if (this.isButtonOnFrontSide) {
      return this.flippingStatus !== FlippingStatus.IDLE
    } else {
      return this.flippingStatus !== FlippingStatus.FLIPPED
    }
  }

  public showEdge(): boolean {
    return (
      this.flippingStatus === FlippingStatus.FLIPPING_TO_BACK ||
      this.flippingStatus === FlippingStatus.FLIPPING_TO_FRONT
    )
  }
  
  private get isFlipping(): boolean {
    return this.showEdge();
  }

  private getNewFlippingStatus(prevStatus: FlippingStatus) {
    const flippingStates: Record<FlippingStatus, FlippingStatus> = {
      [FlippingStatus.IDLE]: FlippingStatus.FLIPPING_TO_BACK,
      [FlippingStatus.FLIPPING_TO_BACK]: FlippingStatus.FLIPPED,
      [FlippingStatus.FLIPPED]: FlippingStatus.FLIPPING_TO_FRONT,
      [FlippingStatus.FLIPPING_TO_FRONT]: FlippingStatus.IDLE,
    }

    return flippingStates[prevStatus]
  }
}
