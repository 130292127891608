<div class="action-bar" *ngIf="visible">
  <ng-template [ngIf]="userInteraction?.type === 'default'">
    <div class="container">
      <div class="row justify-content-center">
        <div class="action-bar__item col-sm-12 col-lg-6" *ngFor="let choice of userInteraction.choices">
          <button class="action-bar__button" (click)="trigger(choice)">{{choice.text}}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngIf]="userInteraction?.type === 'text'">
    <div class="action-bar__item input-parent">
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="2" class="action-bar__input" type="text" [(ngModel)]="inputValue"
        placeholder="Type your answer here..." #input></textarea>
      <button (click)="trigger(inputValue)" class="submit-button btn btn-link btn-sm"><span
          class="material-icons send-icon">send</span></button>
    </div>
  </ng-template>

  <ng-template [ngIf]="userInteraction?.type === 'textbreak'">
    <div class="container">
      <div class="row justify-content-center">
        <div class="action-bar__item" *ngFor="let choice of userInteraction.choices">
          <button class="textbreak" (click)="trigger(choice)">
            <span class="material-icons more-icon">
              expand_more
            </span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>