<div #cardwrapper class="text-center cmn-card" [ngStyle]="{'background' : item.Backgroud}">
    <div (click)="redirect()" class="cmn-card-wrapper">
        <div class="ion-text-uppercase cmn-font-helvetica">{{ item.SubHeadline }}</div>

        <div class="cmn-serif-title ion-text-center">{{ item.Headline }}</div>
        <div class="ion-text-uppercase dream-label cmn-font-helvetica">{{ item.Dream }}</div>

        <div class="instruction-labels ion-text-center">
            <span class="headphones-label bordered ion-text-uppercase" *ngIf="item.NeedsSound">
                <ion-icon name="headset-outline"></ion-icon>
                HEADPHONES
            </span>
        </div>

    </div>
</div>