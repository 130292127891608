import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateManagementService } from 'src/services/state-management.service';
import StoryHelper from 'src/helpers/story-helper';

@Component({
  selector: 'app-navquests',
  templateUrl: './navquests.component.html',
  styleUrls: ['./navquests.component.css']
})
export class NavquestsComponent implements OnInit {

  ultimateCarousel: any = [];
  ultimateDreamsList: any = [];


  currentQuestsCarousel: any = [];
  nextQuestsCarousel: any = [];
  lastCompletedDream;
  lastCompletedDreamRoman;
  nextDreamRoman;
  cardsCarousel: any = [];
  lastIndex = 0;

  constructor(private httpClient: HttpClient,
    private stateManagementService: StateManagementService) { }

  ngOnInit(): void {
    this.lastCompletedDream = this.stateManagementService.getLastCompletedDream();

    this.httpClient.get("../../assets/ink/AllDreamData.json").subscribe((data: object) => {
      var lockQuests = false;
      var lastDreamData = data[this.lastCompletedDream];

      if (this.lastCompletedDream == 0) {
        lastDreamData = data[1];

        var tempCar = [];

        lastDreamData.forEach(
          val => {
           if (!val.IsJournal)
           {
              tempCar.push({
                "Eyebrow": val.Eyebrow,
                "SubHeadline": val.SubHeadline,
                "Headline": val.Headline,
                "Duration": val.Duration,
                "Background": val.Background,
                "RouterValue": val.RouterValue,
                "NeedsSound": val.NeedsSound,
                "Locked": val.IsDream ? false : true
              })
            }
          });
        this.ultimateCarousel.push(tempCar);

      }
      else {

        for (let index = this.lastCompletedDream; index >= 1; index--) {
          var tempCar = [];
          if (data[index]) {
            data[index].forEach(
              val => {
                if (!val.IsJournal)
                {
                  tempCar.push({
                    "Eyebrow": val.Eyebrow,
                    "SubHeadline": val.SubHeadline,
                    "Headline": val.Headline,
                    "Duration": val.Duration,
                    "Background": val.Background,
                    "RouterValue": val.RouterValue,
                    "NeedsSound": val.NeedsSound,
                    "Locked": lockQuests || this.lastCompletedDream < index
                  })
                }
              });
          }
          this.ultimateCarousel.push(tempCar)
        }
      }
    });
  }

  romanize(n) {
    return StoryHelper.romanize(n);
  }

}
