import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, OnChanges } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationError, Event} from '@angular/router';
import { AudioService } from 'src/services/audio.service';
import { StateManagementService } from 'src/services/state-management.service';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-card-carousel',
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.css']
})
export class CardCarouselComponent implements OnInit, OnChanges {

  slideOptions = {
    slidesPerView: 'auto', 
    centeredSlides: true, 
    initialSlide: 0,
  }

  cardsToShare: any = [];
  constructor(public router: Router, 
    public audioService: AudioService,
    private stateManagementService: StateManagementService,
    private ref: ChangeDetectorRef
    ) { 
    }

  @Input() item: any;
  @Input() sliderClass: any;
  @Input() primary: boolean;
  @ViewChild('mySlider', { static: true }) slider: IonSlides;

  ngOnInit(): void {    
    this.cardsToShare = this.item;
    this.slideOptions.initialSlide = this.getInitialSlide();
    
    this.slider.slideTo(this.getInitialSlide());
  }

  ngOnChanges() {
    // create header using child_id
    this.cardsToShare = this.item;
    this.slider.slideTo(this.getInitialSlide());
  }

  getInitialSlide(){
    return this.stateManagementService.getLastCompletedDream();
  }

  redirect(card : any){
    if(!card.Locked){
      this.router.navigate([card.RouterValue]);
    }
  }
}
