import { Component, OnInit } from '@angular/core';
import { LinksComponent, LinksConsts } from 'src/components/shared-components/links/links.component';

import {
  Plugins,
} from '@capacitor/core';
const { Storage } = Plugins;

@Component({
  selector: 'app-navprofile',
  templateUrl: './navprofile.component.html',
  styleUrls: ['./navprofile.component.css']
})
export class NavprofileComponent implements OnInit {

  bubbleDisplayProps: any = {};
  linkProps: any = {};

  constructor() { }

  ngOnInit(): void {

    this.linkProps = [ 
    {
      Headline: "Rate us",
      RouterValue: LinksConsts.RATE
    },
    {
      Headline: "Send us feedback",
      RouterValue: LinksConsts.EMAIL_FEEDBACK
    }];

    Storage.get({ key: 'userid' }).then( useridres => {
      if(useridres.value != null){
        this.linkProps.push({
          Headline: "Log out",
          RouterValue: LinksConsts.LOGOUT
        });
      }
    })
  }
}
