import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-journal-info',
  templateUrl: './journal-info.component.html',
  styleUrls: ['./journal-info.component.css']
})
export class JournalInfoComponent implements OnInit {

  navDream = 1;
  route$: Subscription;

  constructor(private route: ActivatedRoute, 
    public location: Location) { }

  ngOnInit(): void {
    this.route$ = this.route.params.subscribe(params => {
      this.navDream = params.id;
    });
  }

  back(){
    this.location.back();
  }

  calculateClasses() {
    return "d" + this.navDream;
  }
}