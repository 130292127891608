import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NotificationService } from 'src/services/notification.service';
import { AnalyticsService } from 'src/services/analytics.service';

@Component({
  selector: 'app-notification-prompt',
  templateUrl: './notification-prompt.component.html',
  styleUrls: ['./notification-prompt.component.css']
})
export class NotificationPromptComponent implements OnInit {

  constructor(private notificationService: NotificationService,
    public router: Router,
    private location: Location,
    private analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
  }

  //rework
  acceptNotification(accepted : Boolean){
    if (accepted){
      this.notificationService.initPushAsync().then(_ => {
        this.analyticsService.logEvent("notifications_accepted");
        this.router.navigate(["/navmenu/navhome"]);
      })
    }
    else {
      this.router.navigate(["/navmenu/navhome"]);
    }
  }

  back(){
    this.location.back();
  }
}
