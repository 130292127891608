<div class="flip-card__wrapper" [ngClass]="flippingStatus">
  <div class="flip-card__inner">
    <div class="flip-card__front" [style]="frontCardBackground">
      <div class="flip-card__container">
        <div class="flip-card__header">
          <div class="flip-card__title">{{cardTitle}}</div>
          <div class="flip-card__rotate-button" (click)="flipCard()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.8913 1.25L17.5 3.84259M17.5 3.84259L14.8913 6.43518M17.5 3.84259C14.4565 3.84259 7.84783 3.84259 5.76087 3.84259C3.67391 3.84259 2.5 5.78704 2.5 7.08333"
                stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M5.1087 18.75L2.5 16.1574M2.5 16.1574L5.1087 13.5648M2.5 16.1574C5.54348 16.1574 12.1522 16.1574 14.2391 16.1574C16.3261 16.1574 17.5 14.213 17.5 12.9167"
                stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="flip-card__content">
          <ng-content select="[front-card]"></ng-content>
        </div>
      </div>
    </div>
    <!-- <div class="flip-card__back flip-card__back-front" [style]="frontCardBackground"></div> -->
    <!-- <div
      class="flip-card__thick"
      [ngStyle]="{ background: frontCardBackground }"
    ></div> -->
    <div class="flip-card__back flip-card__back-front" [style]="edgeCardBackground"></div>

    <div class="flip-card__edge" [ngStyle]="{'width': showEdge() ? '1px' : '0px'}" [style]="edgeCardBackground"></div>
  
    <div class="flip-card__back" [style]="backCardBackground">
      <div class="flip-card__container">
        <div class="flip-card__header">
          <div class="flip-card__rotate-button" (click)="flipCard()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.8913 1.25L17.5 3.84259M17.5 3.84259L14.8913 6.43518M17.5 3.84259C14.4565 3.84259 7.84783 3.84259 5.76087 3.84259C3.67391 3.84259 2.5 5.78704 2.5 7.08333"
                stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M5.1087 18.75L2.5 16.1574M2.5 16.1574L5.1087 13.5648M2.5 16.1574C5.54348 16.1574 12.1522 16.1574 14.2391 16.1574C16.3261 16.1574 17.5 14.213 17.5 12.9167"
                stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="flip-card__content">
          <!-- Selecting back of the card -->
          <ng-content select="[back-card]"></ng-content>
        </div>
      </div>
    </div>
  </div>
  <button class="flip-card__cta-button" 
          [class.fading]="fading" 
          [ngClass]="isButtonOnFrontSideClass()" 
          [disabled]="isCTADisabled" 
          (click)=buttonClick()>
    {{ctaTitle}}
  </button>
</div>
