<div class="menu-info">
    <div *ngFor="let link of inputItems">
        <div class="option-card" (click)="action(link)">
            <div class="cmn-navmenu-title cmn-text-shadow">
                {{ link?.Headline }}
            </div>
            <ion-icon class="cmn-icon-helvetica" name="chevron-forward-outline">
            </ion-icon>
        </div>
        <hr>
    </div>
</div>