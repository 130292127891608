<ion-slides [options]=slideOptions [ngClass]="sliderClass" #mySlider>
    <ion-slide *ngFor="let card of cardsToShare" [ngStyle]="{'background' : card.Background }">

        <div #cardwrapper (click)=redirect(card) class="text-center cmn-card background-gradint-card">

            <div class="ion-text-uppercase cmn-font-helvetica">{{ card.Eyebrow }}</div>

            <div class="mid-center">
                <div [innerHTML]="card.Headline" class="cmn-serif-title ion-text-center"></div>
                <div class="ion-text-uppercase cmn-font-helvetica">{{ card.SubHeadline }}</div>
            </div>

            <div *ngIf="!card.Locked">
                <div class="instruction-labels text-center">
                    <span class="headphones-label bordered ion-text-uppercase" *ngIf="card.NeedsSound">
                        <ion-icon name="headset-outline"></ion-icon>
                        <span class="icon-label">
                            HEADPHONES
                        </span>
                    </span>
                    <span class="time-label bordered ion-text-uppercase">
                        <ion-icon name="time-outline"></ion-icon>
                        <span class="icon-label">
                            {{ card.Duration }}
                        </span>
                    </span>
                </div>
            </div>
            <div *ngIf="card.Locked">
                <div class="instruction-labels text-center">
                    <span class="headphones-label bordered-sp ion-text-uppercase">
                        <ion-icon style="padding-top: 2px; padding-bottom: 2px" name="lock-closed-outline"></ion-icon>
                    </span>
                </div>
            </div>

        </div>

    </ion-slide>
</ion-slides>