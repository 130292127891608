import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GameComponent } from 'src/components/game/game.component';
import { TermsConditionsComponent } from 'src/components/disclaimer/terms-conditions.component';
import { IntroComponent } from 'src/components/intro/intro.component';
import { TestComponent } from './test/test.component';
import { AwakeComponent } from 'src/components/feedback/feedback.component';
import { SignUpOptionsCompontent } from 'src/components/auth/options/signuptoptions.compontent';
import { EmailSignupComponent } from 'src/components/auth/email/emailsignup.component';
import { ReinventedIntroComponent } from 'src/components/onboarding/reinvented-intro/reinvented-intro.component';
import { LoginComponent } from 'src/components/auth/login/login.component';
import { WelcomeOptionsComponent } from 'src/components/onboarding/welcome-options/welcome-options.component';
import { PurposeComponent } from 'src/components/onboarding/purpose/purpose.component';
import { SliderComponent } from 'src/components/onboarding/slider/slider.component';
import { CardsComponent } from 'src/components/shared-components/cards/cards.component';
import { DreamEnd } from 'src/components/dream-end/dream-end.component';
import { PrivacyPolicyComponent } from 'src/components/disclaimer/privacy-policy.component';
import { DisclaimerComponent } from 'src/components/disclaimer/disclaimer.component';
import { SocialCardDetailComponent } from 'src/components/shared-components/cards/social-card-detail/social-card-detail.component';
import { DownloadPWAComponent } from 'src/components/app-update/app-update.component';
import { SosScreenComponent } from 'src/components/disclaimer/sos-screen';
import { NotificationPromptComponent } from 'src/components/notification-prompt/notification-prompt.component';
import { SuccessfulRegistrationComponent } from 'src/components/successful-registration/successful-registration.component';
import { PredreamComponent } from 'src/components/predream/predream.component';
import { AuthGuardService } from 'src/services/auth.guard.service';
import { WelcomeBackComponent } from 'src/components/predream/welcomeback.component';
import { LinkNftComponent } from 'src/components/disclaimer/link-nft';
import { ForgottenPassComponent } from 'src/components/auth/forgotten-pass/forgotten-pass.component';
import { EndComponent } from 'src/components/disclaimer/end.component';

const routes: Routes = [
  // { path: 'test', component: TestComponent },
  // { path: 'test/:id', component: TestComponent },

  { path: '', component: ReinventedIntroComponent },
  { path: 'sos-screen', component: SosScreenComponent },
  { path: 'download', component: DownloadPWAComponent },
  { path: 'login', component: LoginComponent },
  { path: 'intro', component: ReinventedIntroComponent },
  { path: 'home', component: ReinventedIntroComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'link-nft', component: LinkNftComponent },
  // { path: 'notifications', component: NotificationPromptComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  // { path: 'game', component: GameComponent },
  { path: 'game/:id', component: GameComponent, canActivate: [AuthGuardService] },
  // { path: 'card-detail/:id', component: SocialCardDetailComponent },
  // { path: 'awake', component: AwakeComponent },
  { path: 'waiting', component: DreamEnd, canActivate: [AuthGuardService] },
  // { path: 'signupoptions', component: SignUpOptionsCompontent },
  // { path: 'emailsignup', component: EmailSignupComponent },
  { path: 'welcomeoptions', component: WelcomeOptionsComponent },
  { path: 'purpose', component: PurposeComponent },
  { path: 'slider', component: SliderComponent },
  { path: 'welcomeback', component: WelcomeBackComponent },
  { path: 'forgotten-pass', component: ForgottenPassComponent },
  { path: 'end', component: EndComponent },
  { path: 'predream', component: PredreamComponent, canActivate: [AuthGuardService] },
  { path: 'success', component: SuccessfulRegistrationComponent, canActivate: [AuthGuardService] },
  // { path: 'cards', component: CardsComponent },
  { path: '**', component: ReinventedIntroComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }