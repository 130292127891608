<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>

  <ion-content scrollY="false">
    <div class="cmn-font-helvetica ion-text-uppercase back-button" [class.hidden]="getLocked()">
      <span>
        <ion-icon class="cmn-icon-helvetica" (click)="playPreviousDream()" name="chevron-back-outline">
        </ion-icon>
      </span>
    </div>
    <flip-card #flipCard [frontCardBackground]="backCardBackground" [backCardBackground]="frontCardBackground"
      [ctaTitle]="getButtonText()" [ctaCallback]="myCallbackFunction" isButtonOnFrontSide="false"
      [hideButtonOnClick]="true" (flipEvent)="flipEventAction()" [ctaDisabledfromOutSide]="true"
      [edgeCardBackground]="'background: white'">

      <div front-card *ngIf="firstTime">
        <div class="cmn-wrapper ion-text-center">
          <div class="ion-text-uppercase cmn-font-helvetica cmn-enabled">Dream {{lastCompletedDreamRoman}}</div>
          <br>
          <div class="cmn-serif-title">Complete</div>
          <br>
          <div class="image-wrapper">
            <img class="cmn-diamond" src="assets/images/diamond.png">
          </div>
          <br>
          <!-- <div class="cmn-font-jotia ion-text-center" [innerHTML]="getText().textBelow"> -->
          <div class="cmn-font-jotia ">
            Flip the card to continue <br>your journey.
          </div>
        </div>
      </div>

      <div front-card *ngIf="locked && !firstTime">
        <div class="cmn-wrapper ion-text-center">
          <div class="ion-text-uppercase cmn-font-helvetica cmn-enabled">Dream {{lastCompletedDreamRoman}}</div>
          <br>
          <div class="cmn-serif-title">Complete</div>
          <div class="image-wrapper">
            <img class="cmn-diamond" src="assets/images/diamond.png">
          </div>
          <!-- <div class="cmn-font-jotia ion-text-center" [innerHTML]="getText().textBelow"> -->
          <div class="cmn-font-jotia">
            Share your experience of this dream and find holders-only resources in the In-Between section of Discord.
          </div>
          <div class="cmn-font-jotia discord-link" (click)="openDiscord()">
            Open Discord
          </div>

        </div>
      </div>

      <div front-card *ngIf="!locked && !firstTime">
        <div class="cmn-wrapper ion-text-center" [class.hidden]="transitionsService.shouldHideContent">
          <div class="cmn-serif-title now-available">Now available</div>
          <br>
          <div class="ion-text-uppercase cmn-font-helvetica cmn-enabled"> {{subtitle}} </div>
          <div class="image-wrapper">
            <img class="cmn-diamond" src="assets/images/diamond.png">
          </div>
          <!-- <div class="cmn-font-jotia ion-text-center" [innerHTML]="getText().textBelow"> -->
          <div class="cmn-font-jotia">
            The world of the In-Between <br>is waiting for you.
            <br>
          </div>
        </div>
      </div>


      <div back-card>
      </div>

    </flip-card>
  </ion-content>
</ion-app>