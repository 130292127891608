import { Component, OnInit } from '@angular/core';

import {
  Plugins,
} from '@capacitor/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { JournalService } from 'src/services/journal.service';
const { Storage } = Plugins;

@Component({
  selector: 'app-disclaimer',
  templateUrl: './journal-disc.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class JournalDiscComponent implements OnInit {

  constructor(public navCtrl: NavController, public journalService : JournalService) { }

  ngOnInit(): void {
    this.journalService.initiateCollections();
  }

  accept(){
    Storage.set({ key: 'journal-onboarded' , value : "true"}).then( _ => {
      this.navCtrl.back({ animated : false })
      });
  }
}
