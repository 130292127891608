import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { OperationsHelper } from 'src/helpers/ops-helper';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {

  @Input() inputItems: any;
  
  constructor(public ngFireAuth: AngularFireAuth, public router: Router,
    public authService: AuthService) { }

  ngOnInit(): void {
  }


  action(item : any){
    console.log(item.RouterValue);
    if(item.RouterValue == LinksConsts.SOCIAL){
      this.router.navigate(["/community"]);
    } else if (item.RouterValue == LinksConsts.LOGOUT){
      this.logout();
    } else if (item.RouterValue == LinksConsts.RATE){
        window.open(
          OperationsHelper.APP_STORE_LINK_REVIEW,
          '_blank'
      );
    } else if (item.RouterValue == LinksConsts.LEARNMORE){
      this.router.navigate(["/about"]);
    } else if (item.RouterValue == LinksConsts.EMAIL_FEEDBACK){
      window.location.href = OperationsHelper.EMAIL_FEEDBACK_LINK;
    }
  }
  

  logout(){
    this.ngFireAuth.signOut();
    this.authService.isLoggedIn$.next(false);
    this.router.navigate(["/"]);
  }
}


export class LinksConsts {  
  static readonly SOCIAL = 'Social';
  static readonly LOGOUT = 'Logout';
  static readonly RATE = 'Rate'
  static readonly LEARNMORE = 'Learn'
 
  static readonly EMAIL_FEEDBACK = 'Email';
}