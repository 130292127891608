<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content>
    <div class="cmn-wrapper">
      <div class="cmn-font-helvetica ion-text-uppercase">
        <!-- <span>
          <ion-icon class="cmn-icon-helvetica" [routerLink]="'/slider'" name="chevron-back-outline">
          </ion-icon>
        </span> -->
      </div>
      <div class="cmn-serif-title cmn-mid-text cmn-text-shadow">
        A world of possibility unfolds before you.
        Choose well.
      </div>
      <div class="cmn-bottom-text ion-text-center">
        <div class="cmn-button ion-text-center">
          <a [routerLink]="'/emailsignup'">Create Account</a>
        </div>

        <div [routerLink]="'/login'" class="ion-text-uppercase cmn-font-helvetica"> I have an account</div>
      </div>
    </div>
  </ion-content>
</ion-app>