import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StateManagementService } from 'src/services/state-management.service';
import {
  Plugins,
} from '@capacitor/core';
import { NavController } from '@ionic/angular';
import { JournalService } from 'src/services/journal.service';
import { first, map } from 'rxjs/operators';

const { Storage } = Plugins;


@Component({
  selector: 'app-navdreams',
  templateUrl: './navdreams.component.html',
  styleUrls: ['./navdreams.component.css']
})

//Used for Journal List
export class NavdreamsComponent {

  bubbleProps: any = [];
  currentBuble: any;

  lastCompletedDream;
  cardsCarousel: any = [];
  questsData: any = {};
  entriesData: any = {};

  constructor(private httpClient: HttpClient,
    private stateManagementService: StateManagementService,
    public navCtrl: NavController,
    public journalService: JournalService,
    public ref: ChangeDetectorRef) { }

  ionViewWillEnter() {

    Storage.get({ key: 'journal-onboarded' }).then(onboarded => {
      console.log(onboarded);
      if (onboarded.value == null) {
        if (+localStorage.getItem("lastCmplDream") >= 1) {
          this.navCtrl.navigateForward("journal-disc", { animated: false });
        }

      }
    }).catch();

    if(localStorage.getItem("journalEntryCount") !== 'undefined'){
      this.entriesData = JSON.parse(localStorage.getItem("journalEntryCount"));
    }
    else {
      this.entriesData == null;
    }

    if(Object.keys(this.questsData).length == 0){
      this.httpClient.get("../../assets/ink/AllDreamData.json").pipe(first()).subscribe((data: object) => {
      this.questsData = data;
      this.fetchNumberOfEntries();
    });
    }
    else if(this.entriesData == null){
      this.fetchNumberOfEntries();
    }else{
      this.populateBubbles(this.questsData, this.entriesData);
    }
  }

  fetchNumberOfEntries(){
    this.journalService.getTotalNumberOfEntries().then(val => {
      if (val != null) {
        val.pipe(map((snaps) => {
          var result = snaps.payload.data();
          localStorage.setItem("journalEntryCount", JSON.stringify(result));
          this.entriesData = result;
          this.populateBubbles(this.questsData, this.entriesData);
        }),first()).subscribe();
      }
      else{
        this.bubbleProps.push({
          "Headline": "Your Free Thoughts",
          "Eyebrow": "Insights Log",
          "Background": "linear-gradient(360deg, #292033 0%, #4F415C 100%)",
          "RouterValue": "/journal/0",
          "Entries": this.entriesText(0),
          "Key": 0,
          "Locked": true
        })
      }
    })
  }

  ionViewDidLeave() {
    this.bubbleProps = [];    
  }

  populateBubbles(data, entries) {
    this.lastCompletedDream = this.stateManagementService.getLastCompletedDream();
    
    console.log(entries)

    if (this.lastCompletedDream == 0){
      this.bubbleProps.push({
        "Headline": "Your Free Thoughts",
        "Eyebrow": "Insights Log",
        "Background": "linear-gradient(360deg, #292033 0%, #4F415C 100%)",
        "RouterValue": "/journal/0",
        "Entries": this.entriesText(0),
        "Key": 0,
        "Locked": true
      })
    }
    else {
      this.bubbleProps.push({
        "Headline": "Your Free Thoughts",
        "Eyebrow": "Insights Log",
        "Background": "linear-gradient(360deg, #292033 0%, #4F415C 100%)",
        "RouterValue": "/journal/0",
        "Entries": this.entriesText(entries['d0']),
        "Key": 0,
        "Locked": false
      })
    }

    for (let index = this.lastCompletedDream; index >= 1; index--) {
      if (data[index]) {
        data[index].forEach(
          val => {
            if (val.IsJournal) {
              this.bubbleProps.push({
                "Eyebrow": val.Eyebrow,
                "Headline": val.Title,
                "Background": val.Background,
                "RouterValue": val.RouterValue,
                "Entries": this.entriesText(entries['d' + index]),
                "Key": index
              })
            }
          });
      }
    }
  }

  entriesText(number){
    if (number == 0) {
      return "Write your first entry";
    } else if (number == 1) {
      return "1 entry";
    } else {
      return number + " entries";
    }
  }
}
