export default class StoryHelper {
  static calculatePacingFromMessage(message: string) : number {   
    var numberOfWords = this.countWords(message);

    // default value is 2 seconds (1 to 5 words)
    var delayInSeconds = 2;
    // if (numberOfWords > 5 && numberOfWords < 15)
    if (numberOfWords > 5)
    {
      delayInSeconds = Math.floor(numberOfWords / 3) + 1;

      if (numberOfWords == 15 || numberOfWords == 24 || numberOfWords == 29 || numberOfWords == 39){
        delayInSeconds = Math.floor(numberOfWords / 3);
      }
    }
    // else if (numberOfWords >= 15){
    //   delayInSeconds = Math.floor(15 / 3);
    // }

    // return the delay in miliseconds
    return delayInSeconds * 1000;
  }

    static isJson(item) {
        item = typeof item !== "string"
            ? JSON.stringify(item)
            : item;

        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }

        if (typeof item === "object" && item !== null) {
            return true;
        }

        return false;
    }

    static countWords(message: string): number {
        return message.trim().split(/\s+/).length;
    }

    // Helper for parsing out tags of the form:
    //  # PROPERTY: value
    // e.g. IMAGE: source path
    static splitPropertyTag(tag) {
      var propertySplitIdx = tag.indexOf(":");
      if (propertySplitIdx != null) {
        var property = tag.substr(0, propertySplitIdx).trim();
        var val = tag.substr(propertySplitIdx + 1).trim();
        
        var additionalProp = null;

        var additionalPropIdx = tag.indexOf(",");
        if (additionalPropIdx != -1){
          val = tag.substr(propertySplitIdx + 1, additionalPropIdx - (propertySplitIdx + 1)).trim();
          additionalProp = tag.substr(additionalPropIdx + 1).trim();

          return {
            property: property,
            val: val, 
            additionalProp: additionalProp
          };
        }

        return {
          property: property,
          val: val
        };
      }
  
      return null;
    }

    static romanize (num) {
      if (isNaN(num))
          return "I";
      var digits = String(+num).split(""),
          key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                 "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                 "","I","II","III","IV","V","VI","VII","VIII","IX"],
          roman = "",
          i = 3;
      while (i--)
          roman = (key[+digits.pop() + (i * 10)] || "") + roman;
      return Array(+digits.join("") + 1).join("M") + roman;
    }
}