<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="false" [ngClass]="calculateClasses()">
        <div class="cmn-wrapper">
            <ion-grid class="upper-menu" *ngIf="!showMainMenu">
                <ion-row class="ion-justify-content-center ion-text-center">
                    <ion-col size="4" (click)="toggleSound()" class="sound-col">
                        <span *ngIf="!soundPlaying">
                            <ion-icon name="volume-mute"></ion-icon>
                        </span>
                        <span *ngIf="soundPlaying">
                            <ion-icon name="volume-high"></ion-icon>
                        </span>
                    </ion-col>
                    <ion-col class="diamond" size="4" (click)="hideOptionsMenu()">
                        <ion-icon name="square-sharp" style="transform: rotate(45deg); font-size: 12px;"></ion-icon>
                    </ion-col>
                    <ion-col size="4" (click)="restart()">
                        <ion-icon name="refresh-outline"> </ion-icon>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <ion-grid *ngIf="showMainMenu" class="upper-menu">
                <ion-row class="ion-justify-content-center ion-text-center">
                    <ion-col size="4">
                        <div></div>
                    </ion-col>
                    <ion-col class="diamond" size="4" (click)="openOptionsMenu()">
                        <ion-icon name="square-sharp" style="transform: rotate(45deg); font-size: 12px;"></ion-icon>
                    </ion-col>
                    <ion-col size="4" (click)="openLink()">
                        <span class="sos">SOS</span>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <div class="transparent-margin" [ngClass]="calculateClasses()">
            </div>

            <div #chatHostDiv class="chat-main" chat-host>
                <ng-template chat-host></ng-template>
            </div>
            <div *ngIf="!showMainMenu" #overlayDiv class="chat-overlay" (click)="hideOptionsMenu()">
            </div>


        </div>
    </ion-content>
</ion-app>