import { Directive, ElementRef, ViewChild, Input, ViewChildren } from '@angular/core';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {
  constructor(private elementRef: ElementRef) {}

  @Input() appScroll;

  public ngOnInit() {
    this.scrollToBottom();

    (new MutationObserver(() => {
      this.scrollToBottom();
    })).observe(this.elementRef.nativeElement, { childList: true, subtree: true });
  }

  private scrollToBottom() {try{
        this.appScroll.scrollTop = this.appScroll.scrollHeight;
    }catch(err) { }          
  }
}
