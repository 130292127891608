import { Component, Input, OnInit } from '@angular/core';
import { writeFile } from 'capacitor-blob-writer';
import { FilesystemDirectory, Plugins } from '@capacitor/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/services/analytics.service';
import { OperationsHelper } from 'src/helpers/ops-helper';

const { Share } = Plugins;

@Component({
  selector: 'app-social-card-detail',
  templateUrl: './social-card-detail.component.html',
  styleUrls: ['./social-card-detail.component.css']
})
export class SocialCardDetailComponent implements OnInit {
  private route$: Subscription;
  public cardId;

  constructor(private location : Location, 
        private route: ActivatedRoute,
        private analytics: AnalyticsService) { }

  ngOnInit(): void {
    this.route$ = this.route.params.subscribe(params => {
      this.cardId = params.id;
    })
  }
  
  async share(){ 
    const res = await fetch('./assets/images/social/' + this.cardId + ".png")
    // console.log(res);
    const blob = await res.blob();

    const { uri } = await writeFile({
      path: 'media/betwixt.png',
      directory: FilesystemDirectory.Data,
      data: blob,
      recursive: true,
    })

    var text = OperationsHelper.SHARE_TEXT;
    var title = OperationsHelper.SHARE_TITLE;

    await Share.share({
      title: title,
      text: text,
      url: uri
    });

    this.analytics.logEvent("app_shared_card");
  }
  
  
  back(){
    this.location.back();
  }
}