<ion-app>
    <ion-header class="ion-no-border">
    </ion-header>
    <ion-content scrollY="false">
        <div class="card-outline">
            <div class="cmn-wrapper">
                <div class="cmn-font-helvetica ion-text-uppercase">
                    <span>
                        <ion-icon class="cmn-icon-helvetica" (click)="back()" name="chevron-back-outline">
                        </ion-icon>
                    </span>
                </div>
                <div class="cmn-serif-title ion-text-center cmn-text-shadow">
                    Password reset
                </div>
                <div class="cmn-font-oscar ion-text-center">
                    Forgot your password?<br> Enter your email and we'll send you a link to reset it.
                </div>

                <div class="ion-text-left cmn-input-parent">
                    <ion-input #email type="email" class="input-field" placeholder="Your email"></ion-input>
                </div>
                <br>
                <div [class.hidden]="!showMessage" class="cmn-font-oscar message-block ion-text-center">
                    <div *ngIf="!isError">
                        {{messageSuccess}}
                    </div>
                    <div *ngIf="isError" class="error">
                        {{messageError}}
                    </div>
                </div>

                <div class="cst-footer ion-text-center cmn-bottom-text">
                    <div class="cmn-button ion-text-center" (click)="resetPass(email.value)">
                        Reset password
                    </div>
                </div>
            </div>
        </div>
    </ion-content>
</ion-app>