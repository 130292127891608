import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Howl, Howler } from 'howler';
import { AnalyticsService } from 'src/services/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-meditation',
  templateUrl: './meditation.component.html',
  styleUrls: ['./meditation.component.css']
})
export class MeditationComponent implements OnInit {

  playing: Boolean = false;
  track;
  totalseconds = 0;
  seconds = 0;
  minutes = 0;
  intervalid;

  meditationTitle = "";
  navDream = 1;
  route$: Subscription;

  title: string;

  audioPlayer = <HTMLAudioElement>document.getElementById("player");
  

  constructor(public location: Location, 
              private analytics: AnalyticsService, 
              private route: ActivatedRoute, 
              private httpClient: HttpClient,
              private router: Router) { }

  ngOnInit(){

    this.route$ = this.route.params.subscribe(params => {
          this.navDream = params.id;
    });
    this.audioPlayer = <HTMLAudioElement>document.getElementById("player");

    this.setMeditationTitle();

    this.audioPlayer.addEventListener('ended', () => {
      this.playing = false
    }, false);

    this.audioPlayer.addEventListener('play', () => {
      this.playing = true
    }, false);

    this.audioPlayer.addEventListener('pause', () => {
      this.playing = false
    }, false);
  }

  
  // ngOnInit(): void {
  //  
  //   // TO DO : refactor for all meditations
   

  //   var srcMeditation = "https://firebasestorage.googleapis.com/v0/b/betwixt-game.appspot.com/o/public%2Fmeditations%2Fdream_" + this.navDream + "_guided_med.mp3?alt=media";
  //   this.track = new Howl({
  //     src: srcMeditation, 
  //     preload: true
  //   });

  //   this.httpClient.get("../../assets/ink/AllDreamData.json").subscribe((data: object) => {
  //     var dreamData = data[this.navDream];
  //     var meditationTitle = "";

  //     if (dreamData){
  //       meditationTitle = dreamData[2]?.Headline.replace(/<br>/g, '');
  //     }
  //     this.meditationTitle = meditationTitle;
  //   });
  // }

  public openLink() {
    this.router.navigate(["/sos-screen"]);
  }


  back() {
    // this.track.stop();
    // console.log("back");
    this.location.back();
  }

  toggleSound() {
    if (!this.playing) {
      this.audioPlayer.play()
      this.playing = true;
    } else {
      this.audioPlayer.pause()
      this.playing = false;
    }
  }

  playSound() {
    this.analytics.logEvent("app_meditation_" + this.navDream + "_played");
    this.startTimer();
    this.playing = !this.playing;
    this.track.play();
  }

  format(n) {
    return (n < 10) ? ("0" + n) : n;
  }

  stopSound() {
    this.playing = !this.playing;
    this.track.pause();
    clearInterval(this.intervalid);
  }

  getTrackDuration() {
    var mins = +this.track.duration / 60;
    var seconds = this.track.duration % 60;
    return mins + ":" + seconds;
  }

  startTimer() {
    this.intervalid = setInterval(() => {
      this.totalseconds++;
      this.seconds++;
      if (this.seconds == 60) {
        this.minutes++;
        this.seconds = 0;
      }
    }, 1000)
  }

  getTrackProgress() {
    return this.totalseconds / this.track._duration;
  }

  setMeditationTitle(){
    this.httpClient.get("../../assets/ink/AllDreamData.json").pipe(first()).subscribe((data: object) => {
          var dreamData = data[this.navDream];
          var meditationTitle = "";
    
          if (dreamData){
            meditationTitle = dreamData[2]?.Headline.replace(/<br>/g, '');
          }
          this.meditationTitle = meditationTitle;
      });
  }
}
