import { Component, ViewChild, ComponentFactoryResolver, OnInit, ChangeDetectorRef, ElementRef, OnDestroy } from '@angular/core';
import { ChatComponent } from 'src/components/chat/chat.component';
import { ChatDirective } from 'src/directives/chat.directive';
import { StoryService } from 'src/services/story.service';
import { AudioService } from 'src/services/audio.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NavController } from '@ionic/angular';

import {
  Plugins,
  AppState
} from '@capacitor/core';
import { NotificationService } from 'src/services/notification.service';
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { TransitionsPropertiesService } from 'src/services/rounded-edges-service';
import { environment } from 'src/environments/environment';
const { Storage, App } = Plugins;


@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit, OnDestroy {
  title = 'Betwixt';
  loaded: Boolean = true;
  currentDream = 1;
  navDream = 1;
  route$: Subscription;
  showMainMenu: Boolean = true;
  soundPlaying: Boolean = true;
  @ViewChild(ChatDirective, { static: false }) chatDirective: ChatDirective;

  @ViewChild('mainmenulinks', { static: false }) public mainMenuLinks: ElementRef<any>;
  @ViewChild('menuoptions', { static: false }) public menuOptions: ElementRef<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef,
    public audioService: AudioService,
    private router: Router,
    private route: ActivatedRoute,
    private storyService: StoryService,
    public navCtrl: NavController,
    public notificationService: NotificationService) {
  }

  ngOnInit() {
    this.route$ = this.route.params.subscribe(params => {
      console.log("initialized params dream enter")
      this.navDream = params.id;
      this.currentDream = this.navDream;

      if (this.navDream == 1) {
        Storage.get({ key: 'onboarded' }).then(onboarded => {
          console.log(onboarded);
          if (onboarded.value == null) {
            this.navCtrl.navigateForward("disclaimer", { animated: false });
          } else {
            this.loadChat();
            this.audioService.changeSoundImproved("d1l1.mp3", 12);
          }
        }).catch();
      } else {
        this.loadChat();
        this.audioService.changeSoundImproved();
      }
    });


    console.log("CURRENT DREAM HERE IS - > " + this.currentDream);
  }

  ionViewDidEnter() {
    App.addListener('appStateChange', (state: AppState) => {
      if (state.isActive == false) {
        // TODO: REVIEW
        this.toggleSound();
        this.audioService.stopBackgroundSounds();
        this.audioService.unloadHowler();

        this.storyService.clearPendingPointsTimeout()
        this.storyService.pauseGame();

        Storage.get({ key: 'finished' }).then(finished => {
          if (!finished.value) {
            this.navCtrl.navigateRoot("welcomeback", { animated: false });
          }
        }).catch();

      } else {
        this.storyService.removePause();
        this.audioService.unloadHowler();
        this.audioService.ctxResume();

        window.location.reload();
      }
    });
    // this.audioService.changeSoundImproved()
  }

  loadChat() {
    this.ref.detectChanges();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ChatComponent);
    const component = this.chatDirective.viewContainerRef.createComponent(componentFactory);
    component.instance.currentDream = this.navDream;
  }

  public openLink() {
    this.audioService.stopBackgroundSounds();
    this.audioService.stopFXImproved();
    this.navCtrl.navigateForward("sos-screen", { animation: fadeAnimation });

  }

  openOptionsMenu() {
    this.showMainMenu = false;
  }

  hideOptionsMenu() {
    this.showMainMenu = true;
  }

  toggleSound() {
    if (this.soundPlaying) {
      this.audioService.pauseBackgroundAudio();
      this.audioService.stopFXImproved();
    } else {
      this.audioService.changeSoundImproved();
    }
    this.soundPlaying = !this.soundPlaying;
  }

  redirect(target: string) {
    this.audioService.stopBackgroundSounds();
    this.audioService.stopFXImproved();
    this.navCtrl.navigateForward([target], { animated: false });
  }

  public restart() {
    if (confirm("Are you sure you want to restart the dream?")) {
      //this.storyService.restartGame();
      this.storyService.clearInfoForRestart();
      this.showMainMenu = true;
      this.redirect("predream")
    }
  }

  ngOnDestroy(): void {
    this.audioService.stopBackgroundSounds();
  }

  calculateClasses() {
    return "d" + this.navDream;
  }
}
