import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { Plugins } from '@capacitor/core';
import { fadeAnimation } from 'src/helpers/transitions-helper';
import { NavController } from '@ionic/angular';
import { StateManagementService } from 'src/services/state-management.service';
import { first, map } from 'rxjs/operators';
import { FIRST_SOUNDS } from 'src/enums/first-sounds';

const { Storage } = Plugins;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public ngFireAuth: AngularFireAuth,
    public navCtrl: NavController,
    public location: Location,
    private stateManagentService: StateManagementService) { }
  ngOnInit(): void {
  }

  email2: string;
  password2: string;

  loginClick(email, password) {
    this.email2 = email.value;
    this.password2 = password.value;
    this.login(this.email2, this.password2);
  }

  login(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password).then(async (res) => {
      await Storage.set({ key: 'userid', value: res.user.uid });

      //retrieve user data if any and redirect
      await this.transferDreamDataFromFirebase(res.user.uid);

    }
    ).catch((err) =>
      alert("Incorrect Credentials")
    );
  }

  async transferDreamDataFromFirebase(userid) {
    await this.stateManagentService.transferDreamDataFromFirebase(userid)
      .pipe(first(),
        map((snaps) => {
          var data = snaps.payload.data();
          if (data) {
            for (let i = 1; i <= 11; i++) {
              let storyPoint = data['storyPoints-' + i];
              if (storyPoint) {
                localStorage.setItem('storyPoints-' + i, storyPoint);
                Storage.set({ key: 'onboarded', value: "true" });
                localStorage.setItem('lastCmplDream', (i - 1).toString())
              }
              let storyState = data['storyState-' + i];
              if (storyState) {
                localStorage.setItem('storyState-' + i, storyState);
              }
            }

            // preload first Sound of next dream so it starts smoothly 
            let currentDream = localStorage.getItem("lastCmplDream");
            if (!currentDream) {
              currentDream = '0'
            }
            let nextSound = FIRST_SOUNDS[+currentDream + 1];
            if (nextSound) {
              localStorage.setItem("currentSound", nextSound);
            }
          }

          this.navCtrl.navigateForward("home", { animation: fadeAnimation });
        })
      )
      .subscribe();
  }
}
