<ion-app>
  <ion-header class="ion-no-border">
  </ion-header>
  <ion-content scrollY="false" class="d0">
    <div class="cmn-wrapper">
      <div class="cmn-serif-title ion-text-center margin-top">
        Welcome to the <br>journal
      </div>
      <div class="disclaimer-text ion-text-center">
      <p>
        Here, you can safely store your reflections and insights at any time you like. 
      </p>  
      <p>
        In addition, each Betwixt Dream comes with its own <b>daily journaling prompt</b>. These are brief questions to help you reflect frequently and effectively. A new prompt will appear after each dream is completed.
      </p>
      </div>
      <br>
      <br>
      <div class="cst-footer ion-text-center cmn-bottom-text">
        <div (click)="accept()" class="cmn-button ion-text-center">
          I understand
        </div>
      </div>
    </div>
  </ion-content>
</ion-app>